import {
    useEffect,
    useState,
    Ref,
    forwardRef,
    useImperativeHandle
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { User } from "../../../shared/models/Users";
import schema from "./schema";
import { MultiValue } from "react-select";
import InputCollaboratorsNoFormik from "../../../components/InputCollaboratorsNoFormik";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import InputMasked from "../../../components/InputMasked";
import InputDateHTML from "../../../components/InputDateHTML";
import InputSelect from "../../../components/InputSelect";
import InputSingleFileUploadPure from "../../../components/InputSingleFileUploadPure";
import InputTextarea from "../../../components/InputTextarea";
import Title from "../../../components/Title";
import Spinner from "../../../components/Spinner";
import { useDropdown } from "../../../shared/contexts/domains/DropdownContext";
import { useMenu } from "../../../shared/contexts/MenuContext";
import { useCourse } from "../../../shared/contexts/CourseContext";
import { useUser } from "../../../shared/contexts/UserContext";
import { useLoader } from "../../../shared/contexts/LoadingContext";
import { IStepOne } from "../../../shared/models/course/Course";
import {
    CourseCollaborator,
    DomainOption,
    KnowledgeArea,
    Level
} from "../../../shared/models/domains/Domains";
import { Text } from "./styles";
import { Buttons, Container } from "./styles";

interface RefObject {
    switchOrSubmitStep1: (newTargetStep: number|undefined) => void;
}
interface IStep1 {
    setStep: (step: number) => void;
    className: string;
    type: 'create' | 'edit' | 'read';
    courseCode: number;
    ref: Ref<RefObject>
}

const StepOne = forwardRef((
    {
        setStep,
        className,
        type,
        courseCode = 0
    }: IStep1,
    ref: Ref<RefObject>
) => {
    const [targetStep, setTargetStep] = useState<number|undefined>(undefined);
    const {
        loadingFetch,
        courseEditing,
        resetCourseEditing,
        saveStepOne,
        editStepOne,
        getCourseById,
    } = useCourse();    
    const { knowledgeAreas, levels, courseModalities } = useDropdown();
    const { users, fetchUsers } = useUser();
    const { trackMenuOption } = useMenu();
    const { loading } = useLoader();
    const navigate = useNavigate();
    const customId = "invalid-form-fields";
    
    const handleCancelClick = () => {
        navigate(-1);
    }

    const handleCallbackSubmit = () => {
        if(targetStep) {
            setStep(targetStep);
            // setTargetStep(undefined);
        }
        else {
            setStep(2)
        }
    }

    const handleCourseEnvironmentClick = () => {
        navigate(`/courseenvironment/${courseCode}`, {
            state: 'courseEdit-1'
        });
    }

    const getErrorMessage = (fieldName: string) => {
        return (formik?.getFieldMeta(fieldName)?.touched &&
                formik?.getFieldMeta(fieldName)?.error)
            ?
            formik.getFieldMeta(fieldName).error
            :
            ''
    };

    const checkDisabledSubmit = () => {
        if(formik.isSubmitting && !formik.isValid){
            toast.error("Verifique os campos obrigatórios.", {
                toastId: customId
            });
        }

        return !(formik.dirty || formik.isValid) || 
                (type === 'create' && !formik.dirty) ||
                loading;
    }

    const switchOrSubmitStep1 = (newTargetStep: number|undefined) => {
        if(newTargetStep) {
            setTargetStep(newTargetStep);
        }
        formik.submitForm();
    }

    const handleSubmit = (data: IStepOne) => {
        // console.log("data", data);
        if(type === 'create')
            saveStepOne(data, handleCallbackSubmit);
        if(type === 'edit') {
            if(formik.dirty) {
                editStepOne(data, courseCode, handleCallbackSubmit);
            } else {
                handleCallbackSubmit();
            }
        }
        if(type === 'read') handleCallbackSubmit();
    };

    const { setValues, ...formik } = useFormik({
        onSubmit: handleSubmit,
        validationSchema: schema,
        enableReinitialize: true,
        // initialValues: courseEditing,
        initialValues: {
            logo: courseEditing?.logo || undefined,
            titulo: courseEditing?.titulo || '',
            tituloLongo: courseEditing?.tituloLongo || '',
            nivel: courseEditing?.nivel || '',
            modalidade: courseEditing?.modalidade || 0,
            areaConhecimento: courseEditing?.areaConhecimento || '',
            inicioMatricula: courseEditing?.inicioMatricula || '',
            fimMatricula: courseEditing?.fimMatricula || '',
            inicioCurso: courseEditing?.inicioCurso || '',
            fimCurso: courseEditing?.fimCurso || '',
            numeroVagas: courseEditing?.numeroVagas || 0,
            publicoAlvo: courseEditing?.publicoAlvo || '',
            colaboradores: courseEditing?.colaboradores || [],
            objetivo: courseEditing?.objetivo || '',
            ementa: courseEditing?.ementa || '',
            orientacaoAosAlunos: courseEditing?.orientacaoAosAlunos || '',
            cronograma: courseEditing?.cronograma || '',
        } as IStepOne,
    });

  const text = (
        <Text>
          <p>Não se preocupe, as informações poderão ser alteradas a qualquer momento!<br />
          <b>Preenchimento das informações básicas sobre o curso.</b></p>

          Importante:
          <ol>
            <li>O que você digitar nos itens "resumo do curso" e "orientações aos alunos" e "cronograma" aparecerá na página inicial do seu curso;</li>
            <li>O que você digitar nos demais itens, aparecerá nas informações de divulgação do curso (página "dados do curso"), local onde os alunos se inscreverão;</li>
            <li>Existe uma opção "Logo(imagem)" que oferece espaço dentro do seu curso para incluir uma logo de sua instituição ou imagem desejada. Para isso, clique em "Procurar" e indique a localização da imagem. Será necessário que a imagem tenha o tamanho máximo de 40 x 30 pixels, ou ela aparecerá cortada.</li>
            <li>Campos marcados com * são obrigatórios.</li>
          </ol>
        </Text>
  );

    useImperativeHandle(ref, () => ({ switchOrSubmitStep1 }));

    useEffect(() => document?.getElementById('content')?.scrollTo({top: 0}),[]);

    useEffect(() => {
        if(type === 'create') trackMenuOption(4);
    }, [trackMenuOption, type]);

    useEffect(() => {
        fetchUsers();

        if(type === 'edit' || type === 'read') {
            getCourseById(courseCode);
        }
    }, [courseCode, type]);

    useEffect(() => {
        return () => {
            formik.resetForm();
            resetCourseEditing();
        }
    }, []);

    return (
        <Container className={className}>
            <Title>Passo 1: Informações iniciais</Title>
            <Text>{text}</Text>
            <section>
                <form onSubmit={formik.handleSubmit}>
                    {(type === 'edit' || type === 'read') && loadingFetch ? (
                        <Spinner/>
                    ) : (
                        <>
                            <div className="row-1">
                                <div className="row-1-1">
                                    <div className="row-1-1-1">
                                       <div>
                                            <Input
                                                label="Título longo do curso *"
                                                id="tituloLongo"
                                                name="tituloLongo"
                                                placeholder="Digite o título longo do curso"
                                                value={formik?.values?.tituloLongo}
                                                onChange={formik?.handleChange}
                                                errorText={getErrorMessage('tituloLongo')}
                                                readOnly={loading || type === 'read'}
                                            />
                                        </div>
                                        <div>
                                            <Input
                                                label="Título curto do curso *"
                                                id="titulo"
                                                name="titulo"
                                                placeholder="Digite o título curto do curso"
                                                value={formik?.values?.titulo}
                                                onChange={formik?.handleChange}
                                                errorText={getErrorMessage('titulo')}
                                                readOnly={loading || type === 'read'}
                                            />
                                        </div>
                                        <div>                                            
                                            <InputSelect
                                                label="Modalidade *"
                                                id="modalidade"
                                                name="modalidade"
                                                placeholder="Selecione a modalidade do curso"
                                                formik={formik}
                                                options={courseModalities?.map((x: DomainOption) => {
                                                    return {
                                                        value: x.codigo.toString(),
                                                        label: x.nome
                                                    }
                                                })}
                                                errorText={getErrorMessage('modalidade')}
                                                readOnly={loading}
                                                disabled={type === 'read'}
                                            />
                                        </div>
                                    </div>
                                    <div className="row-1-1-2">
                                        <InputSelect
                                            label="Nível deste curso *"
                                            id="nivel"
                                            name="nivel"
                                            placeholder="Selecione o nível do curso"
                                            formik={formik}
                                            options={levels?.map((x: Level) => {
                                                return {
                                                    value: x.codigo.toString(),
                                                    label: x.nome
                                                }
                                            })}
                                            errorText={getErrorMessage('nivel')}
                                            readOnly={loading}
                                            disabled={type === 'read'}
                                        />                                
                                        <InputSelect
                                            formik={formik}
                                            options={knowledgeAreas?.map((x: KnowledgeArea) => {
                                                return {
                                                    value: x.codigo.toString(),
                                                    label: x.descricao
                                                }
                                            })}
                                            label="Área de conhecimento deste curso *"
                                            name="areaConhecimento"
                                            placeholder="Selecione a área de conhecimento"
                                            errorText={getErrorMessage('areaConhecimento')}
                                            readOnly={loading}
                                            disabled={type === 'read'}
                                        />                                
                                    </div>
                                    
                                </div>
                                {/* <InputSingleFileUpload
                                    formik={formik}
                                    value={formik?.values?.logo}
                                    name="logo"
                                    label="Logo do curso"
                                    maxSizeMB={1}
                                    extensions={{
                                        'image/jpeg': [],
                                        'image/png': [],
                                    } as any}
                                /> */}
                                <InputSingleFileUploadPure
                                    name="logo"
                                    label="Logo do curso *"
                                    value={formik?.values?.logo || undefined}
                                    formik={formik}
                                    maxSizeMB={1}
                                    extensions={{
                                        'image/jpeg': [],
                                        'image/png': [],
                                    } as any}
                                    disabled={type === 'read'}
                                />                                
                            </div>
                            <div className="row-2">
                                <div className="row-2-1">
                                    {/* <InputDate
                                        label="Início da matrícula *"
                                        id="inicioMatricula"
                                        name="inicioMatricula"
                                        formik={formik}
                                        value={formik?.values?.inicioMatricula}
                                        noDefaultDate
                                        errorText={getErrorMessage('inicioMatricula')}
                                        readOnly={loading || type === 'read'}
                                    />
                                    <InputDate
                                        label="Término da matrícula *"
                                        id="fimMatricula"
                                        name="fimMatricula"
                                        formik={formik}
                                        value={formik?.values?.fimMatricula}
                                        noDefaultDate
                                        errorText={getErrorMessage('fimMatricula')}
                                        readOnly={loading || type === 'read'}
                                    />
                                    <InputDate
                                        label="Início do curso *"
                                        id="inicioCurso"
                                        name="inicioCurso"
                                        formik={formik}
                                        value={formik?.values?.inicioCurso}
                                        noDefaultDate
                                        errorText={getErrorMessage('inicioCurso')}
                                        readOnly={loading || type === 'read'}
                                    />
                                    <InputDate
                                        label="Término do curso *"
                                        id="fimCurso"
                                        name="fimCurso"
                                        formik={formik}
                                        value={formik?.values?.fimCurso}
                                        noDefaultDate
                                        errorText={getErrorMessage('fimCurso')}
                                        readOnly={loading || type === 'read'}
                                    /> */}

                                    {/* <Input
                                        label="Número de vagas *"
                                        id="numeroVagas"
                                        name="numeroVagas"
                                        type="number"
                                        value={formik?.values?.numeroVagas}
                                        onChange={formik?.handleChange}
                                        errorText={getErrorMessage('numeroVagas')}
                                        readOnly={loading || type === 'read'}
                                    /> */}                                    
                                    <InputDateHTML
                                        label="Início da matrícula *"
                                        id="inicioMatricula"
                                        name="inicioMatricula"
                                        value={formik?.values?.inicioMatricula}
                                        onChange={formik?.handleChange}
                                        errorText={getErrorMessage('inicioMatricula')}
                                        readOnly={loading || type === 'read'}
                                    />
                                    <InputDateHTML
                                        label="Término da matrícula *"
                                        id="fimMatricula"
                                        name="fimMatricula"
                                        value={formik?.values?.fimMatricula}
                                        onChange={formik?.handleChange}
                                        errorText={getErrorMessage('fimMatricula')}
                                        readOnly={loading || type === 'read'}
                                    />
                                    <InputDateHTML
                                        label="Início do curso *"
                                        id="inicioCurso"
                                        name="inicioCurso"
                                        value={formik?.values?.inicioCurso}
                                        onChange={formik?.handleChange}
                                        errorText={getErrorMessage('inicioCurso')}
                                        readOnly={loading || type === 'read'}
                                    />
                                    <InputDateHTML
                                        label="Término do curso *"
                                        id="fimCurso"
                                        name="fimCurso"
                                        value={formik?.values?.fimCurso}
                                        onChange={formik?.handleChange}
                                        errorText={getErrorMessage('fimCurso')}
                                        readOnly={loading || type === 'read'}
                                    />
                                    <InputMasked
                                        label="Número de vagas"
                                        id="numeroVagas"
                                        name="numeroVagas"
                                        maskType="int0to9999"
                                        maskPlaceholder=""
                                        alwaysShowMask={false}
                                        placeholder="Digite a quantidade de vagas (0 a 9999)"
                                        value={formik?.values?.numeroVagas}
                                        onChange={
                                            (e: React.ChangeEvent<HTMLInputElement>) => 
                                            formik?.setFieldValue('numeroVagas', e.target.value)
                                        }
                                        errorText={getErrorMessage('numeroVagas')}
                                        readOnly={loading || type === 'read'}
                                    />
                                    {/* <InputNumber
                                        label="Número de vagas *"
                                        id="numeroVagas"
                                        name="numeroVagas"
                                        value={formik?.values?.numeroVagas}
                                        onChange={formik?.handleChange}
                                        errorText={getErrorMessage('numeroVagas')}
                                        readOnly={loading || type === 'read'}
                                    /> */}
                                    <Input
                                        label="Público-alvo"
                                        id="publicoAlvo"
                                        name="publicoAlvo"
                                        placeholder="Digite o público-alvo"
                                        value={formik?.values?.publicoAlvo}
                                        onChange={formik?.handleChange}
                                        errorText={getErrorMessage('publicoAlvo')}
                                        readOnly={loading || type === 'read'}
                                    />
                                </div>
                                <InputCollaboratorsNoFormik
                                    label="Colaboradores"
                                    name="colaboradores"
                                    value={
                                        users?.map((user: User) => {
                                            return {
                                                id: user.id,
                                                nome: user?.nome || user?.username || 'N/A',
                                                foto: user?.fotoPerfilServer,
                                            } as CourseCollaborator
                                        }).filter((filteredUser: CourseCollaborator) => {
                                                return formik.values?.colaboradores?.find((userId: number) => userId === filteredUser.id)
                                            }
                                        )
                                        // || undefined
                                    }
                                    onChange={(e: MultiValue<CourseCollaborator>) => {
                                        // formik.setFieldValue('colaboradores', e?.id)
                                        formik.setFieldValue('colaboradores', [...e.map((x:any) => x.id)])
                                    }}                               
                                    options={users?.map((x: User) => {
                                        return {
                                            id: x.id,
                                            nome: x?.nome || x?.username || 'N/A',
                                            foto: x?.fotoPerfilServer,
                                        }
                                    })}
                                    placeholder="Busque os colaboradores"
                                    searchable
                                    // errorText={getErrorMessage('colaboradores')}
                                    readOnly={loading}
                                    disabled={type === 'read'}
                                />                                
                                {/* <InputCollaborators
                                    formik={formik}
                                    options={users?.map((x: User) => {
                                        return {
                                            id: x.id,
                                            nome: x?.nome || x?.username || 'N/A',
                                            foto: x?.fotoPerfilServer,
                                        }
                                    })}
                                    searchable
                                    label="Colaboradores"
                                    name="colaboradores"
                                    // errorText={getErrorMessage('colaboradores')}
                                    readOnly={loading}
                                    disabled={type === 'read'}
                                /> */}
                                <InputTextarea
                                    label="Objetivos"
                                    id="objetivo"
                                    name="objetivo"
                                    placeholder="Digite os objetivos deste curso"
                                    maxLength={100}
                                    value={formik?.values?.objetivo}
                                    onChange={formik?.handleChange}
                                    errorText={getErrorMessage('objetivo')}
                                    readOnly={loading || type === 'read'}
                                    height="120px"
                                />
                                <InputTextarea
                                    label="Ementa"
                                    id="ementa"
                                    name="ementa"
                                    placeholder="Digite a ementa deste curso"
                                    maxLength={255}
                                    value={formik?.values?.ementa}
                                    onChange={formik?.handleChange}
                                    errorText={getErrorMessage('ementa')}
                                    readOnly={loading || type === 'read'}
                                    height="120px"
                                />
                                <InputTextarea
                                    label="Orientações aos alunos"
                                    id="orientacaoAosAlunos"
                                    name="orientacaoAosAlunos"
                                    placeholder="Digite as orientações aos alunos deste curso"
                                    maxLength={255}
                                    value={formik?.values?.orientacaoAosAlunos}
                                    onChange={formik?.handleChange}
                                    errorText={getErrorMessage('orientacaoAosAlunos')}
                                    readOnly={loading || type === 'read'}
                                    height="120px"
                                />
                                <InputTextarea
                                    label="Cronograma"
                                    id="cronograma"
                                    name="cronograma"
                                    placeholder="Digite o cronograma deste curso"
                                    maxLength={255}
                                    value={formik?.values?.cronograma}
                                    onChange={formik?.handleChange}
                                    errorText={getErrorMessage('cronograma')}
                                    readOnly={loading || type === 'read'}
                                    height="120px"
                                />
                            </div>
                        </>
                    )}
                    <Buttons type={type}>
                        {type === 'edit' && (
                            <Button
                                type="button"
                                btnTheme="primary"
                                onClick={handleCourseEnvironmentClick}
                                disabled={checkDisabledSubmit()}
                            >
                                Ambiente de Curso
                            </Button>
                        )}
                        <span>
                            <Button
                                type="button"
                                btnTheme="primary"
                                onClick={handleCancelClick}
                            >
                                Cancelar
                            </Button>
                            <Button
                                type="submit"
                                btnTheme="primary"
                                disabled={checkDisabledSubmit()}
                            >
                                {loading ? <Spinner /> : formik.dirty ? 'Salvar Passo 1' : 'Próximo'}
                            </Button>
                        </span>
                    </Buttons>
                </form>
            </section>
        </Container>
    )
});

export default StepOne;