import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify";

import { AUTH_TOKEN_KEY } from "../../constants";
import { AuthUser } from "../models/Auth";

import { AccessData, RegisterData } from "../models/Auth";
import api from "../services/api";
import { authService } from "../services/domains/authService";

interface IAuthContext {
    logged: boolean;
    loggedUser: AuthUser|undefined;
    signIn(user: string, password: string): void;
    signOut(): void;
    registerNewUser(data: RegisterData): void;
    addPartialNewUser(data: RegisterData): void;
    getStorageAuhtenticatedUser(): AuthUser;
    hasPermission(): boolean;
}

interface Props {
    children?: React.ReactNode | React.ReactNode[];
};

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider: React.FC<Props> = ({ children }) => {
    const [loggedUser, setLoggedUser] = useState<AuthUser|undefined>(undefined);
    const [logged, setLogged] = useState<boolean>(() => {
        const isLogged = localStorage.getItem('@constructore-front:logged');

        return !!isLogged;
    });

    const navigate = useNavigate();

    const signIn = async (user: string, password: string) => {
        const { accessToken }: AccessData = await authService.login(user, password)

        localStorage.setItem(AUTH_TOKEN_KEY, accessToken);
        // localStorage.setItem(`${AUTH_TOKEN_KEY}:authorized`, JSON.stringify(true));

        api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

        const response: AuthUser = await authService.list();
        if(response) {
            localStorage.setItem('@constructore-front:logged', 'true');
            localStorage.setItem('@constructore-front:userAuthenticated', JSON.stringify(response));
            setLogged(true);
            setLoggedUser(response);
            // fetchUfs();
        }     
    }

    const signOut = () => {
        // localStorage.removeItem(`${AUTH_TOKEN_KEY}:authorized`);
        clearStorage();
        setLogged(false);
        setLoggedUser(undefined);
        navigate('/');
    }

    const registerNewUser = async (data: RegisterData) => {
        clearStorage();
        setLogged(false);        
        try {
            const response: any = await authService.registerNotAuthenticatedUser(data);
            if(response) {
                toast.success(response.message);
                navigate('/');
            }
        }
        catch (error) {
            console.log(error);
            // toast.error('Erro inesperado.');
        }
    }

    const addPartialNewUser = async (data: RegisterData) => {     
        try {
            const response: any = await authService.registerNotAuthenticatedUser(data);
            if(response) {
                toast.success(response.message);
                navigate('/admin');
            }
        }
        catch (error) {
            console.log(error);
            toast.error('Erro inesperado.');
        }
    }    

    const clearStorage = () => {
        localStorage.removeItem(AUTH_TOKEN_KEY);
        localStorage.removeItem('@constructore-front:logged');
        localStorage.removeItem('@constructore-front:userAuthenticated');        
    }

    const getStorageAuhtenticatedUser = () => {
        const userAthenticated = localStorage.getItem('@constructore-front:userAuthenticated');
        if(!userAthenticated) return undefined;
        return JSON.parse(userAthenticated);
    }

    const hasPermission = (): boolean => {
        const AuthUser: AuthUser|undefined = getStorageAuhtenticatedUser();
        
        if(!(AuthUser && AuthUser.perfis?.length > 0))
            return false;

        return AuthUser.perfis.some( x =>
                x.name === "PROFESSOR" ||
                x.name === "COLABORADOR" ||
                x.name === "ADMIN"
            );
    }    

    return (
        <AuthContext.Provider value={{
            logged,
            loggedUser,
            signIn,
            signOut,
            registerNewUser,
            addPartialNewUser,
            getStorageAuhtenticatedUser,
            hasPermission,
        }}>
            {children}
        </AuthContext.Provider>
    );
}

function useAuth(): IAuthContext {
    const context = useContext(AuthContext);
    return context;
}

export { AuthProvider, useAuth };