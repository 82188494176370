import styled from 'styled-components'

const BREAKPOINT = '1326px'; // em função de não mostrar scroll horizontal na tela

export const Container = styled.div`

`;

export const Content = styled.div`
    display: flex;
    gap: 40px;

    @media (max-width: ${BREAKPOINT}) {
        flex-direction: column;
    }

    article {
        width: 100%;

        section {
            
        }

        section:not(:last-of-type) {
            margin-bottom: 48px
        }
    }

    /* aside {
        width: 100%;
        width: 337px;
        background: #f8f9fa;

        @media (max-width: ${BREAKPOINT}) {
            width: 100%;
        }

        h2 {
            justify-content: end;
        }
    } */
`;

// export const List = styled.div`
//     display: flex;
//     flex-direction: column;
    
//     @media (max-width: 992px) {
//         row-gap: 16px;
//     }

//     &:last-child {
//         margin-bottom: 48px;
//     }

//     > div {
//         display: flex;
//         column-gap: 40px;
//         line-height: 24px;
//         text-align: left;

//         @media (max-width: 992px) {
//             flex-direction: column;
//         }

//         span {
//             &.name,
//             &.target-audience {
//                 width: 260px;
//                 min-width: 260px;
//             }

//             &.course-modality,
//             &.course-date {
//                 width: 100px;
//                 min-width: 100px;
//             }

//             p {
//                 white-space: nowrap;
//                 overflow: hidden;
//                 text-overflow: ellipsis;
//                 color: var(--primary);
//             }            
//         }
//     }
// `;

export const Empty = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10%;

    > div {
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        height: min-content;
        align-self: center;
        pointer-events: none;
        user-select: none;
        
        h1 {
            font-size: 40px;
            font-weight: bold;
            place-content: center;

            @media (max-width: 576px) {
                font-size: 28px;
            }
        }
    
        p {
            font-size: 20px;
            text-align: center;
        }
    }

    @media (max-width: 576px) {
        height: auto;
    }
`;