import { ReactNode, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Breadcrumb from "../../../components/Breadcrumb";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import InputDateHTML from "../../../components/InputDateHTML";
import InputSelect from "../../../components/InputSelect";
import InputSingleFileUploadPure from "../../../components/InputSingleFileUploadPure";
import InputMasked from "../../../components/InputMasked";
import Title from "../../../components/Title";
import Required from "../../../components/Required";
import { useDropdown } from "../../../shared/contexts/domains/DropdownContext";
import { useUser } from "../../../shared/contexts/UserContext";
import { User } from "../../../shared/models/Users";
import schema from "./schema";
import { LinkTypeEnum } from "../../../shared/enums/Domains";
import { DomainRegion, DomainSelectOption } from "../../../shared/models/domains/Domains";
import { LINK_TYPE } from "../../../shared/consts";
import { Buttons, Container } from "./styles";

const EditForm: React.FC = () => {
    const {
        userEditing,
        getUserById,
        editUser, 
        hasCompleteRegistration,
        isLoading,
        clearUserEditing,
    } = useUser();
    // const { trackMenuOption } = useMenu();
    const { regions } = useDropdown();
    const navigate = useNavigate();
    const { id } = useParams();
    // const [selectedDate, setSelectedDate] = useState<string|undefined>(undefined);
    const [rejectedFileFeedbackErrors, setRejectedFileFeedbackErrors] = useState<ReactNode|string|undefined>();
    const [filteredCities, setFilteredCities] = useState<DomainSelectOption[]>([]);
    const mounted = useRef(true);
    const customId = "invalid-form-fields";

    const handleSubmit = (data: User) => {
        editUser(data, '/Admin');
        // console.log("Dirty?: ", formik.dirty);
        // console.log("Formik: ", formik);
        // console.log(data);
    }

    const { setValues, ...formik } = useFormik({
        onSubmit: handleSubmit,
        validationSchema: schema,
        enableReinitialize: true,
        initialValues: userEditing as User,

        // initialValues: {
        //     ...userEditing,
        //     tipoVinculo: userEditing?.tipoVinculo || '0',
        // } as User,

        // initialValues: {
        //     id: 0,
        //     nome: '',
        //     dataNascimento: '',
        //     sexo: '',
        //     cpf: '',
        //     email: '',
        //     celularVisivel: '1',
        //     celular: '',
        //     vinculadoUFRJ: '0',
        //     tipoVinculo: '0',
        //     instituicao: '',
        //     fotoPerfilServer: undefined,
        //     roles: [],
        //     username: '',
        //     // password: '',
        //     dre: '',
        //     siape: '',
        //     centro: '',
        //     // estado: undefined,
        //     // cidade: undefined,
        //     departamento: '',
        // } as User,
    });

    const handleCancelClick = () => {
        navigate('/Admin');
    }

    const getRegionUFs = () => {
        const ufs: DomainSelectOption[] = regions.map(x => {
            return {
                value: x.codigo.toString(),
                label: x.sigla,
            }
        });

        return ufs;
    }

    // const handleSelected = (date: Date) => {
    //     const dateStr = date.toISOString();
    //     setSelectedDate(dateStr);
    // }
    // const handleDateChange = (fieldName: string, date: Date) => {
    //     const dateStr = date.toISOString();
    //     formik.setFieldValue(fieldName, dateStr);
    //     setSelectedDate(dateStr);
    // }

    // useEffect(() => {
    //     trackMenuOption(6);
    // }, [trackMenuOption]);

    useEffect(() => {
        toast.error(rejectedFileFeedbackErrors);
    },[rejectedFileFeedbackErrors]);

    useEffect(() => {
        let idParam: number = 0;
        if(id?.includes(':')) {
            idParam = Number(id?.split(':')[1]);
        } else {
            idParam = Number(id);
        }
        if(idParam) {
            getUserById(idParam);
        }
    },[]);

    // useEffect(() => {
    //     if(userEditing && mounted?.current === true && !isLoading) {
    //         setValues({
    //             id: userEditing?.id,
    //             nome: userEditing?.nome || formik.values.nome,
    //             dataNascimento: userEditing?.dataNascimento || formik.values.dataNascimento,
    //             sexo: userEditing?.sexo || formik.values.sexo,
    //             cpf: userEditing?.cpf || formik.values.cpf,
    //             email: userEditing?.email || formik.values.email,                
    //             celularVisivel: userEditing?.celularVisivel || formik.values.celularVisivel,
    //             // celularVisivel: '',
    //             celular: userEditing?.celular || formik.values.celular,
    //             estado: userEditing?.estado || formik.values.estado,
    //             cidade: userEditing?.cidade || formik.values.cidade,
    //             vinculadoUFRJ: userEditing?.vinculadoUFRJ || formik.values.vinculadoUFRJ,
    //             tipoVinculo: userEditing?.tipoVinculo || formik.values.tipoVinculo,
    //             instituicao: userEditing?.instituicao || formik.values.instituicao,
    //             fotoPerfilServer: userEditing?.fotoPerfilServer || formik.values.fotoPerfilServer,
    //             roles: userEditing?.roles,
    //             username: userEditing?.username,
    //             // password: userEditing?.password,
    //             dre: userEditing?.dre || formik.values.dre,
    //             siape: userEditing?.siape || formik.values.siape,
    //             centro: userEditing?.centro || formik.values.centro,
    //             departamento: userEditing?.departamento || formik.values.departamento,
    //         });
    //     }
    // },[userEditing, isLoading]);

    useEffect(() => {
        // const selectedUF = userEditing?.estado;
        const selectedUF = formik?.values?.estado;
        const cities:any = selectedUF ? regions?.find((x: DomainRegion) => {
            if(x.codigo.toString() === selectedUF) {
                return x.cidades;
            }
        }) : [];

        let aux = [];
        if(cities?.cidades) {
            aux = cities.cidades.map((city:any) => {
                return {
                    value: city.codigo.toString(),
                    label: city.nome,
                } as DomainSelectOption;
            });
        }

        setFilteredCities(aux);
        
    },[formik?.values?.estado, regions]);

    useEffect(() => {
        if(formik.isSubmitting && !formik.isValid){
            toast.error("Verifique os campos obrigatórios.", {
                toastId: customId
            });
        }
    },[formik.isSubmitting, formik.isValid]);

    useEffect(() => {
        return () => clearUserEditing();
    },[]);

    return (
        <Container>
            <Breadcrumb>
                <span>
                    <Link to="/Admin" aria-label="Usuários (Migalha de pão)">
                        Usuários
                    </Link>
                </span>
                <span>Edição de Usuário</span>
            </Breadcrumb>

            <Title>Edição de Usuário</Title>

            <Required />

            <section>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row-1">
                        <div>
                            <Input
                                label="Nome Completo *"
                                id="nome"
                                name="nome"
                                placeholder="Digite seu nome completo"
                                value={formik?.values?.nome}
                                onChange={formik?.handleChange}
                                errorText={
                                    formik?.touched?.nome && formik?.errors?.nome
                                    ? formik?.errors?.nome
                                    : undefined
                                }
                            />
                            <div>
                                <InputDateHTML
                                    label="Data de Nascimento *"
                                    id="dataNascimento"
                                    name="dataNascimento"
                                    value={formik?.values?.dataNascimento || ''}
                                    onChange={formik?.handleChange}
                                    errorText={
                                        formik?.touched?.dataNascimento && formik?.errors?.dataNascimento
                                        ? formik?.errors?.dataNascimento
                                        : undefined
                                    }
                                />
                                {/* <InputDate
                                    label="Data de Nascimento *"
                                    id="dataNascimento"
                                    name="dataNascimento"
                                    formik={formik}
                                    value={formik?.values?.dataNascimento}
                                    noDefaultDate
                                    // defaultDate={new Date('2022-09-25T00:00:00')}
                                    errorText={
                                        formik?.touched?.dataNascimento && formik?.errors?.dataNascimento
                                        ? formik?.errors?.dataNascimento
                                        : undefined                                      
                                    }
                                /> */}
                                {/* <InputDateNoFormik
                                    label="Data de Nascimento *"
                                    id="dataNascimento"
                                    name="dataNascimento"
                                    // selected={selectedDate && selectedDate!=='' ? new Date(selectedDate) : undefined}
                                    selected={selectedDate}
                                    onSelect={date => handleSelected(date)}
                                    onChange={date => handleDateChange('dataNascimento', date)}
                                    noDefaultDate
                                /> */}
                                <InputSelect
                                    label="Sexo *"
                                    name="sexo"
                                    formik={formik}
                                    options={[
                                        { value: '1', label: 'Masculino' },
                                        { value: '2', label: 'Feminino' },
                                        { value: '3', label: 'Não Informado' },
                                    ]}
                                    errorText={
                                        formik?.touched?.sexo && formik?.errors?.sexo
                                        ? formik?.errors?.sexo
                                        : undefined                                      
                                    }
                                />
                            </div>
                            <div>
                                {/* <Input
                                    label="CPF *"
                                    id="cpf"
                                    name="cpf"
                                    placeholder="00000000000"
                                    value={formik?.values?.cpf}
                                    onChange={formik?.handleChange}
                                    errorText={
                                        formik?.touched?.cpf && formik?.errors?.cpf
                                        ? formik?.errors?.cpf
                                        : undefined
                                    }
                                /> */}
                                <InputMasked
                                    maskType='cpf'
                                    label="CPF *"
                                    id="cpf"
                                    name="cpf"
                                    placeholder="000.000.000-00"
                                    value={formik?.values?.cpf}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        formik?.setFieldValue('cpf', e.target.value)
                                    }
                                    errorText={
                                        formik?.touched?.cpf && formik?.errors?.cpf
                                        ? formik?.errors?.cpf
                                        : undefined
                                    }
                                />
                                <Input
                                    label="E-mail *"
                                    name="email"
                                    placeholder="Digite seu E-mail"
                                    value={formik?.values?.email}                                
                                    onChange={formik?.handleChange}
                                    errorText={
                                        formik?.touched?.email && formik?.errors?.email
                                        ? formik?.errors?.email
                                        : undefined                                      
                                    }
                                />
                            </div>
                            <div>
                                <InputSelect
                                    formik={formik}
                                    options={[
                                        { value: '0', label: 'Não' },
                                        { value: '1', label: 'Sim' },
                                    ]}
                                    defaultValue={{ value: '1', label: 'Sim' }}
                                    label="Telefone Visível"
                                    name="celularVisivel"
                                />
                                {/* <Input
                                    label="Celular"
                                    name="celular"
                                    placeholder="00000000000"
                                    value={formik?.values?.celular}
                                    onChange={formik?.handleChange}
                                /> */}
                                <InputMasked
                                    maskType='cellPhone'
                                    label="Celular"
                                    id="celular"
                                    name="celular"
                                    placeholder="(00) 0 0000-0000"
                                    value={formik?.values?.celular}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        formik?.setFieldValue('celular', e.target.value)
                                    }
                                    errorText={
                                        formik?.touched?.celular && formik?.errors?.celular
                                        ? formik?.errors?.celular
                                        : undefined
                                    }
                                />
                            </div>
                        </div>
                        {/* <Photo>
                            <span>Foto do usuário</span>
                            {mounted?.current === true
                            && userEditing
                            && (
                                <InputSingleFileUpload
                                    name="fotoPerfilServer"
                                    formik={formik}
                                />
                            )}
                            <small>Tamanho máximo 5 MB. Formatos permitidos: .png, .jpg, .gif</small>
                        </Photo> */}
                        {mounted?.current === true
                            && userEditing
                            && (
                                <InputSingleFileUploadPure
                                    name="fotoPerfilServer"
                                    value={formik?.values?.fotoPerfilServer || undefined}
                                    formik={formik}
                                    maxSizeMB={1}
                                    extensions={{
                                        'image/jpeg': [],
                                        'image/png': [],
                                    } as any}
                                />
                                // <InputSingleFileUpload 
                                //     name="fotoPerfilServer"
                                //     formik={formik}
                                //     rejectedFileFeedback={setRejectedFileFeedbackErrors}
                                // />
                        )}                        
                    </div>
                    <div className="row-2">
                        <InputSelect
                            label="UF *"
                            name="estado"
                            formik={formik}
                            options={getRegionUFs()}
                            // options={[
                            //     { value: '1', label: 'RJ' },
                            //     { value: '2', label: 'SP' },
                            //     { value: '3', label: 'MG' },
                            //     { value: '4', label: 'AM' },
                            //     { value: '5', label: 'RO' },
                            //     { value: '6', label: 'MG' },
                            //     { value: '7', label: 'RS' },
                            // ]}
                            searchable
                            // dependentFieldName='cidade'
                            errorText={
                                formik?.touched?.estado && formik?.errors?.estado
                                ? formik?.errors?.estado
                                : undefined
                            }
                        />
                        <InputSelect
                            label="Cidade *"
                            name="cidade"
                            formik={formik}
                            options={filteredCities}
                            // options={[
                            //     { value: '1', label: 'Rio de Janeiro' },
                            //     { value: '2', label: 'Caxias' },
                            //     { value: '3', label: 'Ouro Preto' },
                            //     { value: '4', label: 'Manaus' },
                            //     { value: '5', label: 'Vilhena' },
                            //     { value: '6', label: 'Três Corações' },
                            //     { value: '7', label: 'Rio Grande' },
                            //     { value: '8', label: 'Gramado' },
                            // ]}
                            searchable
                            // isLoading={filteredCities?.length === 0}
                            disabled={filteredCities?.length === 0}
                            errorText={
                                formik?.touched?.cidade && formik?.errors?.cidade
                                ? formik?.errors?.cidade
                                : undefined
                            }
                        />
                        <InputSelect
                            label="Vinculado à UFRJ"
                            name="vinculadoUFRJ"
                            formik={formik}
                            options={[
                                { value: '0', label: 'Não' },
                                { value: '1', label: 'Sim' },
                            ]}
                            defaultValue={{ value: '0', label: 'Não' }}
                        />

                        {formik?.values?.vinculadoUFRJ === '1' && (
                            <InputSelect
                                label="Tipo de Vínculo"
                                name="tipoVinculo"
                                formik={formik}
                                options={LINK_TYPE}
                                defaultValue={LINK_TYPE[0]}
                                errorText={formik?.errors?.tipoVinculo}
                            />
                        )}

                        {formik?.values?.vinculadoUFRJ === '1' 
                            && formik?.values?.tipoVinculo === LinkTypeEnum.TEACHER && (
                            <>
                                <Input
                                    label="SIAPE *"
                                    id="siape"
                                    name="siape"
                                    placeholder="Digite o SIAPE"
                                    value={formik?.values?.siape}
                                    onChange={formik?.handleChange}
                                    errorText={formik?.errors?.siape}
                                />
                                <Input
                                    label="Centro"
                                    id="centro"
                                    name="centro"
                                    placeholder="Digite o Centro"
                                    value={formik?.values?.centro}
                                    onChange={formik?.handleChange}
                                    errorText={formik?.errors?.centro}
                                />
                                <Input
                                    label="Instituto"
                                    id="instituicao"
                                    name="instituicao"
                                    placeholder="Instituto, Faculdade, Escolas, Hospitais e Museus"
                                    value={formik?.values?.instituicao}
                                    onChange={formik?.handleChange}
                                    errorText={formik?.errors?.instituicao}
                                />
                                <Input
                                    label="Departamento"
                                    id="departamento"
                                    name="departamento"
                                    placeholder="Digite seu Departamento"
                                    value={formik?.values?.departamento}
                                    onChange={formik?.handleChange}
                                    // errorText={formik?.errors?.departamento}
                                />
                                <Input
                                    label="Outros"
                                    id="outros"
                                    name="outros"
                                    placeholder="Outros"
                                    // value={formik?.values?.outros}
                                    onChange={formik?.handleChange}
                                    // errorText={formik?.errors?.outros}
                                />
                            </>
                        )}

                        {formik?.values?.vinculadoUFRJ === '1' 
                            && formik?.values?.tipoVinculo === LinkTypeEnum.STUDENT && (
                            <>
                                <Input
                                    label="DRE *"
                                    id="dre"
                                    name="dre"
                                    placeholder="Digite seu DRE"
                                    value={formik?.values?.dre}
                                    onChange={formik?.handleChange}
                                    errorText={formik?.errors?.dre}
                                />
                                <Input
                                    label="Curso"
                                    id="curso"
                                    name="curso"
                                    placeholder="Digite seu Curso"
                                    // value={formik?.values?.curso}
                                    onChange={formik?.handleChange}
                                    // errorText={formik?.errors?.curso}
                                />
                                <Input
                                    label="Nível"
                                    id="nivel"
                                    name="nivel"
                                    placeholder="Digite o Nível"
                                    // value={formik?.values?.nivel}
                                    onChange={formik?.handleChange}
                                    // errorText={formik?.errors?.nivel}
                                />
                                <Input
                                    label="Centro"
                                    id="centro"
                                    name="centro"
                                    placeholder="Digite o Centro"
                                    value={formik?.values?.centro}
                                    onChange={formik?.handleChange}
                                    errorText={formik?.errors?.centro}
                                />
                                <Input
                                    label="Outros"
                                    id="outros"
                                    name="outros"
                                    placeholder="Outros"
                                    // value={formik?.values?.outros}
                                    onChange={formik?.handleChange}
                                    // errorText={formik?.errors?.outros}
                                />
                            </>
                        )}

                    </div>
                    <Buttons>
                        {hasCompleteRegistration && (
                            <Button btnTheme="primary" onClick={handleCancelClick}>
                                Cancelar
                            </Button>
                        )}
                        <Button
                            type="submit"
                            btnTheme="primary"
                            disabled={isLoading || !formik?.dirty}
                        >
                            Salvar
                        </Button>
                    </Buttons>
                </form>
            </section>
        </Container>
    )
}
export default EditForm;