import { Link } from "react-router-dom";
import {
    FaAdjust as IconHighContrast,
    // FaSitemap as IconSiteMap,
    // FaWheelchair as IconAccessibility,
} from "react-icons/fa";
import { useAccessibility } from "../../shared/contexts/AccessibilityContext";
import { useAuth } from "../../shared/contexts/Auth";
import { useUser } from "../../shared/contexts/UserContext";
import { Container } from "./styles";


const AccessibilityToolbar: React.FC = () => {
    const {
        toggleHighContrast,
        increaseFontSize,
        // decreaseFontSize,
        resetFontSize
    } = useAccessibility();
    const { logged } = useAuth();
    const { hasCompleteRegistration } = useUser();

    const handleGoToSearchClick = () => {
        const element = document.getElementById("mainSearch");
        element?.focus();
    }

    const handleGoToMenuClick = () => {
        const element = document.getElementById("first");
        element?.focus({ focusVisible: true } as FocusOptions);
    }

    const handleGoToContentClick = () => {
        const element = document.querySelector("#content a") as HTMLInputElement || 
                        document.querySelector("#content .dropzone") as HTMLInputElement ||
                        document.querySelector("#content input") as HTMLInputElement;
        element?.focus({ focusVisible: true } as FocusOptions);
    }    
    
    // useEffect(() => {
    //     let altPressed = false;

    //     document.addEventListener('keydown', (e: KeyboardEvent) => {
    //         if((e.charCode || e.keyCode) === 18) altPressed = true;
    //     });
    //     document.addEventListener('keyup', (e: KeyboardEvent) => {            
    //         if((e.charCode || e.keyCode) === 18) altPressed = false;
    //     });        

    //     document.addEventListener('keydown', (e: KeyboardEvent) => {
    //         if((e.charCode || e.keyCode) === 49 && altPressed) {
    //             console.log('Alt + 1')
    //         }
    //     });
    //     document.addEventListener('keydown', (e: KeyboardEvent) => {
    //         if((e.charCode || e.keyCode) === 50 && altPressed) {
    //             console.log('Alt + 2')
    //         }
    //     });
    //     document.addEventListener('keydown', (e: KeyboardEvent) => {
    //         if((e.charCode || e.keyCode) === 51 && altPressed) {
    //             console.log('Alt + 3')
    //         }
    //     });
    //     document.addEventListener('keydown', (e: KeyboardEvent) => {
    //         if((e.charCode || e.keyCode) === 52 && altPressed) {
    //             console.log('Alt + 4')
    //         }
    //     });        
    // }, []);

    return (
        <Container>
            {logged && hasCompleteRegistration && (
                <div className="left">
                    <Link to="#" onClick={handleGoToContentClick}>Ir para Conteúdo</Link>
                    |
                    <Link to="#" onClick={handleGoToMenuClick}>Ir para Menu</Link>
                    |
                    <Link to="#" onClick={handleGoToSearchClick}>Ir para Busca</Link>
                    |
                    <Link to="/sitemap" aria-label="Mapa do Site">Mapa do Site</Link>
                    |
                    <Link to="/accessibility" aria-label="Link de acessibilidade">Acessibilidade</Link>
                </div>
            )}
            <div className="right">
                <span>
                    {/* <button
                        onClick={decreaseFontSize}
                        title="Reduzir fonte"
                        aria-label="Reduzir fonte"
                    >
                        A-
                    </button>*/}
                    <button
                        onClick={resetFontSize}
                        title="Retornar ao tamanho de fonte padrão"
                        aria-label="Retornar ao tamanho de fonte padrão"
                    >
                        A
                    </button>
                    <button
                        onClick={increaseFontSize}
                        title="Aumentar fonte"
                        aria-label="Aumentar fonte"
                    >
                        A+
                    </button>
                </span>

                <button
                    onClick={toggleHighContrast}
                    title="Alto contraste"
                    aria-label="Alto contraste"
                >
                    <IconHighContrast/>
                </button>
            </div>
        </Container>
    )
}
export default AccessibilityToolbar;