import { Link, useLocation } from "react-router-dom";
import {
    FaAngleDoubleLeft,
    FaAngleDoubleRight,
    FaAsterisk,
    FaBox,
    FaCalendarAlt,
    FaChalkboardTeacher,
    FaEye,
    FaListUl,
    FaPencilRuler,
    FaPuzzlePiece,
    FaSatelliteDish,
    FaSdCard,
    FaServer,
    FaUserAlt,
    FaUserCog,
    FaUserLock,
    FaUsers
} from "react-icons/fa";
import { useMenu } from "../../shared/contexts/MenuContext";
import { Container, Puller, Options } from "./styles";
import { useUser } from "../../shared/contexts/UserContext";
import { useAuth } from "../../shared/contexts/Auth";

const Menu: React.FC = () => {
    const {
        menu,
        trackedOption,
        openOrCloseMenu,
        expanderManagementOption
    } = useMenu();
    const { hasCompleteRegistration } = useUser();
    const { hasPermission } = useAuth();
    const location = useLocation();

    const handleClick = () => {
        openOrCloseMenu();
    };

    if(location.pathname?.includes('courseenvironment')) {
        return (
            <Container
                className={'aux-menu ' + (!hasCompleteRegistration ? 'closed' : menu.isOpen ? 'open' : 'closed')}
                role="navigation"
            >
                <Options aria-label="Menu Ambiente de Curso">
                    <li className={trackedOption===1 ? 'active' : ''}>
                        <Link id="first" to={location.pathname} title="Apresentação">
                            <div><FaAsterisk /><span>Apresentação</span></div>
                        </Link>
                    </li>
                    {hasCompleteRegistration && (
                        <li className={trackedOption===2 ? 'active' : ''}>
                            <Link to='#' title="MenuOpcao2">
                                <div><FaPuzzlePiece /><span>Menu Opcao 2</span></div>
                            </Link>
                        </li>
                    )}
                    {hasCompleteRegistration && (
                        <li className={trackedOption===3 ? 'active' : ''}>
                            <Link to='#' title="MenuOpcao3">
                                <div><FaSatelliteDish /><span>Menu Opcao 3</span></div>
                            </Link>
                        </li>
                    )}
                    {hasCompleteRegistration && (
                        <li className={trackedOption===4 ? 'active' : ''}>
                            <Link to='#' title="MenuOpcao4">
                                <div><FaEye /><span>Menu Opcao 4</span></div>
                            </Link>
                        </li>
                    )}
                    {hasCompleteRegistration && (
                        <li className={trackedOption===5 ? 'active' : ''}>
                            <Link to='#' title="MenuOpcao5">
                                <div><FaListUl /><span>Menu Opcao 5</span></div>
                            </Link>
                        </li>
                    )}
                    {hasCompleteRegistration && (
                        <li className={trackedOption===6 ? 'active' : ''}>
                            <Link to='#' title="MenuOpcao6">
                                <div><FaBox /><span>Menu Opcao 6</span></div>
                            </Link>
                        </li>
                    )}
                    {hasCompleteRegistration && (
                        <li className={trackedOption===7 ? 'active' : ''}>
                            <Link to='#' title="MenuOpcao7">
                                <div><FaUsers /><span>Menu Opcao 7</span></div>
                            </Link>
                        </li>
                    )}                    
                    {hasCompleteRegistration && (
                        <li className={trackedOption===8 ? 'active' : ''}>
                            <Link to='#' title="MenuOpcao8">
                                <div><FaSdCard /><span>Menu Opcao 8</span></div>
                            </Link>
                        </li>
                    )}
                    {hasCompleteRegistration && (
                        <li className={trackedOption===9 ? 'active' : ''}>
                            <Link to='#' title="MenuOpcao9">
                                <div><FaUserLock /><span>Menu Opcao 9</span></div>
                            </Link>
                        </li>
                    )}
                </Options>
                { hasCompleteRegistration && (
                    <Puller
                        onClick={() => handleClick()}
                        aria-label={menu.isOpen ? "Retrair menu" : "Expandir menu"}>
                        {menu.isOpen ? <FaAngleDoubleLeft /> : <FaAngleDoubleRight />}
                    </Puller>
                )}
            </Container>
        )
    }

    return (
        <Container
            className={'aux-menu ' + (!hasCompleteRegistration ? 'closed' : menu.isOpen ? 'open' : 'closed')}
            role="navigation"
        >
            <Options aria-label="Menu principal">
                {hasCompleteRegistration && (
                    <li className={trackedOption===1 || trackedOption===0 ? 'active' : ''}>
                        <Link id="first" to='/mycourses' title="Meus cursos">
                            <div><FaChalkboardTeacher /><span>Meus cursos</span></div>
                        </Link>
                    </li>
                )}
                <li className={trackedOption===2 ? 'active' : ''}>
                    <Link to='/mydata' title="Meus dados">
                        <div><FaUserAlt /><span>Meus dados</span></div>
                    </Link>
                </li>
                {hasCompleteRegistration && (
                    <li className={trackedOption===3 ? 'active' : ''}>
                        <Link to='#' title="Agenda">
                            <div><FaCalendarAlt /><span>Agenda</span></div>
                        </Link>
                    </li>
                )}
                {hasCompleteRegistration && hasPermission() && (
                    <li className={trackedOption===4 ? 'active' : ''}>
                        <Link to='/course' title="Criar curso" reloadDocument={true}>
                            <div><FaPencilRuler /><span>Criar curso</span></div>
                        </Link>
                    </li>
                )}
                {hasCompleteRegistration && (
                    <li className={trackedOption===5 ? 'active' : ''}>
                        <Link to='#' title="Recursos">
                            <div><FaServer /><span>Recursos</span></div>
                        </Link>
                    </li>
                )}
                {hasCompleteRegistration && (
                    <li /*className={trackedOption===6 ? 'active' : ''}*/>
                        <Link
                            to='#'
                            title="Administração"
                            onClick={e => expanderManagementOption()}
                            className={`option-parent ${trackedOption===6 ? 'active' : ''}`}
                            aria-label={`Administração sub-menu: ${!menu.options.isOpenManagement || !menu.isOpen ? 'fechado' : 'aberto'}`}
                        >
                            <div><FaUserCog /><span>Administração</span></div>
                        </Link>
                        <ul
                            className={`option-parent-list ${
                                !menu.options.isOpenManagement || !menu.isOpen ? 'closed' : ''
                            }`}
                        >
                            <li>Cursos</li>
                            <li>
                                <Link to='/Admin' title="Usuários" aria-label="Usuários">
                                    <span>Usuários</span>
                                </Link>
                            </li>
                            <li>Layout</li>
                            <li>Estatística</li>
                            <li>Grupos de áreas de conhecimento</li>
                            <li>Perguntas de inscrição</li>
                            <li>Suporte técnico</li>
                        </ul>
                    </li>
                )}
            </Options>
            { hasCompleteRegistration && (
                <Puller
                    onClick={() => handleClick()}
                    aria-label={menu.isOpen ? "Retrair menu" : "Expandir menu"}>
                    {menu.isOpen ? <FaAngleDoubleLeft /> : <FaAngleDoubleRight />}
                </Puller>
            )}
        </Container>
    )
};

export default Menu;