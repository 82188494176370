import styled, { css } from "styled-components";

interface Props {
    btnTheme: 'primary' | 'secondary' | 'link';
}

export const Container = styled.button<Props>`
    ${prop => {
    if (prop?.btnTheme === 'primary' || prop?.btnTheme === 'secondary')
        return css<Props>`
            /* width: 100%; */
            width: auto;
            margin: 7px 0;
            padding: 10px;
            font-weight: bold;
            background-color: ${(x:Props) => 
                x.btnTheme === "primary"
                ?
                'var(--primary)'
                :
                x.btnTheme === "secondary"
                    ? 'var(--secondary)'
                    : '#eee'
                };
            color: #fff;            
            transition: opacity .3s;

            &:not(:disabled):hover {
                opacity: .8;
            }
        `;
    }}

    ${props => {
    if (props?.btnTheme === 'link')
        return css<Props>`
            width: auto;
            color: ${x => x.theme.colors.linkText};

            &:hover {
                text-decoration: underline;
            }
        `;
    }}    

    &:disabled {
        background-color: #eee;
        color: #949494;
    } 
`;