import api from './api';
import { IStepFive } from '../models/course/Course';

const ENDPOINT = 'curso_liberacao';

export const courseReleasedAssociationService = {
    list,
    edit,
};

async function list(courseCode: number): Promise<IStepFive> {
    const { data } = await api.get<any>(`${ENDPOINT}/${courseCode}`);

    return data;
    // return { // mockup
    //     liberadoVisualizacaoEmail: {
    //         chave: '',
    //         id: 1,
    //         selecionada: true,
    //         valor: 'E-mail',
    //     },
    //     liberadoVisualizacaoLocalidade: {
    //         chave: '',
    //         id: 2,
    //         selecionada: true,
    //         valor: 'Endereço',
    //     },
    //     liberadoVisualizacaoTelefone: {
    //         chave: '',
    //         id: 3,
    //         selecionada: true,
    //         valor: 'Telefone',
    //     },
    //     cursoLiberado: false,
    // }
};

async function edit(command: IStepFive, courseCode: number): Promise<any> {
    const { data } = await api.put<any>(`${ENDPOINT}/${courseCode}`, command);

    return data;
};