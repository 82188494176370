import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Title from "../../components/Title";
import { Container } from "./styles";

const SiteMap: React.FC = () => {

    return (
        <Container>
            <Breadcrumb>
                <span>Mapa do Site</span>
            </Breadcrumb>
            <Title>Mapa do Site</Title>
            <section>
                <span>
                    <h4>Menu de Navegação</h4>
                    <ul>
                        <li><Link to="/mycourses">Meus cursos</Link></li>
                        <li><Link to="/mydata">Meus dados</Link></li>
                        <li>Agenda</li>
                        <li><Link to="/course">Criar Curso</Link></li>
                        <li>Recursos</li>
                        <li>Administração
                            <ul>
                                <li>Cursos</li>
                                <li><Link to="/admin">Usuários</Link></li>
                                <li>Layout</li>
                                <li>Estatística</li>
                                <li>Grupos de áreas de conhecimento</li>
                                <li>Perguntas de inscrição</li>
                                <li>Suporte técnico</li>
                            </ul>
                        </li>
                    </ul>
                </span>
                <span>
                    <h4>Menu Funcionalidades</h4>
                    <ul>
                        <li><Link to="/home">Minha Constructore</Link></li>
                        <li>Manual</li>
                        <li>Imprimir</li>
                        <li>Fale Conosco</li>
                        <li>Informações</li>
                    </ul>
                </span>
            </section>
        </Container>
    );
}
export default SiteMap;
