import styled from "styled-components";
import 'react-confirm-alert/src/react-confirm-alert.css';

interface Props {
    bgColor: string;
}

export const Dialog = styled.div<Props>`
    display: flex;
    flex-direction: column;
    padding: 32px;
    gap: 32px;
    width: 749px;
    background-color: ${props => props.bgColor};
    border: 1px solid #5B0C0F;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    h1, p {
        align-self: center;
    }

    h1 {
        /* color: ${props => props?.theme?.colors?.titleText}; */
        color: #000;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
    }

    p {
        /* color: ${props => props?.theme?.colors?.success}; */
        color: #000;
    }

    @media (max-width: 576px) {
        width: unset;
    }    
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: end;
    column-gap: 32px;

    button {
        width: 95px;
        font-weight: normal;
        font-size: 16px;
        margin: 0;
    }
`;