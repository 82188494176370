import Select, {
    AriaGuidanceProps,
    AriaOnChangeProps,
    AriaOnFocusProps,
    GroupBase
} from "react-select";
import { SelectStyles } from "../../assets/styles/SelectStyles";
import { DomainSelectOption } from "../../shared/models/domains/Domains";
import { Container } from "./styles";

interface Props {
    formik?: any;
    options: DomainSelectOption[];
    // filterOption: boolean;
    defaultValue?: DomainSelectOption;
    value?: string | number;
    label?: string;
    id?: string;
    name: string;
    placeholder?: string;
    disabled?: boolean;
    searchable?: boolean;
    readOnly?: boolean;
    errorText?: string;
    fit?: boolean;
    isLoading?: boolean;
    className?: string;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    // dependentFieldName?: string;
    // onChange?(e: React.ChangeEvent<unknown>): void;
    // onChange?((newValue: SingleValue<DomainSelectOption>, actionMeta: ActionMeta<DomainSelectOption>)): void;
    // onChange?:(option: any) => void;
    onBlur?(e: React.FocusEvent<unknown>): void;
}

const InputSelect: React.FC<Props> = ({
    formik,
    options,
    // filterOption = false,
    defaultValue = null,
    value = '',
    id,
    name,
    label,
    placeholder = 'Selecione',
    disabled = false,
    searchable = false,
    errorText,
    fit = false,
    isLoading = false,
    // dependentFieldName,
    // onChange,
    className = '',
    onBlur
}) => {

    const handleValue = (): DomainSelectOption | undefined => {
        if(!(options && formik?.values && name && name !== "" && formik.values[name])) return undefined;
        
        try {
            return options.find(
                (x: DomainSelectOption) => x.value.toString() === formik.values[`${name}`].toString()
            );
        } catch (error) {
            console.log(error);
        }
    };
    
    // const handleValue = useCallback(() => {
    //     if(!options || options.length === 0) return undefined;

    //     try {
    //         const aux: DomainSelectOption | undefined = options.find(
    //             (x: DomainSelectOption) => {
    //                 return x.value.toString() === formik.values[`${name}`]
    //             }
    //         );
    //         return aux;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // },[formik.values, name, options]);

    // useEffect(() => {
    //     return () => {
    //         // TODO: isto está causando um erro ao ir da tela "Edição de Usuário" para a tela "Meus Dados"
    //         try {
    //             if(!!name && !!formik?.initialValues && !!options && !!formik && !!formik.values) {
    //                 formik.setFieldValue(name, formik.initialValues[name])
    //             }
    //         } catch(error) {
    //             console.log("Erro no InputSelect: ", error)
    //         }
    //     }
    // }, []);

    return (
        <Container>
            { label && (
                <label id={`aria-label-${id || name}`} htmlFor={id || name}>{label}</label>
            )}
            <div>
                <Select
                    inputId={id || name}
                    name={name}
                    aria-labelledby={`aria-label-${id || name}`}
                    value={handleValue()}
                    // onChange={formik?.handleChange}
                    onChange={(option: any) => {
                        // if(dependentFieldName && dependentFieldName!=='') {
                        //     formik.setFieldValue(dependentFieldName, formik.initialValues[dependentFieldName]);
                        //     // console.log(`Mudança no campo ${name} interferiu no campo ${dependentFieldName}`);
                        // }
                        formik.setFieldValue(name, option.value)
                    }}
                    // onChange={onChange}
                    defaultValue={defaultValue}
                    getOptionValue={(option: DomainSelectOption) => option.value}
                    getOptionLabel={(option: DomainSelectOption) => option.label}
                    options={options}
                    // filterOption={filterOption && filterOption}
                    // filterOption={true}
                    // value={[{ value: '2', label: 'Feminino' }]}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    styles={{
                        ...SelectStyles,
                        control: (styles) => ({
                            ...styles,
                            borderRadius:'none', borderColor: '#949494'
                        })
                    }}
                    isSearchable={searchable}
                    isDisabled={disabled}
                    isLoading={isLoading}
                    // readOnly={readOnly}
                    className={className}
                    // aria-label={label || `Campo de combinação`}
                    ariaLiveMessages={{
                        onFocus: (props: AriaOnFocusProps<DomainSelectOption, GroupBase<DomainSelectOption>>) => {
                            return `Focado em "${props.focused?.alt || props.focused?.label}"${
                                props.isDisabled ? ', desabilitada.' : ''
                            } de ${props.options.length} disponíveis.`;
                        },                        
                        guidance: (props: AriaGuidanceProps) => {
                            return props.context;
                        },
                        onChange: (props: AriaOnChangeProps<DomainSelectOption, false>) => {
                            return `Opção "${props.value?.alt || props.value?.label}" selecionada`
                        },
                    }}
                    aria-errormessage={errorText}
                    aria-invalid={errorText && errorText!=='' ? true : false}
                />
            </div>            
            { (!fit || (fit && errorText)) && <small role="alert">{errorText}</small> }
        </Container>
    );
}

export default InputSelect;