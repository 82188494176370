import styled from "styled-components";

export const Container = styled.div`

    .rdt_TableHeadRow, .rdt_TableRow {
        background-color: ${props => props.theme.colors.bgPrimary};
        color: ${props => props.theme.colors.labelText};
        border-bottom-color: ${props => props.theme.colors.tableDividerLine};
        min-height: 51px;
    }

    .rdt_TableHeadRow:first-of-type {
        border-top-style: solid;
        border-top-width: 1px;
        border-top-color: ${props => props.theme.colors.tableDividerLine};
    }

    .rdt_TableRow:last-of-type {
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: ${props => props.theme.colors.tableDividerLine};
    }

    .rdt_TableCol:first-of-type,
    .rdt_TableCell:first-of-type {
        padding-left: 0
    }

    .rdt_TableCol:last-of-type,
    .rdt_TableCell:last-of-type {
        padding-right: 0
    }
`;