import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import dialogConfirm from '../../components/ConfirmAlert';
import { AuthUser } from '../models/Auth';
import { UpdatePasswordData, User } from '../models/Users';
import { userService } from '../services/domains/userService';
// import { useAccessibility } from './AccessibilityContext';
  
  interface UserContextProps {
    users: any[];
    userEditing: User|undefined;
    authenticatedUser: User|undefined;
    hasCompleteRegistration: boolean;
    isLoading: boolean;
    fetchUsers: () => void;
    getUserById: (id: number) => void;
    editUser: (data: User, redirectRouteCallback?: string) => any;
    removeUserById: (id: number) => void;
    defineAuthenticatedUser: () => void;
    checkCompleteRegistration: () => void;
    clearUserEditing: () => void;
    forgotPassword: (email: string) => void;
    updatePassword: (data: UpdatePasswordData, callback?: () => void) => void;
  }

  interface Props {
    children?: React.ReactNode | React.ReactNode[];
  };

  export const UserContext = createContext<UserContextProps>({} as UserContextProps);
  
  export const UserProvider: React.FC<Props> = ({ children }) => {

    const [users, setUsers] = useState<any>();
    // const [user, setUser] = useState<User|undefined>({
    //   id: 0,
    //   username: '',
    //   email: '',
    //   password: '',
    // });
    const [userEditing, setUserEditing] = useState<User|undefined>(undefined);
    const [hasCompleteRegistration, setHasCompleteRegistration] = useState<boolean>(false);
    const [authenticatedUser, setAuthenticatedUser] = useState<User|undefined>(() => {
      const temp = localStorage.getItem('@constructore-front:userAuthenticated');
      if(!temp) return undefined;
      return JSON.parse(temp);
    });
    const [isLoading, setIsLoading] = useState(false);
    // const { theme } = useAccessibility();
    const navigate = useNavigate();

    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        setUserEditing(undefined);
        const response: User[] = await userService.list();
        setUsers(response);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    const getUserById = async (id: number) => {
      setIsLoading(true);
      try {
        setUserEditing(undefined);
        const resp: User = await userService.listById(id);
        // setUserEditing(resp);
        setUserEditing({
          ...resp,
          /*
            * Mapeamento dos valores recebidos do backend.
            * Sem isso a propriedade touched não funciona corretamente,
            * acarretando falha na exibição da mensagem de erro da validação.
          */
          nome: resp?.nome || '',
          dataNascimento: resp?.dataNascimento || '',
          sexo: resp?.sexo || '',
          cpf: resp?.cpf || '',
          email: resp?.email || '',
          celularVisivel: resp?.celularVisivel || '1',
          celular: resp?.celular || '',
          estado: resp?.estado || '',
          cidade: resp?.cidade || '',
          vinculadoUFRJ: resp?.vinculadoUFRJ || '0',
          // instituicao: resp?.instituicao || '',
          // fotoPerfilServer: resp?.fotoPerfilServer || undefined,
          roles: resp?.roles || [],
          // username: resp?.username || '',
          // password: resp?.password,
          dre: resp?.dre || '',
          siape: resp?.siape || '',
          // centro: resp?.centro || '',
          tipoVinculo: resp?.tipoVinculo || '0',
        } as User)

        // atualiza flag cadastroCompleto do usuário gravado no localStorage caso haja alteração no flag
        const userAthenticated = getUserAuthenticated();
        const hasChangeInCompletedRegister = 
          userAthenticated?.cadastroCompleto !== resp.cadastroCompleto;
        if(hasChangeInCompletedRegister && userAthenticated?.id === resp.id) {
          localStorage.setItem('@constructore-front:userAuthenticated', JSON.stringify({
            ...userAthenticated,
            cadastroCompleto: resp?.cadastroCompleto
          }));
          checkCompleteRegistration();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }  

    const editUser = async (data: User, redirectRouteCallback?: string) => {
      // Com todos os campos obrigatórios da tela de Cadastro Incompleto preenchidos, 
      // o sistema deve direcionar automaticamente para a tela inicial após o usuário salvar.

      try {
        setIsLoading(true);
        const response: User = await userService.updateUser(data);

        // atualiza flag cadastroCompleto do usuário gravado no localStorage caso haja alteração no flag
        const userAthenticated = getUserAuthenticated();
        const hasChangeInCompletedRegister = 
          userAthenticated?.cadastroCompleto !== response.cadastroCompleto;
        if(hasChangeInCompletedRegister && userAthenticated?.id === response.id) {
          localStorage.setItem('@constructore-front:userAuthenticated', JSON.stringify(response));
          // localStorage.setItem('@constructore-front:userAuthenticated', JSON.stringify({
          //   ...userAthenticated,
          //   cadastroCompleto: resp?.cadastroCompleto,
          // }));
        }

        toast.success('Usuário atualizado com sucesso.', {
          onClose: () => {
            if (redirectRouteCallback) {
              if(userAthenticated?.id === response.id) {
                if(response.cadastroCompleto)
                  navigate(redirectRouteCallback/*, { replace: true }*/);
                else
                  checkCompleteRegistration();
              } else {
                navigate(redirectRouteCallback/*, { replace: true }*/);
              }
            }
            setIsLoading(false);
          },
        });

        setUserEditing(response);
        
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    }

    const removeUserById = async (id: number) => {
      dialogConfirm({
        text: "Você confirma que deseja apagar este item?",
        title: "Apagar item",
        // bgColor: theme.colors.bgPrimary,
        onClickConfirm: async () => {
          try {
            await userService.deleteUserById(id);
            toast.success('Usuário removido com sucesso.');
            fetchUsers();
          } catch (error) {
            console.log(error);
          }
        }
      });
    }

    const getUserAuthenticated = (): AuthUser | undefined => {
      const userAthenticated = localStorage.getItem('@constructore-front:userAuthenticated');
      if(!userAthenticated) return undefined;
      return JSON.parse(userAthenticated);
    }

    const getAuthenticatedUserById = async (id: number) => {
      try {
        const resp: any = await userService.listById(id);
        setAuthenticatedUser(resp);
      } catch (error) {
        console.log(error);
      }      
    }

    const defineAuthenticatedUser = () => {
      // define o usuário logado via requisição (User e AuthUser são diferentes)
      const userAuth = getUserAuthenticated();
      if(userAuth) {
        getAuthenticatedUserById(userAuth?.id);
      }
    }

    const clearUserEditing = () => {
      setUserEditing(undefined);
    }

    const checkCompleteRegistration = useCallback(() => {
      // Verifica se o cadastro do usuário logado está completo
      // e em caso negativo, obriga a conclusão do cadastro.
      const loggedUser = getUserAuthenticated();
      const isComplete: boolean = !!loggedUser?.cadastroCompleto;
      setHasCompleteRegistration(isComplete);
      if(loggedUser && !isComplete) {
        navigate(`/mydata`);
      }
      if(!loggedUser) {
        navigate('/');
      }
    },[]);

    const forgotPassword = async (email: string) => {
      try {
        await userService.forgotPasswordByEmail(email);
        navigate('/');
        toast.success('Solicitação de recuperação de senha enviada com sucesso. Por favor, verifique seu e-mail.');
      } catch (error: any) {
        console.log(error);
        const msg = error?.response?.status === 404 ? 'Usuário não encontrado.' : 'Erro inesperado.';
        toast.error(msg);
      }
    }

    const updatePassword = async (data: UpdatePasswordData, callback?: () => void) => {
      try {
        await userService.updatePassword(data);
        // navigate('/');
        toast.success('Senha atualizada com sucesso.');
        callback && callback();
      } catch (err:any) {
        console.log(err);
        const msg = err?.response?.status === 417 ? 'Senha Atual não confere.' : 'Senhas não conferem.';
        // err?.response?.data?.message
        toast.error(msg);
      }
    }    

    useEffect(() => {
      checkCompleteRegistration();
    },[checkCompleteRegistration]);

    return (
      <UserContext.Provider
        value={{
          users,
          userEditing,
          authenticatedUser,
          hasCompleteRegistration,
          isLoading,
          fetchUsers,
          getUserById,
          editUser,
          removeUserById,
          defineAuthenticatedUser,
          checkCompleteRegistration,
          clearUserEditing,
          forgotPassword,
          updatePassword,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  };
  
  export const useUser = () => useContext(UserContext);
  