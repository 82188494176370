import {createContext, useContext, useState} from 'react';
import { Menu } from '../models/Menu';
import { useUser } from './UserContext';

interface Context {
    open: boolean;
    menu: Menu;
    trackedOption: number;
    openOrCloseMenu: () => void;
    expanderManagementOption: () => void;
    trackMenuOption: (option: number) => void;
}

interface Props {
    children?: React.ReactNode | React.ReactNode[];
};

export const MenuContext = createContext<Context>({} as Context);

export const MenuProvider: React.FC<Props> = ({  children }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [menu, setMenu] = useState<Menu>({
        isOpen: false,
        options: {
            isOpenManagement: false
        }
    });
    const { hasCompleteRegistration } = useUser();
    const [trackedOption, setTrackedOption] = useState<number>(0);

    const openOrCloseMenu = () => {
        setOpen(prevState => !prevState);
        setMenu((prevState: Menu) => ({
            ...prevState,
            isOpen: !prevState.isOpen
        }));
    }

    const expanderManagementOption = () => {
        // abre o menu caso expanda o submenu com o menu fechado
        if(!menu.isOpen) {
            setMenu((prevState: Menu) => ({
                ...prevState, isOpen: true
            }));
        }
        // expande o submenu clicado
        if(hasCompleteRegistration) {
            setMenu((prevState: Menu) => ({
                ...prevState, options: {
                    isOpenManagement: !prevState.options.isOpenManagement
                }
            }));
        }

        if(!hasCompleteRegistration) {
            setMenu((prevState: Menu) => ({
                ...prevState, options: {
                    isOpenManagement: false
                }
            }));
        }
    }

    const trackMenuOption = (option: number): void => {
        setTrackedOption(option);
    };

    return (
        <MenuContext.Provider
            value={{
                open: open,
                menu: menu,
                trackedOption: trackedOption,
                openOrCloseMenu,
                expanderManagementOption,
                trackMenuOption,
            }}
            >
            {children}
        </MenuContext.Provider>
    );
};

export const useMenu = () => useContext(MenuContext);