import styled from "styled-components";

export const Container = styled.div`
    section {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        form {
            width: -webkit-fill-available;
            max-width: 400px;

            > div:first-child {
                gap: 16px;
                display: flex;
                flex-direction: column;                
            }

            i {
                outline: solid var(--gray5) 1px;
                width: 100%;
                display: flex;
                margin: 16px 0;                
            }
        }
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: end;
    column-gap: 32px;
`;