import { Link } from "react-router-dom";
import {
    FaTimes as IconClose,
    FaBars as IconOpen,
    FaCalendarAlt as IconSchedule,
    FaChalkboardTeacher,
    FaPencilRuler,
    FaServer,
    FaUserAlt,
    FaUserCog
} from "react-icons/fa";
// import brand from "../../assets/img/logo_c.jpg";
// import brandHighContrast from "../../assets/img/logo_c_branco.png";
import logoFAPERJ from '../../assets/img/logo_faperj.jpg';
import logoFAPERJHighContrast from '../../assets/img/logo_faperj_branco.png';
import { useMenu } from "../../shared/contexts/MenuContext";
import { useUser } from "../../shared/contexts/UserContext";
import { useAuth } from "../../shared/contexts/Auth";
import { useAccessibility } from "../../shared/contexts/AccessibilityContext";
import {
    Container,
    Options,
    Puller,
    LinkBrand,
    LinkSchedule,
    Buttons,
    OptionsWrap
} from "./styles";

const MenuMobile: React.FC = () => {
    const {
        menu,
        trackedOption,
        openOrCloseMenu,
        expanderManagementOption
    } = useMenu();
    const { hasCompleteRegistration } = useUser();
    const { signOut } = useAuth();
    const { theme } = useAccessibility();

    const handleClick = () => {
        openOrCloseMenu();
    };

    const handleLogoffClick = () => {
        openOrCloseMenu();
        signOut();
    };

    const getLogoImage = (normal: string, highContrast: string) => 
        theme.id !== 2 ? normal : highContrast;

    return (
        <Container className="aux-public-menu">
            <Buttons>
                <LinkBrand>
                    <img src={getLogoImage(logoFAPERJ, logoFAPERJHighContrast)} alt="Logotipo FAPERJ" />
                </LinkBrand>
                <Puller onClick={e => handleClick()}>
                    {menu.isOpen ? <IconClose /> : <IconOpen />}
                </Puller>
            </Buttons>            
            <OptionsWrap className={menu.isOpen ? 'open' : 'closed'}>
                <Options>
                    <li>
                        <Link to='#' title="Manual">
                            <span>Manual</span>
                        </Link>
                    </li>
                    <li>
                        <Link to='#' title="Imprimir">
                            <span>Imprimir</span>
                        </Link>
                    </li>
                    <li>
                        <Link to='#' title="Fale conosco">
                            <span>Fale conosco</span>
                        </Link>
                    </li>
                    <li>
                        <Link to='#' title="Informações">
                            <span>Informações</span>
                        </Link>
                    </li>
                </Options>
            </OptionsWrap>
        </Container>
    )
};

export default MenuMobile;