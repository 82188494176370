import * as yup from 'yup';

import { setLocale } from 'yup';
import { Alternative } from '../../../../shared/models/course/Question';
setLocale({
  mixed: {
    default: 'Valor inválido',
    required: 'Campo obrigatório'
  },
});

const schema = yup.object().shape({
    enunciado: yup
        .string()
        .min(3, 'Mínimo de 3 caracteres')
        .max(50, 'Máximo de 50 caracteres')
        .required()
        .label('Nome'),
    valorTipoMultiplaEscolhaUnica: yup.array<Alternative[]>(
        yup.object().shape({
          // id: yup.number(),
          texto: yup.string().required(),
          // alternativaCorreta: yup.boolean(),
        })        
      )
      .nullable(),
    valorTipoMultiplaEscolhaMultipla: yup.array<Alternative[]>(
        yup.object().shape({
          texto: yup.string().required(),
        })        
      )
      .nullable(),      
});

export default schema;
