import styled from "styled-components";

export const Container = styled.span`
    display: inline-flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: ${props => props.theme.colors.white};
    border-radius: 50%;
    background-color: ${props => props.theme.colors.primary};
    user-select: none;

    @media (max-width: 768px) {
        display: none;
    }    
`;