import styled from "styled-components";

export const Container = styled.div`
    section {
        display: flex;
        flex-direction: column;
        
        form {
            .row-1 {
                display: flex;
                flex-direction: row;
                column-gap: 32px;
                margin-bottom: 16px;

                @media (max-width: 761px) {
                    flex-direction: column;
                    row-gap: 32px;
                }

                > div:first-child {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 8px;

                    > div:nth-child(2),
                    > div:nth-child(3),
                    > div:nth-child(4) {
                        display: flex;
                        flex-direction: row;
                        column-gap: 32px;
                        display: grid;
                        grid-template-columns: 1fr 1fr;

                        // @media (max-width: 576px) {
                        @media (max-width: 1021px) { // em função do campo "Data de Nascimento"
                            grid-template-columns: 1fr;
                        }
                    }
                }

                > div:last-child {
                    @media (max-width: 761px) {
                        align-self: center;
                    }
                }                
            }

            .row-2 {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 32px;
                row-gap: 8px;

                @media (max-width: 768px) {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: end;
    column-gap: 32px;
`;

export const InputSelectStyle = styled.div`
    display: flex;
    flex-direction: column;
    /* min-width: 150px; */

    label {
        align-self: start;
        padding: 0 4px 2px 0;
    }

    > div {
        display: flex;
        flex-direction: row;
    }

    .react-select-container {
        align-content: 'flex-start';
        /* width: 153px; */
    }
`;