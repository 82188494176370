import styled from "styled-components";

export const Container = styled.div`
    &.active {
        display: block;
    }

    section {
        display: flex;
        flex-direction: column;
        margin-top: 40px;

        &:last-of-type {
            margin-bottom: 40px;
        }
        
        form {
            .row-1 {
                display: grid;
                flex-direction: row;
                column-gap: 32px;
                margin-bottom: 16px;
                justify-content: flex-start;
            }

            h4 {
                color: ${(props) => props.theme.colors.labelText};
            }
        }
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    column-gap: 32px;

    > span {
        display: flex;
        column-gap: inherit;
    }
`;

export const GenericTableStyled = styled.table`
    width: 100%;

    thead {
        tr {
            th {
                height: 35px;
                align-items: center;
                text-align: start;
                padding: 8px;
                color: ${(props) => props.theme.colors.titleText};
                font-weight: bold;

                > &:nth-child(2) {
                    display: flex;
                }
    
                > &:nth-child(3) {
                    text-align: start;
                }
            }

        }
    }

    tbody {
        tr {
            td {
                height: 35px;
                align-items: center;
                text-align: start;
                padding: 8px;
                color: ${(props) => props.theme.colors.titleText};

                > &:nth-child(2) {
                    display: flex;
                }
    
    
                > &:nth-child(3) {
                    text-align: start;
                }            
            }

        }
    }  
`;

export const Text = styled.pre`
    text-align: left;
    white-space: pre-line;
    margin-bottom: 32px;
    line-height: 1.2;
`;