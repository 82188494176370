import React, { useState, useEffect } from "react";
import {
    Container,
    Text,
    Content,
    TableRow,
    TableHeader,
    TableCell,
    Pagination,
    Input,
    SearchContainer,
    PopupWrapper,
    PopupContainer,
    AuthorDate
} from "./styles";
import Button from "../../../components/Button";
import Breadcrumb from "../../../components/Breadcrumb";
import { useNavigate } from "react-router-dom";

interface Comment {
    id: number;
    content: string;
}

interface Post {
    id: number;
    title: string;
    content: string;
    author: string;
    date: string;
    respostas: number;
    visualizações: number;
    curtidas: number;
    likes: number;
    comments: Comment[];
}

const ForumPage: React.FC = () => {
    const [posts, setPosts] = useState<Post[]>([]);
    const [hovered, setHovered] = useState<number | null>(null);

    useEffect(() => {
        fetch("/mock.json")
            .then((response) => response.json())
            .then((data) => setPosts(data.posts));
    }, []);

    const navigate = useNavigate();
    const handleNewPost = () => {
        navigate("/communication/forum/post");
    };

    const handleMouseEnter = (postId: number) => {
        setHovered(postId);
    };

    const handleMouseLeave = () => {
        setHovered(null);
    };

    return (
        <Container>
            <Breadcrumb>
                <span>Comunicação</span>
                <span>Fórum</span>
            </Breadcrumb>

            <Text>
                Seja bem vindo ao Fórum, este espaço é dedicado à troca de
                ideias, aprendizado e conexão.
            </Text>
            <SearchContainer>
                <Button btnTheme="primary" onClick={handleNewPost}>
                    Nova postagem
                </Button>
                <Input type="text" placeholder="Buscar tópico" />
            </SearchContainer>

            <Content>
                <TableHeader>
                    <TableCell>Tópicos</TableCell>
                    <TableCell>Respostas</TableCell>
                    <TableCell>Visualizações</TableCell>
                    <TableCell>Curtidas</TableCell>
                </TableHeader>
                {posts.map((post) => (
                    <TableRow key={post.id}>
                        <TableCell>
                            <div>{post.title}</div>
                            <AuthorDate>
                                <span>{post.author}</span>
                                <span>
                                    {new Date(post.date).toLocaleString()}
                                </span>
                            </AuthorDate>
                        </TableCell>
                        <TableCell>{post.respostas}</TableCell>
                        <TableCell>{post.visualizações}</TableCell>
                        <TableCell>
                            <div>{post.curtidas}</div>
                            <br />
                            <a
                                href="#"
                                onMouseEnter={() => handleMouseEnter(post.id)}
                                onMouseLeave={handleMouseLeave}
                            >
                                Associações
                            </a>
                            {/* TODO: Seria bom verificar o hover num
                          dispositivo touch screen ou criar alguma
                          alternativa */}

                            {hovered === post.id && (
                                <PopupWrapper
                                    onMouseEnter={() =>
                                        handleMouseEnter(post.id)
                                    }
                                    onMouseLeave={handleMouseLeave}
                                >
                                    {hovered === post.id && (
                                        <PopupContainer>
                                            <p>Módulo</p>
                                            <p className="indented">
                                                <a href="#">Módulo 1</a>
                                            </p>
                                            <p>Atividade</p>
                                            <p className="indented">
                                                <a href="#">Atividade 1</a>
                                            </p>
                                            <p>Links</p>
                                            <p className="indented">
                                                <a href="#">Link 1</a>
                                            </p>
                                        </PopupContainer>
                                    )}
                                </PopupWrapper>
                            )}
                        </TableCell>
                    </TableRow>
                ))}
                <Pagination>
                    <span>Página</span>
                    <a href="#">&lt;</a>
                    <a href="#">1</a>
                    <span>de</span>
                    <span>07</span>
                    <a href="#">&gt;</a>
                </Pagination>
            </Content>
        </Container>
    );
};

export default ForumPage;
