import styled from "styled-components";

export const Container = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    width: 100%;

    svg {
        width: 30px;
        height: 30px;
        color: ${props => props.theme.colors.tertiary};
        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear; 

        @keyframes spin {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }        
    }
`;