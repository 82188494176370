import React, { useEffect } from 'react';
// import { BrowserRouter } from 'react-router-dom';

import App from './app.routes';
import Auth from './auth.routes';

import { useAuth } from '../shared/contexts/Auth';
import { AUTH_TOKEN_KEY } from '../constants';

const Routes: React.FC = () => {
    const { logged, signOut } = useAuth();

    useEffect(() => {
        const token = localStorage.getItem(AUTH_TOKEN_KEY);
        if(!token && logged) signOut();
    }, [logged, signOut]);
    
    // return (
    //     <BrowserRouter>
    //         { logged ? <App /> : <Auth /> }
    //     </BrowserRouter>
    // );

    return (
        <>
            { logged ? <App /> : <Auth /> }
        </>
    );
}

export default Routes;