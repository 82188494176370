import styled from 'styled-components'

export const Container = styled.div`
    section {
        display: flex;
        gap: 24px;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        ul {
            list-style: initial;
            text-align: left;
            line-height: 24px;
            padding-left: 24px;
            color: ${props => props.theme.colors.labelText};
    
            ul {
                list-style: circle;
                text-indent: 20px;
            }
        }
    }
`;