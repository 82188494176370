import styled from "styled-components";

export const Container = styled.h1`
    font-family: 'Verdana', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    margin: 40px 0;
    display: flex;
    flex: none;
    order: 0;
    flex-grow: 0;
    text-align: left;

    @media (max-width: 768px) {
        justify-content: center;
        width: 100%;
        text-align: center;
    }
`;
