import axios from 'axios';
import { toast } from 'react-toastify';
import { AUTH_TOKEN_KEY } from '../../constants';

var baseHostname: any = process.env.REACT_APP_BASE_HOSTNAME;
var backendPort: any = process.env.REACT_APP_PORT;
switch (window.location.hostname) {
  case process.env.REACT_APP_BASE_HOSTNAME_PUBLIC:
    baseHostname = process.env.REACT_APP_BASE_HOSTNAME_PUBLIC;
    backendPort = process.env.REACT_APP_PORT_PUBLIC;
    break;
  case process.env.REACT_APP_BASE_HOSTNAME_DOMAIN:
    baseHostname = process.env.REACT_APP_BASE_HOSTNAME_DOMAIN;
    break;
  default:
    baseHostname = process.env.REACT_APP_BASE_HOSTNAME;
    break;
}

const api = axios.create({
    baseURL: process.env.REACT_APP_PROTOCOL + baseHostname + ":" + backendPort
});

api.interceptors.request.use(
    request => {
      const token = localStorage.getItem(AUTH_TOKEN_KEY);
      
      if (token) {
        request.headers = {
          ...request.headers,
          Authorization: `Bearer ${token}`,
        };
      }
  
    //   if (request.params) request.params = clearObject(request.params);
  
    //   if (request.data) request.data = clearObject(request.data);
  
      return request;
    }
);

api.interceptors.response.use(
    (response) => {
        if(response?.status !== 200) {
            toast.error(response?.statusText || 'Erro inesperado!');
        }
        
        return response;
    },
    (error) => {
        if(error?.response?.status === 401) {
          toast.error('Por favor, verifique Login e Senha.');
          localStorage.removeItem(AUTH_TOKEN_KEY);
        }
        if(!error?.response || error?.response?.status !== 401) {
          const msg = error?.message || 'Erro inesperado.';
          toast.error(msg);
        }
        
        // useNavigate()("/");
        
        return Promise.reject({ ...error })
    }
);

export default api;