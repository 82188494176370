import styled from "styled-components";

export const Container = styled.section`

    form {
        /* background-color: #ff00001c; */

        section {
            display: flex;
            flex-direction: column;
            gap: 16px;

            table {
                width: 100%;

                thead {
                    > tr {
                        > td {
                            padding-bottom: 2px;
                            
                            &:first-child {
                                display: flex;
                                text-align: start;
                            }
    
                            &:last-child {
                                svg {
                                    color: #949494;
                                }
                            }
                        }
                    }
                }
            }
        }

        /* .question-type {
            width: 30%;
            min-width: 360px;
        } */
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: left;
    column-gap: 32px;
`;

export const QuestionType = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
    margin-bottom: 16px;

    > div {
        gap: 16px;
        display: flex;

        label {
            align-self: center;
        }
    }
`;