import Select, {
    ActionMeta,
    AriaGuidanceProps,
    // AriaOnChangeProps,
    AriaOnFocusProps,
    GroupBase,
    MultiValue,
} from "react-select";
import { SelectStyles } from "../../assets/styles/SelectStyles";
import { CourseCollaborator } from "../../shared/models/domains/Domains";
import defaultImg from "../../assets/img/perfil-vazio.png";
import { Container, CustomOption } from "./styles";

interface Props {
    // formik?: any;
    options: CourseCollaborator[];
    defaultValue?: CourseCollaborator;
    value: CourseCollaborator[] | undefined;
    label?: string;
    id?: string;
    name: string;
    placeholder?: string;
    disabled?: boolean;
    searchable?: boolean;
    readOnly?: boolean;
    errorText?: string;
    fit?: boolean;
    isLoading?: boolean;
    className?: string;
    onBlur?(e: React.FocusEvent<unknown>): void;
    onChange: (newValue: MultiValue<CourseCollaborator>, actionMeta: ActionMeta<CourseCollaborator>) => void;
}

const InputCollaboratorsNoFormik: React.FC<Props> = ({
    // formik,
    options,
    defaultValue = null,
    value = undefined,
    id,
    name,
    label,
    placeholder = 'Selecione',
    disabled = false,
    searchable = false,
    errorText,
    fit = false,
    isLoading = false,
    className = '',
    onBlur,
    onChange,
}) => {

    // const handleValue = (): any => {
    //     if(!(
    //         options &&
    //         formik?.values &&
    //         name &&
    //         name !== "" &&
    //         formik?.values[name] &&
    //         formik?.values[name]?.length > 0
    //     )) return [];

    //     try {
    //         return options.filter(
    //             (x: CourseCollaborator) => {
    //                 return formik.values[`${name}`].find((userId: number) => userId === x.id)
    //             }
    //         );
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const getName = (name: string) => {
        return name && name.split(' ').length > 1
            ? `${name.split(' ')[0]} ${name.split(' ')[name.split(' ').length-1]}`
            : name.split(' ').length === 1 ? name : '';
    }

    return (
        <Container>
            { label && (
                <label id={`aria-label-${id || name}`} htmlFor={id || name}>
                    {label}
                </label>
            )}
            <div>
                <Select
                    inputId={id || name}
                    name={name}
                    aria-labelledby={`aria-label-${id || name}`}
                    isMulti
                    // value={handleValue()}
                    value={value}
                    onChange={onChange}
                    // onChange={(option: any) => {
                    //     formik.setFieldValue(name, [...option.map((x:any) => x.id)])
                    // }}
                    defaultValue={defaultValue}
                    getOptionValue={(option: CourseCollaborator) => option.id.toString()}
                    getOptionLabel={(option: CourseCollaborator) => option.nome}
                    options={options}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    styles={{
                        ...SelectStyles,
                        control: (styles) => ({
                            ...styles,
                            borderRadius:'none', borderColor: '#949494'
                        }),
                    }}
                    formatOptionLabel={(item: CourseCollaborator) => {
                        const img = item?.foto?.data ? `data:image/png;base64,${item.foto.data}` : defaultImg;
                        const name = getName(item.nome);
                        return (
                            <CustomOption title={item?.nome || ''} className="custom-option">
                                <span><img src={img} alt={name}/></span>
                                <span>{name}</span>
                            </CustomOption>
                        )
                    }}
                    noOptionsMessage={() => "Nenhuma opção"}
                    isSearchable={searchable}
                    isDisabled={disabled}
                    isLoading={isLoading}
                    // readOnly={readOnly}
                    className={className}
                    ariaLiveMessages={{
                        onFocus: (props: AriaOnFocusProps<CourseCollaborator, GroupBase<CourseCollaborator>>) => {
                            return `Focado em "${props.focused?.nome}"${
                                props.isDisabled ? ', desabilitada.' : ''
                            } de ${props.options.length} disponíveis.`;
                        },
                        guidance: (props: AriaGuidanceProps) => {
                            return props.context;
                        },
                        // onChange: (props: AriaOnChangeProps<CourseCollaborator, false>) => {
                        //     return `Opção "${props.value?.nome}" selecionada`
                        // },                        
                    }}
                    aria-errormessage={errorText}
                    aria-invalid={errorText && errorText!=='' ? true : false}
                />
            </div>
            { (!fit || (fit && errorText)) && <small role="alert">{errorText}</small> }
        </Container>
    );
}

export default InputCollaboratorsNoFormik;