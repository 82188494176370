import styled from "styled-components";

export const Container = styled.section`
    
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: left;
    column-gap: 32px;
`;

export const QuestionType = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
    margin-bottom: 16px;    
`;