import { Container } from "./styles";

const text = `Campos marcados com * são obrigatórios.`;

const Info: React.FC = () => {

    return (
    <Container>
        {text}
    </Container>
    )
}

export default Info;