import styled from 'styled-components'

export const Container = styled.div`
    section {
        p {
            margin: 0 0 16px;
        }

        > p:first-child {
            font-weight: bold;
        }
    }
`;