import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import logoUFRJ from '../../assets/img/logo_ufrj.png';
import logoUFRJHighContrast from '../../assets/img/logo_ufrj_branco.png';
import logoLTC from '../../assets/img/logo_ltc.png';
import logoLTCHighContrast from '../../assets/img/logo_ltc_branco.png';
import logoMec from '../../assets/img/logo_mec.png';
import logoMecHighContrast from '../../assets/img/logo_mec_branco.png';
import logoNUTES from '../../assets/img/logo_nutes.png';
import logoNUTESHighContrast from '../../assets/img/logo_nutes_branco.png';
import logoFAPERJ from '../../assets/img/logo_faperj.jpg';
import logoFAPERJHighContrast from '../../assets/img/logo_faperj_branco.png';

import AccessibilityToolbar from '../../components/AccessibilityToolbar';
import Input from '../../components/Input';
import InputMasked from '../../components/InputMasked';
import PublicMenu from '../../components/PublicMenu';
import Title from '../../components/Title';
import Required from '../../components/Required';
import Spinner from '../../components/Spinner';
import Button from '../../components/Button';
import { RegisterData } from '../../shared/models/Auth';
import { useAuth } from '../../shared/contexts/Auth';
import { useLoader } from '../../shared/contexts/LoadingContext';
import { useAccessibility } from '../../shared/contexts/AccessibilityContext';
import schema from './schema';
import {
    Container,
    Form,
    TopBrand,
    Buttons,
    Content,
    FooterBrand,
} from './styles';

const Register: React.FC = () => {
    const { registerNewUser } = useAuth();
    const { loading } = useLoader();
    const { theme } = useAccessibility();
    const navigate = useNavigate();

    const handleSubmit = (data: RegisterData) => {
        data.username = data.email.split('@')[0];
        registerNewUser(data);
    }

    const formik = useFormik({
        onSubmit: handleSubmit,
        validationSchema: schema,
        initialValues: {
            email: '',
            cpf: '',
            password: '',
            perfil: [],
            username: ''
        },
    });

    // function handleShowError(field: string) {
    //     return formik.touched?[field] && formik?.errors[field];
    // };

    const checkDisabledSubmit = () => {
        return !(formik.dirty || formik.isValid) || 
                !formik.dirty ||
                loading;
    }

    const getErrorMessage = (fieldName: string) => {
        return (formik?.getFieldMeta(fieldName)?.touched &&
                formik?.getFieldMeta(fieldName)?.error)
            ?
            formik.getFieldMeta(fieldName).error
            :
            ''
    }

    const getLogoImage = (normal: string, highContrast: string) => {
        return theme.id !== 2 ? normal : highContrast;
    }    

    return (
        <Container>
            <AccessibilityToolbar />
            <Content>
                <TopBrand>
                    <span>
                        <img
                            src={getLogoImage(logoMec, logoMecHighContrast)}
                            alt="Ministério da Educação - MEC"
                            className="logo-mec"
                        />
                    </span>
                    <span>
                        <span>
                            <img
                                src={getLogoImage(logoUFRJ, logoUFRJHighContrast)}
                                alt="UFRJ"
                                className="logo-ufrj"
                            />
                        </span>
                        <span>
                            <img
                                src={getLogoImage(logoNUTES,logoNUTESHighContrast)}
                                alt="NUTES"
                                className="logo-nutes"
                            />
                        </span>
                        <span>
                            <img
                                src={getLogoImage(logoLTC,logoLTCHighContrast)}
                                alt="Laboratorio de Tecnologias Cognitivas"
                                className="logo-ltc"
                            />
                        </span>
                    </span>
                </TopBrand>

                <Title>Cadastro de Novo Usuário</Title>

                <Required />

                <Form onSubmit={formik.handleSubmit}>
                    <div>
                        <Input
                            label="E-mail *"
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Digite seu e-mail"
                            value={formik?.values?.email}
                            onChange={formik?.handleChange}
                            autoComplete
                            autoFocus
                            readOnly={loading}
                            errorText={getErrorMessage('email')}
                        />
                        {/* <Input
                            label="CPF *"
                            type="text"
                            id="cpf"
                            name="cpf"
                            placeholder="Digite seu CPF"
                            value={formik?.values?.cpf}
                            onChange={formik?.handleChange}
                            autoComplete
                            readOnly={loading}
                            errorText={getErrorMessage('cpf')}
                        /> */}
                        <InputMasked
                            maskType='cpf'
                            label="CPF *"
                            id="cpf"
                            name="cpf"
                            placeholder="Digite seu CPF"
                            value={formik?.values?.cpf}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                formik?.setFieldValue('cpf', e.target.value)
                            }
                            autoComplete
                            readOnly={loading}
                            errorText={getErrorMessage('cpf')}
                        />
                        <Input
                            label="Senha *"
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Digite sua senha"
                            value={formik?.values?.password}
                            readOnly={loading}
                            onChange={formik?.handleChange}
                            errorText={getErrorMessage('password')}
                        />
                        <Buttons>
                            <Button
                                onClick={() => navigate('/')}
                                btnTheme="primary"
                            >
                                Cancelar
                            </Button>                    
                            <Button
                                type="submit"
                                disabled={checkDisabledSubmit()}
                                btnTheme="primary"
                            >
                                {loading ? <Spinner /> : 'Salvar'}
                            </Button>
                        </Buttons>
                    </div>
                </Form>
            </Content>
            <FooterBrand>
                <img src={getLogoImage(logoFAPERJ, logoFAPERJHighContrast)} alt="Logotipo FAPERJ" />
            </FooterBrand>            
            <PublicMenu />
        </Container>
    );
}

export default Register;