import { Link } from "react-router-dom";

import { FaPencilAlt } from "react-icons/fa";

import { Container } from "./styles";

interface Props {
    userId: number;
    children?: React.ReactNode | React.ReactNode[];
}

const Actions: React.FC<Props> = ({children, userId}) => {
    
    return (
        <Container>
            <Link
                to={`/admin/edit/:${userId}`}
                aria-label="Ação Editar Usuário"
            >
                <FaPencilAlt />
            </Link>
            {children}
        </Container>
    )
}
export default Actions;