/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    id: 2,
    title: 'highContrast',
    colors: {
        primary: '#fff',
        secondary: '#252A48',
        tertiary: '#fff',

        bgPrimary: '#000',
        bgSecondary: '#252A48',
        bgTopTabs: '#000',

        labelText: '#fff',
        placeholderText: '#000',
        placeholderSearchText: '#fff',
        titleText: '#fff',
        linkText: '#fff333',
        menuElements: '#fff',
        tableDividerLine: '#fff',
        mobilePullerSecondaryMenuIcon: '#000',
        mobilePullerSecondaryMenuLabel: '#000',
        bgMenuElements: '#595959',
        bgAccessibilityToolbar: '#fff',

        white: '#000',
        black: '#FFF',
        gray: '#BFBFBF',

        success: '#4E41F0',
        info: '#F7931B',
        warning: '#E44C4E',
    },
};