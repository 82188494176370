import { Link, useLocation } from "react-router-dom";
import {
    FaTimes as IconClose,
    FaBars as IconOpen,
    FaSearch as IconSearch,
    FaCalendarAlt as IconSchedule,
    FaChalkboardTeacher,
    FaPencilRuler,
    FaServer,
    FaUserAlt,
    FaUserCog,
    FaAsterisk,
    FaPuzzlePiece,
    FaSatelliteDish,
    FaEye,
    FaListUl,
    FaBox,
    FaUsers,
    FaSdCard,
    FaUserLock
} from "react-icons/fa";
import brand from "../../../assets/img/logo_c.jpg";
import brandHighContrast from "../../../assets/img/logo_c_branco.png";
import { useMenu } from "../../../shared/contexts/MenuContext";
import { useUser } from "../../../shared/contexts/UserContext";
import { useAuth } from "../../../shared/contexts/Auth";
import { useAccessibility } from "../../../shared/contexts/AccessibilityContext";
import {
    Container,
    Options,
    Puller,
    LinkBrand,
    NavButton,
    Buttons,
    OptionsWrap
} from "./styles";

const MenuMobile: React.FC = () => {
    const {
        menu,
        trackedOption,
        openOrCloseMenu,
        expanderManagementOption
    } = useMenu();
    const { hasCompleteRegistration } = useUser();
    const { signOut, hasPermission } = useAuth();
    const { theme } = useAccessibility();
    const location = useLocation();

    const handleClick = () => {
        openOrCloseMenu();
    };

    const handleLogoffClick = () => {
        openOrCloseMenu();
        signOut();
    };

    const getLogoImage = () => theme.id !== 2 ? brand : brandHighContrast;

    return (
        <Container className="aux-menu-mobile">
            <Buttons>
                <LinkBrand>
                    <Link to="/" title="Minha Constructore">
                        <img src={getLogoImage()} alt="Logo Constructore" />
                    </Link>                
                </LinkBrand>
                <NavButton>
                    <Link to="#" title="Busca">
                        <IconSearch />
                    </Link>                
                </NavButton>                
                <NavButton>
                    <Link to="#" title="Calendário">
                        <IconSchedule />
                    </Link>                
                </NavButton>
                <Puller onClick={() => handleClick()}>
                    {menu.isOpen ? <IconClose /> : <IconOpen />}
                </Puller>
            </Buttons>            
            <OptionsWrap className={menu.isOpen ? 'open' : 'closed'}>
                {location.pathname?.includes('courseenvironment') ? (
                    <Options aria-label="Menu Ambiente de Curso">
                        <li className={trackedOption===1 ? 'active' : ''}>
                            <Link id="first" to={location.pathname} title="Apresentação">
                                <div><FaAsterisk /><span>Apresentação</span></div>
                            </Link>
                        </li>
                        {hasCompleteRegistration && (
                            <li className={trackedOption===2 ? 'active' : ''}>
                                <Link to='#' title="MenuOpcao2">
                                    <div><FaPuzzlePiece /><span>Menu Opcao 2</span></div>
                                </Link>
                            </li>
                        )}
                        {hasCompleteRegistration && (
                            <li className={trackedOption===3 ? 'active' : ''}>
                                <Link to='#' title="MenuOpcao3">
                                    <div><FaSatelliteDish /><span>Menu Opcao 3</span></div>
                                </Link>
                            </li>
                        )}
                        {hasCompleteRegistration && (
                            <li className={trackedOption===4 ? 'active' : ''}>
                                <Link to='#' title="MenuOpcao4">
                                    <div><FaEye /><span>Menu Opcao 4</span></div>
                                </Link>
                            </li>
                        )}
                        {hasCompleteRegistration && (
                            <li className={trackedOption===5 ? 'active' : ''}>
                                <Link to='#' title="MenuOpcao5">
                                    <div><FaListUl /><span>Menu Opcao 5</span></div>
                                </Link>
                            </li>
                        )}
                        {hasCompleteRegistration && (
                            <li className={trackedOption===6 ? 'active' : ''}>
                                <Link to='#' title="MenuOpcao6">
                                    <div><FaBox /><span>Menu Opcao 6</span></div>
                                </Link>
                            </li>
                        )}
                        {hasCompleteRegistration && (
                            <li className={trackedOption===7 ? 'active' : ''}>
                                <Link to='#' title="MenuOpcao7">
                                    <div><FaUsers /><span>Menu Opcao 7</span></div>
                                </Link>
                            </li>
                        )}                    
                        {hasCompleteRegistration && (
                            <li className={trackedOption===8 ? 'active' : ''}>
                                <Link to='#' title="MenuOpcao8">
                                    <div><FaSdCard /><span>Menu Opcao 8</span></div>
                                </Link>
                            </li>
                        )}
                        {hasCompleteRegistration && (
                            <li className={trackedOption===9 ? 'active' : ''}>
                                <Link to='#' title="MenuOpcao9">
                                    <div><FaUserLock /><span>Menu Opcao 9</span></div>
                                </Link>
                            </li>
                        )}
                    </Options>
                ) : (
                    <Options>
                        {hasCompleteRegistration && (
                            <li className={trackedOption===1 ? 'active' : ''}>
                                <Link to='/mycourses' title="Meus cursos">
                                    <div><FaChalkboardTeacher /><span>Meus cursos</span></div>
                                </Link>
                            </li>
                        )}
                        <li className={trackedOption===2 ? 'active' : ''}>
                            <Link to='/mydata' title="Meus dados">
                                <div><FaUserAlt /><span>Meus dados</span></div>
                            </Link>
                        </li>
                        {hasCompleteRegistration && (
                            <li className={trackedOption===3 ? 'active' : ''}>
                                <Link to='#' title="Agenda">
                                    <div><IconSchedule /><span>Agenda</span></div>
                                </Link>
                            </li>
                        )}
                        {hasCompleteRegistration && hasPermission() && (
                            <li className={trackedOption===4 ? 'active' : ''}>
                                <Link to='/course' title="Criar curso" reloadDocument={true}>
                                    <div><FaPencilRuler /><span>Criar curso</span></div>
                                </Link>
                            </li>
                        )}
                        {hasCompleteRegistration && (
                            <li className={trackedOption===5 ? 'active' : ''}>
                                <Link to='#' title="Recursos">
                                    <div><FaServer /><span>Recursos</span></div>
                                </Link>
                            </li>
                        )}
                        {hasCompleteRegistration && (
                            <li>
                                <div className={`option-parent ${trackedOption===6 ? 'active' : ''}`}
                                    title="Administração"
                                    onClick={e => expanderManagementOption()}
                                >
                                    <div><FaUserCog /><span>Administração</span></div>
                                </div>
                                <ul className={`option-parent-list ${
                                    !menu.options.isOpenManagement || !menu.isOpen ? 'closed':''
                                }`}>
                                    <li>Cursos</li>
                                    <li>
                                        <Link to='/Admin' title="Administração">
                                            <div><span>Usuários</span></div>
                                        </Link>
                                    </li>
                                    <li>Layout</li>
                                    <li>Estatística</li>
                                    <li>Grupos de áreas de conhecimento</li>
                                    <li>Perguntas de inscrição</li>
                                    <li>Suporte técnico</li>
                                </ul>
                            </li>
                        )}
                        <li>
                            <button onClick={handleLogoffClick}>Sair</button>
                        </li>
                    </Options>
                )}
            </OptionsWrap>
        </Container>
    )
};

export default MenuMobile;