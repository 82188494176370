import styled from "styled-components";

export const Container = styled.div`
    table {
        width: 100%;

        thead {
            background: var(--gray5);
        }

        th, td {
            padding: 8px;
        }

        tr:nth-child(even) {
            background: var(--gray5);
        }
    }
`;

export const CustomTitleContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    span {
        &:first-of-type {
            align-self: center;
        }
        &:last-of-type {
            button {
                font-size: inherit;

                svg {
                    display: flex;
                    color: ${props => props.theme.colors.labelText};
                }
            }
        }
    }
`;