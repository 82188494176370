import {
    CourseModuleType,
    DomainOption,
    DomainRegion,
    KnowledgeArea,
    Level
} from "../../models/domains/Domains";
import api from "../api";

const ENDPOINT = '/domain';

export const dropdownService = {
    listRegions,
    listCities,
    getUFWhithCities,
    // listUFs,
    listKnowledgeAreas,
    listLevels,
    listCourseModuleTypes,
    listCourseModalities,
};

async function listRegions(): Promise<DomainRegion[]> {
    const { data } = await api.get(`${ENDPOINT}/estado/listAll`);

    return data;
};

async function listCities(): Promise<any[]> {
    const { data } = await api.get(`${ENDPOINT}/cidade/listAll`);

    return data;
};

async function getUFWhithCities(ufId: string): Promise<any[]> {
    const { data } = await api.get(`${ENDPOINT}/estado/${ufId}`);

    return data;
};

// async function listUFs(): Promise<any[]> {
//     const { data } = await api.get(`${ENDPOINT}/estado/listAll`);

//     return data;
// };

async function listKnowledgeAreas(): Promise<KnowledgeArea[]> {
    const { data } = await api.get(`${ENDPOINT}/areaConhecimentol/listAll`);

    return data;
};

async function listLevels(): Promise<Level[]> {
    const { data } = await api.get(`${ENDPOINT}/nivel/listAll`);

    return data;
};

async function listCourseModuleTypes(): Promise<CourseModuleType[]> {
    const { data } = await api.get(`${ENDPOINT}/tipoCursoModulo/listAll`);

    return data;
};

async function listCourseModalities(): Promise<DomainOption[]> {
    const { data } = await api.get(`${ENDPOINT}/modalidade/listAll`);

    return data;
};