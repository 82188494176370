import styled from 'styled-components';

export const Container = styled.div`
    grid-area: CT; // Content

    overflow-y: auto;
    padding: 16px 100px;

    @media (max-width: 1200px) {
        padding: 16px 16px 16px 64px;
    }

    @media (max-width: 576px) {
        padding: 16px 16px 64px 16px;
        width: 100vw;
        height: calc(100vh - (72px + 32px));
    }

    a, .dropzone {
        &:focus {
            outline: 1px dotted var(--primary);
        }
    }
`;