import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './shared/contexts/Auth';
import { LoaderProvider } from './shared/contexts/LoadingContext';
import { UserProvider } from './shared/contexts/UserContext';
import { MenuProvider } from './shared/contexts/MenuContext';
import { CourseProvider } from './shared/contexts/CourseContext';
import { DropdownProvider } from './shared/contexts/domains/DropdownContext';
import { AccessibilityProvider } from './shared/contexts/AccessibilityContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <AccessibilityProvider>
        <AuthProvider>
          <LoaderProvider>
            <DropdownProvider>
              <UserProvider>
                <CourseProvider>
                  <MenuProvider>
                    <App />
                  </MenuProvider>
                </CourseProvider>
              </UserProvider>
            </DropdownProvider>
          </LoaderProvider>
        </AuthProvider>
      </AccessibilityProvider>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
