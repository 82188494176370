import { useState, useRef, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
// import { useCourse } from "../../../shared/contexts/CourseContext";
import StepOne from "../StepOne";
import StepTwo from "../StepTwo";
import StepThree from "../StepThree";
import StepFour from "../StepFour";
import StepFive from "../StepFive";
import { Container, Steps } from "./styles";

interface RefObject {
    switchOrSubmitStep1: (newTargetStep: number|undefined) => void;
    switchOrSubmitStep2: (newTargetStep: number|undefined) => void;
    switchOrSubmitStep3: (newTargetStep: number|undefined) => void;
    switchOrSubmitStep4: (newTargetStep: number|undefined) => void;
    switchOrSubmitStep5: (newTargetStep: number|undefined) => void;
}

const CourseEdit: React.FC = () => {
    const  myRef = useRef<RefObject>(null)
    const [ step, setStep ] = useState<number>(1);
    const { codigo } = useParams();
    const location = useLocation();
    // const {
    //     resetCourseEditing,
    // } = useCourse();

    const getCode = () => {
        return Number(codigo) || 0
    }

    const switchOrSubmitStep1 = (newTargetStep: number|undefined) => {
        if (myRef.current) {
            myRef.current.switchOrSubmitStep1(newTargetStep);
        }
    }

    const switchOrSubmitStep2 = (newTargetStep: number|undefined) => {
        if (myRef.current) {
            myRef.current.switchOrSubmitStep2(newTargetStep);
        }
    }

    const switchOrSubmitStep3 = (newTargetStep: number|undefined) => {
        if (myRef.current) {
            myRef.current.switchOrSubmitStep3(newTargetStep);
        }
    }

    const switchOrSubmitStep4 = (newTargetStep: number|undefined) => {
        if (myRef.current) {
            myRef.current.switchOrSubmitStep4(newTargetStep);
        }
    }

    const switchOrSubmitStep5 = (newTargetStep: number|undefined) => {
        if (myRef.current) {
            myRef.current.switchOrSubmitStep5(newTargetStep);
        }
    }    

    const handleSwitchToStep = (newStep: number) => {
        const hasChangeStep: boolean = step !== newStep;
        if(hasChangeStep) {
            if(step === 1) switchOrSubmitStep1(newStep);
            if(step === 2) switchOrSubmitStep2(newStep);
            if(step === 3) switchOrSubmitStep3(newStep);
            if(step === 4) switchOrSubmitStep4(newStep);
            if(step === 5) switchOrSubmitStep5(newStep);
        }
    }

    useEffect(() => {
        // console.log("Location: ", location);
        const { state } = location;
        if(state && typeof state === 'string' && state.includes('prevStep')) {
            const stepRecovered = Number(state.split(':')[1]);
            setStep(stepRecovered)
        }
    },[location]);

    // useEffect(() => {
    //     return () => {
    //         setStep(0);
    //         resetCourseEditing();
    //     }
    // }, []);

    return (
        <Container>
            <Breadcrumb>
                <span>Edição de Curso</span>
            </Breadcrumb>            
            <Steps>
                <i />
                <button
                    className={`${step===1 ? 'active' : ''}`}
                    onClick={() => handleSwitchToStep(1)}
                    title="Ir para o passo 1"
                    aria-label="Ir para o passo 1"
                >
                        <span>1</span>
                </button>
                <button
                    className={`${step===2 ? 'active' : ''}`}
                    onClick={() => handleSwitchToStep(2)}
                    title="Ir para o passo 2"
                    aria-label="Ir para o passo 2"
                >
                    <span>2</span>
                </button>
                <button
                    className={`${step===3 ? 'active' : ''}`}
                    onClick={() => handleSwitchToStep(3)}
                    title="Ir para o passo 3"
                    aria-label="Ir para o passo 3"
                >
                    <span>3</span>
                </button>
                <button
                    className={`${step===4 ? 'active' : ''}`}
                    onClick={() => handleSwitchToStep(4)}
                    title="Ir para o passo 4"
                    aria-label="Ir para o passo 4"
                >
                    <span>4</span>
                </button>
                <button
                    className={`${step===5 ? 'active' : ''}`}
                    onClick={() => handleSwitchToStep(5)}
                    title="Ir para o passo 5"
                    aria-label="Ir para o passo 5"
                >
                    <span>5</span>
                </button>                
            </Steps>
            <p></p>
            {step === 1 && (
                <StepOne
                    type={'edit'}
                    setStep={setStep}
                    className={`${step===1 ? 'active' : ''}`}
                    courseCode={getCode()}
                    ref={myRef}
                />
            )}
            {step === 2 && (
                <StepTwo
                    type={'edit'}
                    setStep={setStep}
                    className={`${step===2 ? 'active' : ''}`}
                    courseCode={getCode()}
                    ref={myRef}
                />
            )}
            {step === 3 && (
                <StepThree
                    type={'edit'}
                    setStep={setStep}
                    className={`${step===3 ? 'active' : ''}`}
                    courseCode={getCode()}
                    ref={myRef}
                />
            )}
            {step === 4 && (
                <StepFour
                    type={'edit'}
                    setStep={setStep}
                    className={`${step===4 ? 'active' : ''}`}
                    courseCode={getCode()}
                    ref={myRef}
                />
            )}
            {step === 5 && (
                <StepFive
                    type={'edit'}
                    setStep={setStep}
                    className={`${step===5 ? 'active' : ''}`}
                    courseCode={getCode()}
                    ref={myRef}
                />
            )}              
        </Container>
    )
}
export default CourseEdit;