import React from 'react';
// import Menu from '../Menu';
import { Container } from './styles';

type Props = {
    children?: React.ReactNode | React.ReactNode[];
};

const Content: React.FC<Props> = ({ children }) => (
    <Container id="content">
        { children }
    </Container>
);

export default Content;