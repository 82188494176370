import { useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import StepOne from "../StepOne";
import StepTwo from "../StepTwo";
import StepThree from "../StepThree";
import StepFour from "../StepFour";
import StepFive from "../StepFive";
import { Container, Steps } from "./styles";

const CourseRead: React.FC = () => {
    const [ step, setStep ] = useState<number>(1);
    const { codigo } = useParams();

    const getCode = () => {
        return Number(codigo) || 0
    }

    return (
        <Container>
            <Breadcrumb>
                <span>Visualização de Curso</span>
            </Breadcrumb>            
            <Steps>
                <i />
                <div className={`${step===1 ? 'active' : ''}`}><span>1</span></div>
                <div className={`${step===2 ? 'active' : ''}`}><span>2</span></div>
                <div className={`${step===3 ? 'active' : ''}`}><span>3</span></div>
                <div className={`${step===4 ? 'active' : ''}`}><span>4</span></div>
                <div className={`${step===5 ? 'active' : ''}`}><span>5</span></div>
            </Steps>
            <p></p>
            {step === 1 && (
                <StepOne
                    type={'read'}
                    setStep={setStep}
                    className={`${step===1 ? 'active' : ''}`}
                    courseCode={getCode()}
                />
            )}
            {step === 2 && (
                <StepTwo
                    type={'read'}
                    setStep={setStep}
                    className={`${step===2 ? 'active' : ''}`}
                    courseCode={getCode()}
                />
            )}
            {step === 3 && (
                <StepThree
                    type={'read'}
                    setStep={setStep}
                    className={`${step===3 ? 'active' : ''}`}
                    courseCode={getCode()}
                />
            )}
            {step === 4 && (
                <StepFour
                    type={'read'}
                    setStep={setStep}
                    className={`${step===4 ? 'active' : ''}`}
                    courseCode={getCode()}
                />
            )}
            {step === 5 && (
                <StepFive
                    type={'read'}
                    setStep={setStep}
                    className={`${step===5 ? 'active' : ''}`}
                    courseCode={getCode()}
                />
            )}              
        </Container>
    )
}
export default CourseRead;