import { useFormik } from "formik";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import InputTextarea from "../../../../components/InputTextarea";
import Spinner from "../../../../components/Spinner";
import InputBinarySelect from "../../../../components/InputBinarySelect";
import { Module } from "../../../../shared/models/course/Module";
import { useLoader } from "../../../../shared/contexts/LoadingContext";
import { useCourse } from "../../../../shared/contexts/CourseContext";
import schema from "./schema";
import { Buttons, Container } from "./styles";

interface Props {
    courseCode: number;
    setAddOrEditModuleShow?: (value: boolean) => void;
    customModuleName: string; // O nome do módulo pode ser alterado pelo professor
    selectedModuleType: number;
}

const AddCourseModule: React.FC<Props> = ({
    courseCode,
    setAddOrEditModuleShow = () => { },
    customModuleName,
    selectedModuleType
}) => {
    const { loading } = useLoader();
    const { fetchStepFour, createModule } = useCourse();

    const resetForm = () => {
        fetchStepFour(courseCode);
        // formik.resetForm();
        setAddOrEditModuleShow(false);
    }

    const cancelEditModule = () => {
        setAddOrEditModuleShow(false);
    }

    const checkDisabledSubmit = () => {
        return !(formik.dirty || formik.isValid) || loading;
    }

    const handleSubmit = (data: Module) => {
        console.log("Criando novo módulo (form): ", formik);
        createModule(data, courseCode, resetForm);
    }

    // Se a seleção for Módulo (default), usar "Módulo". Caso
    // contrário, adicionar prefixo "Módulo", por exemplo, "Módulo
    // Aula", para garantir concordância nominal.
    // const formatModuleText = (customModuleName: string): string => {
    //     return customModuleName === 'Módulo' ? 'Módulo' : `Módulo ${customModuleName}`;
    // };

    // Obs: Optou-se pela preposição neutra: "do(a) Sessao", "do(a)
    // Aula", etc.

    const { setValues, ...formik } = useFormik({
        onSubmit: handleSubmit,
        validationSchema: schema,
        enableReinitialize: true,
        initialValues: {
            id: 0,
            ordem: "",
            nome: "",
            visivelParaAlunos: false,
            descricao: "",
            selecionada: false,
        } as Module
    });

    return (
        <Container className="new-question">
            <form onSubmit={formik.handleSubmit}>
                <section>
                    <h2>Adicionar {customModuleName}</h2>
                    <InputBinarySelect
                        formik={formik}
                        label="Visível Para Alunos"
                        name="visivelParaAlunos"
                        value={formik?.values?.visivelParaAlunos}
                        className="visible-to-student"
                        disabled={formik?.isSubmitting}
                    />
                    <Input
                        label={`Nome do(a) ${customModuleName}`}
                        id="nome"
                        name="nome"
                        placeholder={`Digite a descrição do(a) ${customModuleName}`}
                        value={formik?.values?.nome}
                        onChange={formik?.handleChange}
                        readOnly={formik?.isSubmitting}
                        errorText={formik?.errors?.nome}
                    />
                    <InputTextarea
                        label={`Descrição do(a) ${customModuleName}`}
                        id="descricao"
                        name="descricao"
                        placeholder={`Escreva a descrição do(a) ${customModuleName}`}
                        value={formik?.values?.descricao}
                        onChange={formik?.handleChange}
                        readOnly={formik?.isSubmitting}
                        errorText={formik?.errors?.descricao}
                    />
                </section>
                <Buttons>
                    <Button
                        type="submit"
                        btnTheme="primary"
                        disabled={checkDisabledSubmit()}
                    >
                        {loading ? <Spinner /> : `Criar ${customModuleName}`}
                    </Button>
                    <Button
                        btnTheme="primary"
                        onClick={cancelEditModule}
                        disabled={checkDisabledSubmit()}
                    >
                      {`Cancelar Criação do(a) ${customModuleName}`}
                    </Button>
                </Buttons>
            </form>
        </Container>
    )
}

export default AddCourseModule;
