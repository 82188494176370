import { useState } from "react";
import { Link } from "react-router-dom";

import {
    FaAngleDoubleDown as IconClose,
    FaAngleDoubleUp as IconOpen,
} from "react-icons/fa";
import { Container } from "./styles";

const ContextualMenu: React.FC = () => {
    // const {
    //     menu,
    //     trackedOption,
    //     openOrCloseMenu,
    //     expanderManagementOption
    // } = useMenu();

    const [contextMenuOpen, setContextMenuOpen] = useState(false);

    const handleContextMenuClick = () => {
        setContextMenuOpen(!contextMenuOpen);
    }

    return (
        <Container className="contextual-menu">
            <div className={`cm-list ${contextMenuOpen ? 'cm-open' : 'cm-close'}`}>
                <div className="cm-puller" onClick={handleContextMenuClick}>
                    {contextMenuOpen ? <IconClose /> : <IconOpen />}
                    <span>Funcionalidades</span>
                </div>
                <ul>
                    <li>
                        <Link to='#' title="Manual">
                            <span>Manual</span>
                        </Link>
                    </li>
                    <li>
                        <Link to='#' title="Imprimir">
                            <span>Imprimir</span>
                        </Link>
                    </li>
                    <li>
                        <Link to='#' title="Fale conosco">
                            <span>Fale conosco</span>
                        </Link>
                    </li>
                    <li>
                        <Link to='#' title="Informações">
                            <span>Informações</span>
                        </Link>
                    </li>                
                </ul>
            </div>
        </Container>
    )
};

export default ContextualMenu;