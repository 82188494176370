import styled from 'styled-components'

export const Container = styled.div`
    grid-area: AS; // Aside

    border-width: 2px;
    border-color: #828282;
    border-right-style: solid;
    position: relative;

    > ul {        
        > li {
            color: ${props => props.theme.colors.labelText};
        }
    }

    &.closed {
        width: 117px;
        transition: width 0.3s ease;

        > ul {
            > li {
                a,
                .option-parent {
                    div {
                        justify-content: center;
                    }
                }

                svg {
                    height: 54px;
                    /* transform: scale(2.2); */
                    width: 40px;
                    transition: width 0.3s ease;
                }

                span {
                    display: none;
                }

                /* &.closed-menu-mark {
                    background: var(--gray5);
                } */
            }
        }
    }
    &.open {
        width: 196px;
        transition: width 0.3s ease;

        > ul {
            padding: 16px 7px;
            
            > li {
                padding-left: 8px;

                svg {
                    height: 54px;
                    /* transform: scale(1.2); */
                    width: 20px;
                    transition: width 0.3s ease;
                }

                span {
                    display: unset;
                }                
            }
        }        
    }

    @media (max-width: 576px) {
        display: none;
    }
`;

export const Puller = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;    

    position: absolute;
    width: 50px;
    height: 60px;
    top: 45vh;
    right: -50px;

    border: 2px solid #828282;
    border-radius: 0 10px 10px 0;

    svg {
        font-size: 40px;
        color: ${props => props.theme.colors.titleText};
    }
`;

export const Options = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    row-gap: 16px;

    > li {
        display: flex;
        flex-direction: column;
        align-self: center;
        min-width: 100%;
        width: 100%;

        &:hover {
            cursor: pointer;
        }

        a,
        .option-parent {
            color: ${props => props.theme.colors.menuElements} !important;
            transition: 0.5s;
            width: 100%;
            text-decoration: none;

            &:hover {
                color: #986769 !important;
                transition: 0.5s;
            }

            > div {
                display: flex;
                flex-direction: row;
                width: 100%;
                column-gap: 8px;
                /* background: green; */

                span {
                    white-space: pre;
                    align-self: center;
                }
            }
        }

        svg {
            /* background: pink; */
            /* height: 54px;
            transform: scale(2.2); */
        }

        > ul {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding-top: 8px;
            /* transition: 0.2s; */
            height: 280px;
            

            &.closed {
                /* transition: 0.2s; */
                /* display: none; */
                height: 0;
                overflow: hidden;
                /* background: red; */
            }

            > li {
                text-align: left;
                padding-left: 8px;

                a {
                    text-decoration: none;
                }
            }
        }
    }

    .active {
        background-color: ${props => props.theme.colors.bgMenuElements};
    }
`;