import './App.css';
import Routes from './routers';
import GlobalStyled from './assets/styles/GlobalStyles';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { useAccessibility } from './shared/contexts/AccessibilityContext';
import VLibras from '@djpfs/react-vlibras';

function App() {
  const { theme, fontSize } = useAccessibility();

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyled fontSize={fontSize}/>
        <span id="vlibras">
          <VLibras forceOnload />
        </span>
        <div>
          <ToastContainer
            theme="colored"
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            role="alert"
          />
        </div>
        <Routes />
      </ThemeProvider>
    </div>
  );
}

export default App;
