import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    label {
        align-self: start;
        padding: 0 4px 2px 0;
    }

    > div {
        display: flex;
        flex-direction: row;
    }

    .react-select-container {
        align-content: 'flex-start';
    }

    small {
        margin-top: 2px;
        align-self: start;
        text-align: left;
        color: red;
        min-height: 15px;
    }
`;

export const CustomOption = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    span:first-child {
        img {
            border-radius: 50%;
            height: 24px;
            width: 24px;

            @media (max-width: 768px) {
                height: 56px;
                width: 56px;
            }            
        }
    }
`;