import { FaSpinner } from "react-icons/fa";
import { Container } from "./styles";

const SpinnerScreen: React.FC = () => {
    return (
        <Container>
            <FaSpinner />
        </Container>
    )
}
export default SpinnerScreen;