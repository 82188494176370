import React, { useState, useEffect } from "react";
import { useDropzone, FileRejection, FileError, Accept } from "react-dropzone";
import { FaFolderPlus, FaTrash } from "react-icons/fa";
import {
    Container,
    Wrapper,
    Label,
    FileList,
    FileItem,
    FileNumber,
    FileRemove
} from "./styles";

interface Props {
    onFileUpload: (file: File[]) => void;
    label?: string;
    placeholder?: string;
    tooltip?: string;
    disabled?: boolean;
    maxSizeMB?: number;
    accept?: Accept;
}

const FileUpload: React.FC<Props> = ({
    onFileUpload,
    label = "Inserir Anexos: ",
    tooltip = "Arraste e solte um arquivo aqui ou clique para selecionar.",
    disabled = false,
    maxSizeMB = 5,
    accept = {
        "image/*": [".jpeg", ".jpg", ".png", ".gif"],
        "application/pdf": [".pdf"]
    }
}) => {
    const [files, setFiles] = useState<File[]>([]);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: true,
        accept: accept,
        maxSize: maxSizeMB * 1024 * 1024,
        disabled: disabled,
        onDropAccepted: (acceptedFiles: File[]) => {
            setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
            onFileUpload([...files, ...acceptedFiles]);
        },
        onDropRejected: (fileRejections: FileRejection[]) => {
            const errorCodes: string[] = fileRejections[0]?.errors.map(
                (x: FileError) => {
                    return x.code as string;
                }
            );
            console.error("File upload errors: ", errorCodes);
        }
    });

    useEffect(() => {
        // Revoke data uris to avoid memory leaks
        return () => {
            files.forEach((file: any) => URL.revokeObjectURL(file.preview));
        };
    }, [files]);

    const removeFile = (index: number) => {
        const newFiles = files.filter((_, i) => i !== index);
        setFiles(newFiles);
        onFileUpload(newFiles);
    };

    return (
        <Wrapper>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Label>{label}</Label>
                <Container
                    {...getRootProps({
                        className: `dropzone ${files.length > 0 ? "has-file" : ""}`,
                        isFocused,
                        isDragAccept,
                        isDragReject
                    })}
                    title={!disabled ? tooltip : ""}
                >
                    <input {...getInputProps()} />
                    <FaFolderPlus size={40} style={{ marginLeft: "10px" }} />
                </Container>
            </div>
            <FileList>
                {files.map((file, index) => (
                    <FileItem key={index}>
                        <FileNumber>{index + 1}.</FileNumber> {file.name}
                        <FileRemove onClick={() => removeFile(index)}>
                            <FaTrash />
                        </FileRemove>
                    </FileItem>
                ))}
            </FileList>
        </Wrapper>
    );
};

export default FileUpload;
