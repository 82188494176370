import api from './api';
import { Module } from '../models/course/Module';
import { IStepFour } from '../models/course/Course';

const ENDPOINT = 'curso_modulos';

export const courseModuleService = {
    listCourseModules,
    editCourseModules,
    create,
    edit,
    remove,
    removeList,
};

async function listCourseModules(courseCode: number): Promise<IStepFour> {
    const { data } = await api.get<any>(`${ENDPOINT}/passo/${courseCode}`);

    return data;
    // return /*data?.length > 0 ? data :*/ { // mockup
    //     idTipoDeModuloSelecionado: 2,
    //     modulos: [
    //         {
    //             id: 1,
    //             nome: "I (mockup)",
    //             ordem: "1",
    //             selecionada: true,
    //             descricao: "Mockup Lorem",
    //             visivelParaAlunos: false,
    //         },
    //         {
    //             id: 2,
    //             nome: "II (mockup)",
    //             ordem: "2",
    //             selecionada: false,
    //             descricao: "Mockup Ipsum",
    //             visivelParaAlunos: true,
    //         },
    //         {
    //             id: 3,
    //             nome: "III (mockup)",
    //             ordem: "3",
    //             selecionada: true,
    //             descricao: "Mockup dolor sit amet",
    //             visivelParaAlunos: false,
    //         },
    //     ]
    // };
};

async function editCourseModules(module: IStepFour, courseCode: number): Promise<IStepFour> {
    const { data } = await api.put<any>(`${ENDPOINT}/passo/${courseCode}`, module);

    return data;
};

async function create(courseCode: number, module: Module): Promise<any> {
    const { data } = await api.post<any>(`${ENDPOINT}/${courseCode}`, module);

    return data;
};

async function edit(module: Module, courseCode: number): Promise<any> {
    const { data } = await api.put<any>(`${ENDPOINT}/${courseCode}`, module);

    return data;
};

async function remove(courseCode: number, modulesId: number): Promise<any> {
    const { data } = await api.delete<any>(`${ENDPOINT}/${courseCode}/${modulesId}`);

    return data;
};

async function removeList(courseCode: number, moduleIds: number[]): Promise<any> {
    const { data } = await api.delete(`${ENDPOINT}/${courseCode}`, {
        data: moduleIds
    });

    return data;
};