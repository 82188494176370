import React, { Suspense, lazy, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Layout from '../components/Layout';
import Admin from '../pages/Admin';
import EditForm from '../pages/Admin/EditForm';
import AddForm from '../pages/Admin/AddForm';
import CourseCreate from '../pages/Course/CourseCreate';
import StepOne from '../pages/Course/StepOne';
import StepTwo from '../pages/Course/StepTwo';
import CourseEdit from '../pages/Course/CourseEdit';
import CourseRead from '../pages/Course/CourseRead';
import CourseRegistration from '../pages/CourseRegistration';
import MyCourses from '../pages/MyCourses';
import ForumPage from '../pages/Communication/Forum/ForumPage';
import NewPost from '../pages/Communication/Forum/NewPost';
// import Home from '../pages/Home';
// import MyData from '../pages/MyData';
import SpinnerScreen from '../components/SpinnerScreen';
import { useDropdown } from '../shared/contexts/domains/DropdownContext';
import { useUser } from '../shared/contexts/UserContext';
import { useAuth } from '../shared/contexts/Auth';
import SiteMap from '../pages/SiteMap';
import Accessibility from '../pages/Accessibility';
import UpdatePassword from '../pages/MyData/UpdatePassword';

const AppRoutes: React.FC = () => {
    const { checkCompleteRegistration } = useUser();
    const { hasPermission } = useAuth();
    const {
        fetchRegions,
        // fetchUFs, ufs,
        fetchCities,
        fetchKnowledgeAreas,
        fetchLevels,
        fetchCourseModuleTypes,
        fetchCourseModalities,
    } = useDropdown();
    const Home = lazy(() => import("../pages/Home"));
    const MyData = lazy(() => import('../pages/MyData'));
    // const CourseCreate = lazy(() => import("../pages/Course/CourseCreate"));
    // const CourseEdit = lazy(() => import("../pages/Course/CourseEdit"));
    // const CourseRead = lazy(() => import("../pages/Course/CourseRead"));
    const CourseEnvironment = lazy(() => import("../pages/CourseEnvironment"));

    useEffect(() => {
        checkCompleteRegistration();
    }, []);

    useEffect(() => {
        fetchRegions();
        // fetchUFs();
        fetchCities();
        fetchKnowledgeAreas();
        fetchLevels();
        fetchCourseModuleTypes();
        fetchCourseModalities();
    }, []);

    const renderStepOne = () => {
        return (
            <StepOne
                setStep={() => {}}
                className=""
                type="create"
                courseCode={0}
            />
        );
    };

    const renderStepTwo = () => {
        return (
            <StepTwo
                setStep={() => {}}
                className=""
                type="create"
                courseCode={0}
            />
        );
    };

    return (
        <Layout>
            <Routes>
                {/* <Route path="/" element={<Home/>} /> */}
                <Route path="/" element={
                    <Suspense fallback={<SpinnerScreen />}>
                        <Home/>
                    </Suspense>
                }/>
                <Route path="/admin" element={<Admin/>} />
                <Route path="/admin/edit/:id" element={<EditForm/>} />
                {/* <Route path="/mydata" element={<MyData/>} /> */}
                <Route path="/mydata" element={
                    <Suspense fallback={<SpinnerScreen />}>
                        <MyData/>
                    </Suspense>
                }/>
                {hasPermission() && <Route path="/course" element={<CourseCreate/>} />}
                {/* {hasPermission() && (
                    <Route path="/course" element={
                        <Suspense fallback={<h1>Loading...</h1>}>
                            <CourseCreate/>
                        </Suspense>
                    }/>
                )} */}
                {hasPermission() && <Route path="/course/edit/:codigo" element={<CourseEdit/>} />}
                {/* {hasPermission() && (
                    <Route path="/course/edit/:codigo" element={
                        <Suspense fallback={<h1>Loading...</h1>}>
                            <CourseEdit/>
                        </Suspense>
                    }/>
                )} */}
                {!hasPermission() && <Route path="/course/read/:codigo" element={<CourseRead/>} />}
                {/* {!hasPermission() && (
                    <Route path="/course/read/:codigo" element={
                        <Suspense fallback={<h1>Loading...</h1>}>
                            <CourseRead/>
                        </Suspense>
                    }/>
                )} */}
                <Route path="/courseenvironment/:codigo" element={
                    <Suspense fallback={<SpinnerScreen/>}>
                        <CourseEnvironment />
                    </Suspense>
                } />
                <Route path="/mycourses" element={<MyCourses/>} />
                <Route path="/sitemap" element={<SiteMap/>} />
                <Route path="/accessibility" element={<Accessibility/>} />
                <Route path="/updatepassword/:id" element={<UpdatePassword/>} />
                <Route path="/communication/forum" element={<ForumPage />} />
                <Route path="/communication/forum/post" element={<NewPost />} />
                {hasPermission() && <Route path="/register" element={<AddForm/>} />}

                {hasPermission() && <Route path="/course/step-one" element={renderStepOne()} />}
                {hasPermission() && <Route path="/course/step-two" element={renderStepTwo()} />}
                {hasPermission() && <Route path="/course-registration" element={<CourseRegistration/>} />}

                <Route path="*" element={<Navigate to={"/"}/>} />
            </Routes>
        </Layout>
    );
};

export default AppRoutes;
