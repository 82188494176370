import api from './api';
import { Question } from '../models/course/Question';
import { IStepTwo } from '../models/course/Course';

export const questionAssociationService = {
    getQuestionsByCourseId,
    createStepTwo,
    editStepTwo,
};

const ENDPOINT = 'curso_perguntas_curso';

// Passo 2
async function getQuestionsByCourseId(courseCode: number): Promise<Question[]> {
    const { data } = await api.get(`${ENDPOINT}/${courseCode}`);

    return data;
};

async function createStepTwo(questionsId: IStepTwo, courseCode: number): Promise<any> {
    const { data } = await api.post<any>(`${ENDPOINT}/${courseCode}`, questionsId);

    return data;
};

async function editStepTwo(questionsId: IStepTwo, courseCode: number): Promise<any> {
    const { data } = await api.put<any>(`${ENDPOINT}/${courseCode}`, questionsId);

    return data;
};