import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    column-gap: 16px;

    a {
        color: ${props => props.theme.colors.placeholderSearchText};

        &:link, &:hover, &:visited, &:active {
            color: ${props => props.theme.colors.placeholderSearchText};
        }
    }

    a:last-child {
        color: ${props => props.theme.colors.labelText};

        &:link, &:hover, &:visited, &:active {
            color: ${props => props.theme.colors.labelText};
        }
    }
`;