import styled from 'styled-components';

export const Container = styled.div`
    
`;

export const Content = styled.div`
    /* height: 100vh; */
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    row-gap: 24px;

    min-height: calc(100vh - 35px - 47px - 8px);
    justify-content: start;
`;

export const Form = styled.form`
    width: 400px;

    > div:first-child {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }

    @media (max-width: 576px) {
        width: auto;
        width: 100%;
        height: unset;
    }    
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: end;
    column-gap: 32px;

    @media (max-width: 576px) {
        width: 100%;
        column-gap: 24px;
        justify-content: space-evenly;

        button {
            width: 100%;
        }
    }
`;