import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { questionAssociationService } from "../../shared/services/courseQuestionAssociationService";
import Breadcrumb from "../../components/Breadcrumb";
import Title from "../../components/Title";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import InputTextarea from "../../components/InputTextarea";
import { toast } from "react-toastify";
import { Question } from "../../shared/models/course/Question";
import {
    Container,
    Form,
    Text,
    QuestionContainer,
    Label,
    ResponseLabel,
    ButtonWrapper,
    CheckboxWrapper,
    CheckboxLabel,
    RadioWrapper,
    RadioLabel
} from "./styles";
import { TYPE_QUESTION } from "../../shared/consts";

// export const TYPE_QUESTION: DomainSelectOption[] = [
//     { value: '0', label: 'Discursiva' },
//     { value: '1', label: 'Alternativas Sim e Não' },
//     { value: '2', label: 'Múltipla escolha de seleção única' },
//     { value: '3', label: 'Múltipla escolha de múltipla seleção' },
// ];

const CourseRegistration: React.FC = () => {
    const [questions, setQuestions] = useState<Question[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const courseCode = 89; // FIXME:

    useEffect(() => {
        async function fetchQuestions() {
            try {
                const courseQuestions =
                    await questionAssociationService.getQuestionsByCourseId(
                        courseCode
                    );
                const initialResponses: {
                    [key: number]: string | boolean | number[];
                } = {};
                courseQuestions.forEach((question) => {
                    if (question.id !== undefined) {
                        if (question.tipo === "0")
                            initialResponses[question.id] = "";
                        if (question.tipo === "1")
                            initialResponses[question.id] = "";
                        if (question.tipo === "2")
                            initialResponses[question.id] = "";
                        if (question.tipo === "3")
                            initialResponses[question.id] = [];
                    }
                });
                formik.setValues({
                    ...formik.values,
                    responses: initialResponses
                });
                setQuestions(courseQuestions);
            } catch (err: any) {
                setError(err.message);
                toast.error(err.message);
            } finally {
                setLoading(false);
            }
        }

        fetchQuestions();
    }, [courseCode]);

    const formik = useFormik({
        initialValues: {
            responses: {} as { [key: number]: string | boolean | number[] },
            perguntasIds: [] as number[]
        },
        onSubmit: async (values) => {
            try {
                await questionAssociationService.createStepTwo(
                    { perguntasIds: values.perguntasIds },
                    courseCode
                );
                toast.success("Respostas enviadas com sucesso!");
            } catch (err: any) {
                toast.error("Erro ao enviar respostas: " + err.message);
            }
        }
    });

    const checkDisabledSubmit = () => {
        return formik.isSubmitting || !formik.isValid;
    };

    const getErrorMessage = (fieldName: string) => {
        return formik?.getFieldMeta(fieldName)?.touched &&
            formik?.getFieldMeta(fieldName)?.error
            ? formik.getFieldMeta(fieldName).error
            : "";
    };

    const renderQuestionInput = (question: Question) => {
        if (question.id === undefined) return null;

        const responseValue = formik.values.responses[question.id];

        switch (question.tipo) {
            case "0":
                return (
                    <InputTextarea
                        id={`responses.${question.id}`}
                        name={`responses.${question.id}`}
                        placeholder="Escreva aqui sua resposta..."
                        value={(responseValue as string) || ""}
                        onChange={formik.handleChange}
                        errorText={getErrorMessage(`responses.${question.id}`)}
                        height="120px"
                    />
                );
            case "1":
                return (
                    <RadioWrapper>
                        <RadioLabel>
                            <input
                                type="radio"
                                id={`responses.${question.id}.sim`}
                                name={`responses.${question.id}`}
                                value="true"
                                checked={responseValue === "true"}
                                onChange={formik.handleChange}
                            />
                            Sim
                        </RadioLabel>
                        <RadioLabel>
                            <input
                                type="radio"
                                id={`responses.${question.id}.nao`}
                                name={`responses.${question.id}`}
                                value="false"
                                checked={responseValue === "false"}
                                onChange={formik.handleChange}
                            />
                            Não
                        </RadioLabel>
                    </RadioWrapper>
                );
            case "2":
                return (
                    <RadioWrapper>
                        {question.valorTipoMultiplaEscolhaUnica?.map(
                            (option) => (
                                <RadioLabel key={option.id}>
                                    <input
                                        type="radio"
                                        id={`responses.${question.id}.${option.id}`}
                                        name={`responses.${question.id}`}
                                        value={option.id}
                                        checked={
                                            responseValue ===
                                            option.id.toString()
                                        }
                                        onChange={formik.handleChange}
                                    />
                                    {option.texto}
                                </RadioLabel>
                            )
                        )}
                    </RadioWrapper>
                );
            case "3":
                return (
                    <CheckboxWrapper>
                        {question.valorTipoMultiplaEscolhaMultipla?.map(
                            (option) => (
                                <CheckboxLabel key={option.id}>
                                    <input
                                        type="checkbox"
                                        id={`responses.${question.id}.${option.id}`}
                                        name={`responses.${question.id}`}
                                        value={option.id}
                                        checked={
                                            Array.isArray(responseValue) &&
                                            (
                                                responseValue as number[]
                                            ).includes(option.id)
                                        }
                                        onChange={(e) => {
                                            const valueArray = Array.isArray(
                                                responseValue
                                            )
                                                ? (responseValue as number[])
                                                : [];
                                            if (e.target.checked) {
                                                formik.setFieldValue(
                                                    `responses.${question.id}`,
                                                    [...valueArray, option.id]
                                                );
                                            } else {
                                                formik.setFieldValue(
                                                    `responses.${question.id}`,
                                                    valueArray.filter(
                                                        (id) => id !== option.id
                                                    )
                                                );
                                            }
                                        }}
                                    />
                                    {option.texto}
                                </CheckboxLabel>
                            )
                        )}
                    </CheckboxWrapper>
                );
            default:
                return null;
        }
    };

    const instructions = `Por favor, forneça respostas às perguntas formuladas pelo instrutor do curso.`;

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Container>
            <Breadcrumb>
                <span>Inscrição do curso</span>
            </Breadcrumb>
            <Text>{instructions}</Text>
            <Title>Perguntas</Title>
            <Form onSubmit={formik.handleSubmit}>
                {questions.map(
                    (question, index) =>
                        question.id !== undefined && (
                            <QuestionContainer key={question.id}>
                                <Label htmlFor={`responses.${question.id}`}>
                                    <span>{index + 1}-</span>{" "}
                                    {question.enunciado}
                                    <span>
                                        {" "}
                                        (
                                        {
                                            TYPE_QUESTION.find(
                                                (type) =>
                                                    type.value === question.tipo
                                            )?.label
                                        }
                                        )
                                    </span>
                                </Label>
                                <ResponseLabel>Resposta:</ResponseLabel>
                                {renderQuestionInput(question)}
                            </QuestionContainer>
                        )
                )}
                <ButtonWrapper>
                    <Button
                        type="submit"
                        disabled={checkDisabledSubmit()}
                        btnTheme="primary"
                    >
                        {formik.isSubmitting ? <Spinner /> : "Enviar Respostas"}
                    </Button>
                </ButtonWrapper>
            </Form>
        </Container>
    );
};

export default CourseRegistration;
