import styled from "styled-components";

export const Container = styled.div`
    
`;

export const Photo = styled.div`
    width: 247px;
    min-width: 247px;
    height: 325px;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > small {
        color: var(--secondary);
    }
`;

export const Preview = styled.div`
    overflow: hidden;
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    position: relative;

    aside {
        display: 'flex';
        flex-direction: 'row';
        flex-wrap: 'wrap';
        margin-top: 16;
        min-width: 100%;

        img {
            display: block;
            max-width: 100%;
            min-width: 100%;
        }
    }

    div {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        /* background-color: #ff000042; */

        &:hover {
            cursor: pointer;
        }

        p {
            font-size: 12px;
            color: var(--secondary);
            background: green;
            background: transparent;
        }
    }   
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: end;
    column-gap: 32px;   
`;

export const InputSelectStyle = styled.div`
    display: flex;
    flex-direction: column;
    /* min-width: 150px; */

    label {
        align-self: start;
        padding: 0 4px 2px 0;
    }

    > div {
        display: flex;
        flex-direction: row;
    }

    .react-select-container {
        align-content: 'flex-start';
    }
`;

export const Title = styled.div`
    h1 {
        font-weight: bold;
        display: flex;
        margin: 32px 0;
        color: var(--gray2);
    }
`;

export const Feed = styled.div`
    display: flex;
    flex-direction: row;
    margin: 32px 0;
    color: var(--secondary-orange);
    border: 1px solid var(--secondary-orange);
    padding: 16px;

    svg {
        margin-right: 16px;
    }
`;

export const Text = styled.pre`
    text-align: left;
    white-space: pre-line;
    margin-bottom: 32px;
    line-height: 1.2;
`;

export const Steps = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    align-content: center;
    column-gap: 64px;

    div {
        background-color: var(--secondary);
        width: 58px;
        height: 58px;
        border-radius: 50%;
        font-size: 40px;
        font-weight: 700;
        color: #fff;
        z-index: 1;

        &.active {
            background-color: var(--primary);
        }

        span {
            display: flex;
            justify-content: center;
            margin: 7px;
            user-select: none;

            // @media (max-width: 576px) {
            @media (max-width: 768px) {
                margin: 9px;
            }
        }

        // @media (max-width: 576px) {
        @media (max-width: 768px) {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            font-size: 24px;
        }
    }

    i {
        display: flex;
        position: absolute;
        align-self: center;
        height: 3px;
        width: 500px;
        /* width: -webkit-fill-available; */
        background-color: var(--secondary);
    }

    // @media (max-width: 576px) {
    @media (max-width: 768px) {
	    column-gap: 8px;
        justify-content: space-between;

        i {
            width: 100%;
        }
    }
`;