import styled from "styled-components";

export const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
`;

export const FormGroup = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;

    label {
        color: var(--labelText);
        font-size: 16px;
        text-align: left;
    }
`;

export const Input = styled.input`
    padding: 8px;
    margin-left: 10px;
    font-size: 16px;
    border: 1px solid var(--secondary);
    border-radius: 2px;
    flex-grow: 1;
`;

export const ForumPageContainer = styled.div`
    padding: 20px;
`;

export const ForumPostContainer = styled.div`
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
`;

export const CommentsContainer = styled.div`
    margin-top: 10px;
`;

export const ForumCommentContainer = styled.div`
    border-top: 1px solid #eee;
    padding: 5px;
`;

export const Text = styled.div`
    text-align: left;
    white-space: pre-line;
    margin-bottom: 32px;
    line-height: 1.2;
`;

export const Content = styled.div`
    margin-top: 2em;
    flex-grow: 1;
`;

export const TableRow = styled.div`
    padding: 10px;
    border-bottom: 1px solid var(--gray2);
    display: flex;
    justify-content: space-between;

    &:hover {
        background-color: #f2f2f2;
    }
`;

export const TableHeader = styled.div`
    background-color: var(--gray2);
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
`;

export const TableCell = styled.div`
    flex: 1;
    padding: 10px;

    &:first-child {
        flex: 4;
        text-align: left;
    }

    &:nth-child(n + 2) {
        flex: 1;
    }
`;

export const Pagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    span,
    a {
        margin: 0 5px;
        padding: 5px 10px;
        border: 1px solid #ddd;
        text-decoration: none;
    }

    a:hover {
        background-color: #f2f2f2;
    }
`;

export const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
`;

export const AuthorDate = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;

    span:first-child {
        margin-right: 20px;
    }
`;

export const TableContainer = styled.div`
    display: flex;
    justify-content: space-between;
    order: 1;
    align-self: stretch;
    flex-grow: 1;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const LeftColumn = styled.div`
    flex: 1;
    margin-top: 8em;

    @media (max-width: 768px) {
        margin-top: 2em;
    }
`;

export const RightColumn = styled.div`
    flex: 1;
`;

export const Label = styled.label`
    min-width: 100px;
    margin-right: 10px;
    text-align: right;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: start;
    column-gap: 32px;
    margin-top: 2em;
`;

export const PopupWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

// TODO: adicionar à borda: linear-gradient(180.74deg, #720E12 5.86%,
// #D81B22 99.36%);

export const PopupContainer = styled.div`
    position: absolute;
    right: 0;
    border: 4px solid var(--primary);
    background-color: ${(props) => props.theme.colors.bgPrimary};
    padding: 8px;
    line-height: 2;
    width: 240px;

    .indented {
        padding-left: 20px;
    }
`;
