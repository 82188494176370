import styled from 'styled-components';

export const Container = styled.div`
    /* display: grid;
    grid-template-columns: 117px auto;
    grid-template-rows: 70px auto;
    grid-template-areas:
    'MH MH'
    'AS CT'; */

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
    'AT'
    'MH'
    'MB';

    /* height: 100vh;
    min-width: 315px; */

    /* @media(max-width: 600px) {
        grid-template-columns: 100%;
        grid-template-rows: 70px auto;
        grid-template-areas:
        'MH'
        'CT';
    } */

    @media (max-width: 576px) {
        grid-template-areas:
        'AT' // Accessibillity Toolbar
        'MB' // Main Body
        'CM' // Contextual Menu
        'MM'; // Menu Mobile
        
        width: max-content;
    }    
`;