import api from './api';
import { IStepThree } from '../models/course/Course';

const ENDPOINT = 'curso_recursos';

export const courseResourcesService = {
    listByCourseCode,
    edit,
    // create,
    // remove,
};

async function listByCourseCode(courseCode: number): Promise<IStepThree> {
    const { data } = await api.get(`${ENDPOINT}/${courseCode}`);

    return data;
    /*return {
        id: 7,
        comunicacao: [
            {
                id: 2,
                nome: "Avisos",
                selecionada: false
            },
            {
                id: 3,
                nome: "Fórum",
                selecionada: false
            },
            {
                id: 4,
                nome: "Chat",
                selecionada: true
            },
            {
                id: 5,
                nome: "E-mail",
                selecionada: false
            }
        ],
        consulta: [
            {
                id: 2,
                nome: "Links Úteis",
                selecionada: true
            },
            {
                id: 3,
                nome: "Glossário",
                selecionada: true
            },
            {
                id: 4,
                nome: "Bibliografia",
                selecionada: true
            }
        ]
    }*/
};

async function edit(recourses: IStepThree, courseCode: number): Promise<any> {
    const { data } = await api.put<any>(`${ENDPOINT}/${courseCode}`, recourses);

    return data;
};

// async function create(recourses: ICourseRecourses, courseCode: number): Promise<any> {
//     const { data } = await api.post<any>(`${ENDPOINT}/${courseCode}`, recourses);

//     return data;
// };

// async function remove(courseCode: number /*, recoursesIds: number[],*/): Promise<any> {
//     const { data } = await api.delete<any>(`${ENDPOINT}/${courseCode}`);

//     return data;
// };