import * as yup from 'yup';

import { setLocale } from 'yup';
setLocale({
  mixed: {
    default: 'Valor inválido',
    required: 'Campo obrigatório'
  },
});

const schema = yup.object().shape({
  currentPassword: yup.string().required().label('Senha Atual'),
  newPassword: yup.string().required().label('Nova Senha'),
  confirmNewPassword: yup.string().required()
    .oneOf([yup.ref('newPassword')], 'Confirmação deve ser igual à Nova Senha')
});

export default schema;