import React from "react";
import Select from "react-select";
import { Wrapper, Label } from "./styles";
import { SelectStyles } from "../../assets/styles/SelectStyles";
import { DomainSelectOption } from "../../shared/models/domains/Domains";

interface InputSelectProps {
    label: string;
    id: string;
    name: string;
    placeholder: string;
    options: DomainSelectOption[];
}

const InputSelectWrapper: React.FC<InputSelectProps> = ({
    label,
    id,
    name,
    placeholder,
    options
}) => {
    return (
        <Wrapper>
            <Label htmlFor={id}>{label}</Label>
            <Select
                inputId={id}
                name={name}
                options={options}
                placeholder={placeholder}
                styles={SelectStyles}
            />
        </Wrapper>
    );
};

export default InputSelectWrapper;
