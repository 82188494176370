import React, { useState } from "react";
import {
    Container,
    Input,
    FormGroup,
    TableContainer,
    LeftColumn,
    RightColumn,
    Label,
    Buttons
} from "./styles";
import Breadcrumb from "../../../components/Breadcrumb";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import InputSelectWrapper from "../../../components/InputSelectWrapper";
import FileUpload from "../../../components/FileUpload";
import Editor from "../../../components/Editor";
import { DomainSelectOption } from "../../../shared/models/domains/Domains";

const NewPost: React.FC = () => {
    const [title, setTitle] = useState("");
    const [link, setLink] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLink(event.target.value);
    };

    const handleFileUpload = (files: File[]) => {
        setUploadedFiles(files);
        console.log("Uploaded files: ", files);
    };

    const courseModules: DomainSelectOption[] = [
        { value: "1", label: "A" },
        { value: "2", label: "B" },
        { value: "3", label: "C" }
    ];

    return (
        <Container>
            <Breadcrumb>
                <span>Comunicação</span>
                <span>Fórum</span>
                <span>Nova Postagem</span>
            </Breadcrumb>
            <Title>Nova Postagem</Title>

            <FormGroup>
                <Label htmlFor="post-title">Título do Tópico:</Label>
                <Input
                    id="post-title"
                    type="text"
                    value={title}
                    onChange={handleTitleChange}
                    placeholder="Digite o título do tópico"
                />
            </FormGroup>

            <FormGroup>
                <TableContainer>
                    <LeftColumn>
                        <p>Associação:</p>
                    </LeftColumn>
                    <RightColumn>
                        <InputSelectWrapper
                            label="Curso: "
                            id="course"
                            name="course"
                            placeholder="Selecione o curso..."
                            options={courseModules}
                        />
                        <InputSelectWrapper
                            label="Módulo: "
                            id="module"
                            name="module"
                            placeholder="Selecione o módulo..."
                            options={courseModules}
                        />
                        <InputSelectWrapper
                            label="Atividade: "
                            id="activity"
                            name="activity"
                            placeholder="Selecione a atividade..."
                            options={courseModules}
                        />
                        <InputSelectWrapper
                            label="Comunicação: "
                            id="communication"
                            name="communication"
                            placeholder="Selecione a comunicação..."
                            options={courseModules}
                        />
                        <FormGroup>
                            <Label htmlFor="notice">Aviso:</Label>
                            <Input
                                id="notice"
                                type="text"
                                placeholder="Aviso..."
                            />
                        </FormGroup>
                    </RightColumn>
                </TableContainer>
            </FormGroup>

            <FormGroup>
                <Label htmlFor="post-link">Link:</Label>
                <Input
                    id="post-link"
                    type="text"
                    value={link}
                    onChange={handleLinkChange}
                    placeholder="https://link.teste.ex"
                />
            </FormGroup>

          <FormGroup>
            <Editor />
          </FormGroup>

            <FormGroup>
                <FileUpload onFileUpload={handleFileUpload} />
            </FormGroup>

            <Buttons>
                <Button type="submit" btnTheme="primary">
                    Salvar
                </Button>
                <Button btnTheme="primary">Cancelar</Button>
            </Buttons>
        </Container>
    );
};

export default NewPost;
