import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    text-align: left;
`;

export const Text = styled.pre`
    text-align: left;
    white-space: pre-line;
    margin-bottom: 32px;
    line-height: 1.2;
`;

export const Form = styled.form`
    width: 100%;

    @media (max-width: 576px) {
        width: auto;
        width: 100%;
        height: unset;
    }
`;

export const QuestionContainer = styled.div`
    margin-bottom: 2em;
`;

export const Label = styled.label`
    display: block;

    span {
        font-weight: normal;
        margin-right: 0.5em;
    }
`;

export const ResponseLabel = styled.div`
    margin-top: 2em;
    margin-bottom: 0.35em;
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 2em;

    margin-left: 2em;
    > label:first-of-type {
        margin-bottom: 1.5em;
    }
`;

export const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    margin-right: 1em;

    input[type="checkbox"] {
        transform: scale(1.5);
        margin-right: 2.5em;
    }
`;

export const RadioWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 2em;
    margin-left: 2em;
`;

export const RadioLabel = styled.label`
    display: flex;
    align-items: center;
    margin-right: 1em;

    input[type="radio"] {
        transform: scale(1.5);
        margin-right: 2.5em;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 16px;
`;
