import { createContext, useContext, useState } from 'react';
import api from '../services/api';
  
  interface LoadingContext {
    loading: boolean;
    setLoading: (state: boolean) => void;
  }

  interface Props {
    children?: React.ReactNode | React.ReactNode[];
  };

  export const LoaderContext = createContext<LoadingContext>({} as LoadingContext);
  
  export const LoaderProvider: React.FC<Props> = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);
  
    api.interceptors.request.use(function (config) {
        setLoading(true);
        return config;
      }, function (error) {
        setLoading(false);
        return Promise.reject(error);
      });

    api.interceptors.response.use(function (response) {
        // 2xx
        setLoading(false);
        return response;
      }, function (error) {
        setLoading(false);
        return Promise.reject(error);
      });

    return (
      <LoaderContext.Provider
        value={{
          loading,
          setLoading,
        }}
      >
        {children}
      </LoaderContext.Provider>
    );
  };
  
  export const useLoader = () => useContext(LoaderContext);
  