import * as yup from 'yup';

import { setLocale } from 'yup';
setLocale({
  mixed: {
    default: 'Valor inválido',
    required: 'Campo obrigatório'
  },
});

const schema = yup.object().shape({
  tituloLongo: yup
    .string()
    .min(3, 'Mínimo de três caracteres')
    .max(300, 'Máximo de 300 caracteres')
    .required(),  
  titulo: yup
    .string()
    .min(3, 'Mínimo de três caracteres')
    .max(30, 'Máximo de 30 caracteres')
    .required(),
  modalidade: yup.string().required(),
  nivel: yup.string().min(0).max(9999).required().label('Nível'),
  areaConhecimento: yup.string().required(),
  inicioMatricula: yup.date().nullable().required('Campo obrigatório'),
  fimMatricula: yup.date().nullable().required('Campo obrigatório'),
  inicioCurso: yup.date().nullable().required('Campo obrigatório'),
  fimCurso: yup.date().nullable().required('Campo obrigatório'),
  numeroVagas: yup.number().max(9999, "Máximo até 9999").required().label('Número de vagas'),
  objetivo: yup.string().max(100, 'Máximo de 100 caracteres').required(),
  ementa: yup.string().max(255, 'Máximo de 255 caracteres').required(),
  orientacaoAosAlunos: yup.string().max(255, 'Máximo de 255 caracteres').required(),
  cronograma: yup.string().max(255, 'Máximo de 255 caracteres').required(),
});

export default schema;
