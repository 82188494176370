import {createContext, useContext, useState} from 'react';
import light from '../../assets/styles/themes/light';
import highContrast from '../../assets/styles/themes/highContrast';
import { DefaultTheme } from 'styled-components';

interface Context {
    theme: DefaultTheme;
    fontSize: number;
    toggleHighContrast(): void;
    increaseFontSize(): void;
    decreaseFontSize(): void;
    resetFontSize(): void;
}

interface Props {
    children?: React.ReactNode | React.ReactNode[];
};

export const AccessibilityContext = createContext<Context>({} as Context);

export const AccessibilityProvider: React.FC<Props> = ({ children }) => {
    const [theme, setTheme] = useState<DefaultTheme>(() => {
        const themeSaved = localStorage.getItem('@constructore-front:theme');
        if(themeSaved) {
            return JSON.parse(themeSaved);
        }
        else {
            return light;
        }
    });
    const [fontSize, setFontSize] = useState<number>(() => {
        const fontSizeSaved = localStorage.getItem('@constructore-front:fontsize');
        if(fontSizeSaved) {
            return JSON.parse(fontSizeSaved);
        }
        else {
            return 1;
        }
    });    
    // const [activeHighContrast, setActiveHighContrast] = useState<boolean>(false);
    // const { getStorageAuhtenticatedUser } = useAuth();

    const toggleHighContrast = () => {
        if(theme.title === 'highContrast') {
            setTheme(light);
            localStorage.setItem('@constructore-front:theme', JSON.stringify(light));
        }
        else {
            setTheme(highContrast);
            localStorage.setItem('@constructore-front:theme', JSON.stringify(highContrast));
        }
    }

    const increaseFontSize = () => {
        if(fontSize < 1.1) {
            const newSize = Number((fontSize + 0.05).toFixed(2));
            setFontSize(newSize);
            localStorage.setItem('@constructore-front:fontsize', JSON.stringify(newSize));
        }
    }

    const decreaseFontSize = () => {
        if(fontSize > 1) {
            const newSize = Number((fontSize - 0.05).toFixed(2));
            setFontSize(newSize);
            localStorage.setItem('@constructore-front:fontsize', JSON.stringify(newSize));
        }
    }    

    const resetFontSize = () => {
        setFontSize(1);
        localStorage.setItem('@constructore-front:fontsize', JSON.stringify(1));
    }

    return (
        <AccessibilityContext.Provider
            value={{
                theme,
                fontSize,
                toggleHighContrast,
                increaseFontSize,
                decreaseFontSize,
                resetFontSize,
            }}
            >
            {children}
        </AccessibilityContext.Provider>
    );
};

export const useAccessibility = () => useContext(AccessibilityContext);