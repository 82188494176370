import {createContext, useContext, useState} from 'react';
import {
    CourseModuleType,
    DomainOption,
    DomainRegion,
    DomainSelectOption,
    KnowledgeArea,
    Level
} from '../../models/domains/Domains';
import { dropdownService } from '../../services/domains/dropdownService';


interface Context {
    regions: DomainRegion[],
    // ufs: DomainSelectOption[];
    cities: DomainSelectOption[];
    filteredCities: any[];
    knowledgeAreas: KnowledgeArea[];
    levels: Level[];
    courseModuleTypes: CourseModuleType[];
    courseModalities: DomainOption[];
    fetchRegions: () => void;
    fetchCities: () => void;
    fetchCitiesByUFId: (ufId: string) => void;
    // fetchUFs: () => void;
    fetchKnowledgeAreas: () => void;
    fetchLevels: () => void;
    fetchCourseModuleTypes: () => void;
    fetchCourseModalities: () => void;
}

interface Props {
    children?: React.ReactNode | React.ReactNode[];
};

export const DropdownContext = createContext<Context>({} as Context);

export const DropdownProvider: React.FC<Props> = ({  children }) => {
    const [regions, setRegions] = useState<DomainRegion[]>([]);
    // const [ufs, setUfs] = useState<any|undefined>(undefined);
    const [cities, setCities] = useState<DomainSelectOption[]>([]);
    const [filteredCities, setFilteredCities] = useState<any[]>([]);
    const [knowledgeAreas, setKnowledgeAreas] = useState<KnowledgeArea[]>([]);
    const [levels, setLevels] = useState<Level[]>([]);
    const [courseModuleTypes, setCourseModuleTypes] = useState<CourseModuleType[]>([]);
    const [courseModalities, setCourseModalities] = useState<DomainOption[]>([]);

    const fetchRegions = async () => {
        try {
            const response: DomainRegion[] = await dropdownService.listRegions();
            setRegions(response);

            // const response: DomainRegion[] = await dropdownService.listRegions();
            // console.log(response);
            // const ufs: DomainSelectOption[] = response.map(x => {
            //     return {
            //         value: x.codigo.toString(),
            //         label: x.sigla,
            //     }
            // });

            // // const cities: any = response.map((region: DomainRegion) => {
            // //     const cities = region.cidades;
            // //     const newCities = region.cidades.map((city: DomainCity) => {
            // //         return {
            // //             value: city.codigo.toString(),
            // //             label: city.nome,
            // //         }
            // //     })
            // //     return [...cities, ...newCities];
            // // });

            // let cities: DomainSelectOption[] = [];
            // for(let i = 0 ; i < response.length ; i++) {
            //     const c = response[i].cidades.map(x => {
            //         return {
            //             value: x.codigo.toString(),
            //             label: x.nome,
            //         }
            //     });
            //     cities = cities.concat(c);
            // }
            
            // setUfs(ufs);
            // setCities(cities);

        } catch (error) {
            console.log("listRegions error: ", error);
        }
    }

    const fetchCities = async () => {
        try {
            const response: any[] = await dropdownService.listCities();
            const cities: DomainSelectOption[] = response.map(x => {
                return {
                    value: x.codigo.toString(),
                    label: x.nome,
                }
            });
            setCities(cities);
        } catch (error) {
            console.log("listCities error: ", error);
        }
    }

    const fetchCitiesByUFId = async (ufId: string) => {
        try {
            const response: any = await dropdownService.getUFWhithCities(ufId);
            const cities: DomainSelectOption[] = response.cidades.map((x:any) => {
                return {
                    value: x.codigo.toString(),
                    label: x.nome,
                }
            });
            setFilteredCities(cities);
        } catch (error) {
            console.log("fetchCitiesByUFId error: ", error);
        }
    }

    // const fetchUFs = async () => {
    //     try {
    //         // const response: any[] = await dropdownService.listUFs();
    //         // const ufs: DomainSelectOption[] = response.map(x => {
    //         //     return {
    //         //         value: x.codigo.toString(),
    //         //         label: x.sigla,
    //         //     }
    //         // });
    //         // setUfs(ufs);

    //         const ufs: DomainSelectOption[] = regions.map(x => {
    //             return {
    //                 value: x.codigo.toString(),
    //                 label: x.descricao,
    //             }
    //         });
    //         setUfs(ufs);
    //     } catch (error) {
    //         console.log("listUFs error: ", error);
    //     }
    // }    

    const fetchKnowledgeAreas = async () => {
        try {
            const response: KnowledgeArea[] = await dropdownService.listKnowledgeAreas();
            setKnowledgeAreas(response);
        } catch (error) {
            console.log("fetchKnowledgeArea error: ", error);
        }
    }

    const fetchLevels = async () => {
        try {
            const response: Level[] = await dropdownService.listLevels();
            setLevels(response);
        } catch (error) {
            console.log("fetchLevels error: ", error);
        }
    }

    const fetchCourseModuleTypes = async () => {
        try {
            const response: CourseModuleType[] = await dropdownService
                .listCourseModuleTypes();
            setCourseModuleTypes(response);
        } catch (error) {
            console.log("fetchCourseModuleTypes error: ", error);
        }
    }

    const fetchCourseModalities = async () => {
        try {
            const response: DomainOption[] = await dropdownService
                .listCourseModalities();
            setCourseModalities(response);
        } catch (error) {
            console.log("fetchCourseModality error: ", error);
        }
    }

    return (
        <DropdownContext.Provider
            value={{
                regions: regions,
                // ufs: ufs,
                cities: cities,
                filteredCities: filteredCities,
                knowledgeAreas,
                levels,
                courseModuleTypes,
                courseModalities,
                fetchRegions: fetchRegions,
                fetchCities: fetchCities,
                fetchCitiesByUFId: fetchCitiesByUFId,
                // fetchUFs: fetchUFs,
                fetchKnowledgeAreas,
                fetchLevels,
                fetchCourseModuleTypes,
                fetchCourseModalities,
            }}
            >
            {children}
        </DropdownContext.Provider>
    );
};

export const useDropdown = () => useContext(DropdownContext);