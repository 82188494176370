import { confirmAlert } from 'react-confirm-alert';
import Button from '../Button';
import { Buttons, Dialog } from "./styles";

interface Props {
    text: string;
    title?: string;
    onClickConfirm?: () => void;
    bgColor?: string;
}

const dialogConfirm: React.FC<Props> = ({
    text,
    title = '',
    onClickConfirm,
    bgColor = "#fff"
}) => (
    <>
        {confirmAlert({
            overlayClassName: 'overlay',
            customUI: ({ onClose }) => {
                return (
                    <Dialog bgColor={bgColor}>
                        <h1>{title}</h1>
                        <p>{text}</p>
                        <Buttons>
                            <Button
                                btnTheme='primary'
                                onClick={() => {
                                    onClickConfirm && onClickConfirm();
                                    onClose();
                                }}
                            >
                                Sim
                            </Button>
                            <Button
                                btnTheme='primary'
                                onClick={onClose}
                                autoFocus
                            >
                                Não
                            </Button>
                        </Buttons>
                    </Dialog>
                );
            }
        })}
    </>
);

export default dialogConfirm;