import styled from 'styled-components'

export const Container = styled.div`
    grid-area: MM; // Menu Mobile

    border-width: 2px;
    border-color: #828282;
    border-top-style: solid;
    position: relative;
    /* background: red; */

    svg {
        color: ${props => props.theme.colors.placeholderSearchText};
    }    

    @media (min-width: 577px) {
        display: none;
    }
`;

export const OptionsWrap = styled.div`
    > li {
        color: ${props => props.theme.colors.labelText};
    }

    &.closed {
        transition: height 0.3s ease;
        height: 0;
        overflow: hidden;

        > ul {
            overflow-y: hidden;
            height: 0;
            padding: 0;

            > li {
                a,
                .option-parent {
                    div {
                        justify-content: center;
                    }
                }

                svg {
                    height: 54px;
                    width: 40px;
                    transition: width 0.3s ease;
                }

                span {
                    display: none;
                }
            }
        }
    }
    &.open {
        transition: height 0.3s ease;

        > ul {
            padding: 16px 7px;
            
            > li {
                padding-left: 8px;

                svg {
                    height: 54px;
                    width: 20px;
                    transition: width 0.3s ease;
                }

                span {
                    display: unset;
                }                
            }
        }

        @media (max-width: 576px) {
            position: fixed;
            bottom: 0;
            bottom: 70px;
            width: 100%;
            height: calc(100vh - 300px);
            background-color: ${props => props.theme.colors.bgPrimary};
            overflow-y: scroll;

            border-width: 2px;
            border-color: #828282;
            border-top-style: solid;                
        }
    }
`;

export const Puller = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 32px;
    height: 32px;
    right: 16px;
    bottom: 16px;

    svg {
        font-size: 40px;
        color: ${props => props.theme.colors.labelText};
    }
`;

export const LinkBrand = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 32px;
    height: 32px;
    left: 16px;
    bottom: 16px;

    img {
        height: 32px;
        width: 32px;
    }
`;

export const NavButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 32px;
    height: 32px;
    bottom: 16px;

    svg {
        font-size: 32px;
        color: ${props => props.theme.colors.labelText};
    }
`;

export const Options = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    row-gap: 16px;    

    > li {
        display: flex;
        flex-direction: column;
        align-self: center;
        min-width: 100%;
        width: 100%;

        &:hover {
            cursor: pointer;
        }

        a,
        .option-parent,
        button {
            color: ${props => props.theme.colors.menuElements} !important;
            transition: 0.5s;
            width: 100%;
            text-decoration: none;

            &:hover {
                color: #986769 !important;
                transition: 0.5s;
            }

            > div {
                display: flex;
                flex-direction: row;
                width: 100%;
                column-gap: 8px;

                span {
                    white-space: pre;
                    align-self: center;
                }
            }
        }

        button {
            display: flex;
            align-items: center;
            height: 54px;
        }

        > ul {
            display: flex;
            flex-direction: column;

            &.closed {
                height: 0;
                overflow: hidden;           
            }

            > li {
                text-align: left;
                padding-left: 8px;
                height: 54px;
                display: flex;
                align-items: center;

                a {
                    text-decoration: none;
                }
            }
        }
    }

    .active {
        background-color: ${props => props.theme.colors.bgMenuElements};
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    align-items: center;
    height: 70px;
    /* background: green; */
`;