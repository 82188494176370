import styled from 'styled-components';

export const Container = styled.div`
    grid-area: MB; // Main Body

    display: grid;
    /* grid-template-columns: 117px auto; */
    grid-template-columns: max-content auto;

    /* &.closed {
        grid-template-columns: 117px auto;
        transition: width 0.6s ease;
    }
    &.open {
        grid-template-columns: 196px auto;
        transition: width 0.6s ease;
    } */

    grid-template-rows: auto;
    grid-template-areas: 'AS CT';

    // height: calc(100vh - 154px);
    // height: calc(100vh - 171px);
    height: calc(100vh - (154px + 32px + 2px));
    min-width: 315px;
    /* transition: width 0.6s ease; */

    @media (max-width: 576px) {
        grid-template-areas:
        'CT';
        /* grid-template-areas:
        'CT'
        'MM'; */
        height: unset;
        height: auto; // tentativa de corrigir rolagem no celular (Chrome e Edge)
    }    
`;