import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';

type Props = {
    children?: React.ReactNode | React.ReactNode[];
};

const Breadcrumb: React.FC<Props> = ({ children }) => (
    <Container>
        <span>
            <Link to='/' aria-label="Minha Constructore (Migalha de pão)">
                Minha Constructore
            </Link>
        </span>
        { children }
    </Container>
);

export default Breadcrumb;