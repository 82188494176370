import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../shared/contexts/UserContext";
import { useAuth } from "../../shared/contexts/Auth";
import { useCourse } from "../../shared/contexts/CourseContext";
import { useMenu } from "../../shared/contexts/MenuContext";
// import { useDropdown } from "../../shared/contexts/domains/DropdownContext";
import { useLoader } from "../../shared/contexts/LoadingContext";
import Title from "../../components/Title";
import Breadcrumb from "../../components/Breadcrumb";
import SpinnerScreen from "../../components/SpinnerScreen";
import Table from "../../components/Table";
import { ICourse } from "../../shared/models/course/Course";
import { DomainSelectOption } from "../../shared/models/domains/Domains";
import { dateFormatter } from "../../shared/helpers/dates";
import { MODALITY_COURSE, KNOWLEDGE_AREA_COURSE } from "../../shared/consts";
import { Container, Content, Empty } from "./styles";

const MyCourses: React.FC = () => {
    const { checkCompleteRegistration } = useUser();
    const { courses, fetchCourses, resetCourses } = useCourse();
    const { trackMenuOption } = useMenu();
    // const { knowledgeAreas } = useDropdown();
    const { getStorageAuhtenticatedUser, hasPermission } = useAuth();
    const { loading } = useLoader();

    const readOrEdit = hasPermission() ? 'edit' : 'read';

    const columns = [
        {
            name: 'Curso',
            selector: (row: ICourse) => row.titulo,
        },
        {
            name: 'Início',
            width: '130px',
            selector: (row: ICourse) => row.inicioCurso,
        },
        {
            name: 'Função',
            width: '130px',
            selector: (row: ICourse) => row.modalidade,
        },
    ];

    // const getKnowledgeAreaName = (code: string) => {
    //     return knowledgeAreas.find((x:KnowledgeArea) => x.codigo === Number(code))?.descricao;
    // }

    const getModalityName = (id: string) => {
        return MODALITY_COURSE.find((x:DomainSelectOption) => x.value === id)?.label
    }

    const getKnowledgeAreaName = (id: string) => {
        return KNOWLEDGE_AREA_COURSE.find((x:DomainSelectOption) => x.value === id)?.label
    }

    useEffect(() => {
        checkCompleteRegistration();
    },[checkCompleteRegistration]);

    useEffect(() => {
        trackMenuOption(1);
    }, [trackMenuOption]);

    useEffect(() => {
        fetchCourses();
    },[]);

    useEffect(() => {
        return () => resetCourses()
    }, []);

    if(loading || !courses) {
        return <SpinnerScreen />
    }

    if(courses && courses?.length > 0) {
        return (
            <Container>
                <Breadcrumb>
                    <span>Meus cursos</span>
                </Breadcrumb>

                <Content>
                    <article>
                        <h2>Meus Cursos</h2>

                        <section>
                            <h3>Em Andamento</h3>
                            <Table
                                columns={[
                                    {
                                        name: 'Área',
                                        width: '170px',
                                        selector: (row: ICourse) => row.areaConhecimento,
                                    },
                                    {
                                        name: 'Curso',
                                        selector: (row: ICourse) => row.titulo,
                                    },
                                    {
                                        name: 'Modalidade',
                                        width: '130px',
                                        selector: (row: ICourse) => row.modalidade,
                                    },
                                    {
                                        name: 'Status',
                                        width: '130px',
                                        selector: (row: ICourse) => row.cursoAtualizado,
                                    },
                                ]}
                                data={courses?.slice(0,6).sort((a:ICourse, b:ICourse) => { // exibe apenas seis itens
                                        return a?.titulo < b?.titulo ? -1 : a?.titulo > b?.titulo ? 1 : 0;
                                    }).map((course:ICourse) => {
                                        return ({
                                            areaConhecimento: getKnowledgeAreaName(course.areaConhecimento),
                                            titulo: (
                                                <p title={course.titulo}>
                                                    <Link to={`/courseenvironment/${course.codigo}`}>
                                                        {course.titulo}
                                                    </Link>
                                                </p>
                                            ),
                                            modalidade: getModalityName(course.modalidade),
                                            cursoAtualizado: course?.cursoAtualizado ? "Atualizado" : ""
                                        })
                                    }) || []
                                }
                            />
                            {/* <List>
                                {
                                    courses?.slice(0,6).sort((a:any, b:any) => {
                                        return a?.titulo < b?.titulo ? -1 : a?.titulo > b?.titulo ? 1 : 0;
                                    }).map((course:ICourse) => {
                                        return (
                                            <div key={course.codigo}>
                                                <span className="knowledge-area"><p>{getKnowledgeAreaName(course.areaConhecimento)}</p></span>
                                                <span className="name">
                                                    <p><Link to={`/course/edit/${course?.codigo}`}>{course?.titulo}</Link></p>
                                                </span>
                                                <span className="course-modality"><p>{getModalityName(course.modalidade)}</p></span>
                                                <span><p>{course?.cursoAtualizado ? "Atualizado" : ""}</p></span>
                                            </div>
                                        )
                                    })
                                }
                            </List> */}
                        </section>

                        <section>
                            <h3>Em Desenvolvimento</h3>
                            <Table
                                columns={columns}
                                data={courses?.slice(0,4).sort((a:ICourse, b:ICourse) => { // exibe apenas quatro itens
                                        return a?.titulo < b?.titulo ? -1 : a?.titulo > b?.titulo ? 1 : 0;
                                    }).map((course:ICourse) => {
                                        return ({
                                            titulo: (
                                                <p title={course.titulo}>
                                                    <Link to={`/course/${readOrEdit}/${course.codigo}`}>
                                                        {course.titulo}
                                                    </Link>
                                                </p>
                                            ),
                                            inicioCurso: dateFormatter(course?.inicioCurso),
                                            funcao: ""
                                        })
                                    }) || []
                                }
                            />
                            {/* <List>
                                {
                                    courses?.slice(0,4).sort((a:any, b:any) => {
                                        return a?.titulo < b?.titulo ? -1 : a?.titulo > b?.titulo ? 1 : 0;
                                    }).map((course:ICourse) => { // exibir só 4 itens
                                        return (
                                            <div key={course.codigo}>
                                                <span className="name">
                                                    <p><Link to={`/course/edit/${course?.codigo}`}>{course?.titulo}</Link></p>
                                                </span>
                                                <span className="course-date">
                                                    <p>{ dateFormatter(course?.cursoLiberacao?.dataLiberacao) }</p>
                                                </span>
                                                <span className="target-audience"><p>{course?.publicoAlvo}</p></span>
                                            </div>
                                        )
                                    })
                                }
                            </List> */}
                        </section>
                    
                        <section>
                            <h3>Concluídos</h3>
                            <Table
                                columns={columns}
                                data={courses?.sort((a:ICourse, b:ICourse) => {
                                        return a?.titulo < b?.titulo ? -1 : a?.titulo > b?.titulo ? 1 : 0;
                                    }).map((course:ICourse) => {
                                        return ({
                                            titulo: (
                                                <p title={course.titulo}>
                                                    <Link to={`/courseenvironment/${course.codigo}`}>
                                                        {course.titulo}
                                                    </Link>
                                                </p>
                                            ),
                                            inicioCurso: dateFormatter(course?.inicioCurso),
                                            funcao: ""
                                        })
                                    }) || []
                                }
                            />
                            {/* <List>
                                {
                                    courses?.sort((a:any, b:any) => {
                                        return a?.titulo < b?.titulo ? -1 : a?.titulo > b?.titulo ? 1 : 0;
                                    }).map((course:ICourse) => {
                                        return (
                                            <div key={course.codigo}>
                                                <span className="knowledge-area"><p>{getKnowledgeAreaName(course.areaConhecimento)}</p></span>
                                                <span className="name">
                                                    <p><Link to={`/course/edit/${course?.codigo}`}>{course?.titulo}</Link></p>
                                                </span>
                                                <span className="course-modality"><p>{getModalityName(course.modalidade)}</p></span>
                                                <span><p>{course?.cursoAtualizado ? "Atualizado" : ""}</p></span>
                                            </div>
                                        )
                                    })
                                }
                            </List> */}
                        </section>
                    </article>

                    {/* <aside>
                        <h2>Agenda</h2>
                    </aside> */}
                </Content>
            </Container>
        );
    }

    return (
        <>
            <Container>
                <Breadcrumb>
                    <span>Meus cursos</span>
                </Breadcrumb>
            </Container>
            <Empty>
                <div>
                    <Title>Seu ambiente está em branco</Title>
                    {getStorageAuhtenticatedUser()?.tipoVinculo === "0" && (
                        <p>Você ainda não está inscrito em nenhum curso. Para se inscrever, realize a busca no campo superior, por título ou nome do professor.</p>
                    )}
                    {getStorageAuhtenticatedUser()?.tipoVinculo === "1" && (
                        <p>Você ainda não possui cursos cadastrados. Clique em "Criar Curso" no menu de navegação.</p>
                    )}
                </div>
            </Empty>
        </>
    )
}
export default MyCourses;
