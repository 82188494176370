import styled from "styled-components";

export const Container = styled.div`
    width: 100%;

    .ql-editor {
        min-height: 250px;
        font-size: 16px;
        border: none;
        border-radius: 0px 0px 2px 2px;
    }

    .ql-toolbar {
        text-align: left;
    }

    .ql-editor strong {
        font-weight: bold;
    }

    .ql-editor em {
        font-style: italic;
`;
