import styled from "styled-components";

export const Container = styled.div`
    grid-area: AT; // Accessibility Toolbar

    /* display: flex; */
    /* justify-content: space-between; */
    background-color: ${props => props.theme.colors.bgAccessibilityToolbar};
    height: 32px;
    padding: 0 16px;

    a {
        color: ${props => props.theme.colors.white} !important;
    }

    > div {
        {
            color: ${props => props.theme.colors.white};
        }
    }

    > div.left {
        display: flex;
        align-self: center;
        column-gap: 8px;
        margin-top: 10px;
        float: left;
        font-size: 12px;

        @media (max-width: 992px) {
            display: none;
        }        
    }

    > div.right {
        display: flex;
        align-self: center;
        justify-content: center;
        flex-wrap: nowrap;
        place-content: space-around;
        float: right;
        gap: 24px;
        margin-top: 2px;

        button {
            display: flex;
            place-items: center;
            justify-content: center;
            /* width: 24px; */
            height: 27px;
            color: ${props => props.theme.colors.white};
            font-size: 22px;
        }

        span {
            display: flex;
            gap: 16px;
            border-radius: 2px;

            @media (max-width: 992px) {
                display: none;
            }

            button {
                font-weight: bold;
                font-size: 24px;

                /* &:hover {
                    background-color: ${props => props.theme.colors.secondary};
                    color: ${props => props.theme.colors.labelText};
                } */
            }
        }
    }
`;