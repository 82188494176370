import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
`;

export const Label = styled.label`
    min-width: 100px;
    margin-right: 20px;
    text-align: right;
`;
