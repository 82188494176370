import { DomainSelectOption } from "./models/domains/Domains";

export const LINK_TYPE: DomainSelectOption[] = [
    { value: '0', label: 'Aluno' },
    { value: '1', label: 'Funcionário (Docente ou Técnico)' },
];

export const MODALITY_COURSE: DomainSelectOption[] = [
    { value: '0', label: 'Presencial' },
    { value: '1', label: 'Online' },
    { value: '2', label: 'Semipresencial' },
];

export const TYPE_QUESTION: DomainSelectOption[] = [
    { value: '0', label: 'Discursiva' },
    { value: '1', label: 'Alternativas Sim e Não' },
    { value: '2', label: 'Múltipla escolha de seleção única' },
    { value: '3', label: 'Múltipla escolha de múltipla seleção' },
];

export const KNOWLEDGE_AREA_COURSE: DomainSelectOption[] = [
    { value: '1', label: 'Ciências Exatas e da Terra' },
    { value: '2', label: 'Ciências Biológicas' },
    { value: '3', label: 'Engenharias' },
    { value: '4', label: 'Ciências da Saúde' },
    { value: '5', label: 'Ciências Agrárias' },
    { value: '6', label: 'Linguística, Letras e Artes' },
    { value: '7', label: 'Ciências Sociais Aplicadas' },
    { value: '8', label: 'Ciências Humanas' },
];

// export const COURSE_MODULE_TYPES: DomainOption[] = [
//     { value: 1, label: 'Aula' },
//     { value: 2, label: 'Módulo' },
//     { value: 3, label: 'Sessão' },
//     { value: 4, label: 'Outros' },
// ];

// export const MOCKUP_DEFAULT_QUESTIONS: Question[] = [
//     {
//         enunciado: "Quais as suas expectativas em relação ao curso?",
//         id: 1,
//         nome: "",
//         selecionada: false,
//         tipo: "0",
//         valorTipoDiscursiva: "",
//         perguntaPadrao: true,
//     },
//     {   enunciado: "Possui computador em casa?",
//         id: 2,
//         nome: "",
//         selecionada: false,
//         tipo: "0",
//         valorTipoDiscursiva: "",
//         perguntaPadrao: true,
//     },
//     {   enunciado: "Possui acesso a internet em casa?",
//         id: 3,
//         nome: "",
//         selecionada: false,
//         tipo: "0",
//         valorTipoDiscursiva: "",
//         perguntaPadrao: true,
//     },
//     {   enunciado: "Com que frequência você costuma utilizar o computador e a internet?",
//         id: 4,
//         nome: "",
//         selecionada: false,
//         tipo: "0",
//         valorTipoDiscursiva: "",
//         perguntaPadrao: true,
//     },                
// ];