import Breadcrumb from "../../components/Breadcrumb";
import Title from "../../components/Title";
import { Container } from "./styles";

const Accessibility: React.FC = () => {

    return (
        <Container>
            <Breadcrumb>
                <span>Acessibilidade</span>
            </Breadcrumb>
            <Title>Acessibilidade</Title>
            <section>
                <p>Este portal segue as diretrizes do e-MAG (Modelo de Acessibilidade em Governo Eletrônico), conforme as normas do Governo Federal, em obediência ao Decreto 5.296, de 2.12.2004.</p>
                <p>O termo acessibilidade significa incluir a pessoa com deficiência na participação de atividades como o uso de produtos, serviços e informações. Alguns exemplos são os prédios com rampas de acesso para cadeira de rodas e banheiros adaptados para deficientes.</p>
                <p>Na internet, acessibilidade refere-se principalmente às recomendações do WCAG (World Content Accessibility Guide) do W3C e no caso do Governo Brasileiro ao e-MAG (Modelo de Acessibilidade em Governo Eletrônico). O e-MAG está alinhado às recomendações internacionais, mas estabelece padrões de comportamento acessível para sites governamentais.</p>
                <p>Na parte superior do portal existe uma barra de acessibilidade onde se encontra atalhos de navegação padronizados e a opção para alterar o contraste. Essas ferramentas estão disponíveis em todas as páginas do portal.</p>
            </section>
        </Container>
    );
}
export default Accessibility;
