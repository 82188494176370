import styled from 'styled-components'

export const Container = styled.div`
    grid-area: CM; // Contextual Menu

    position: relative;
    height: 0;

    @media (min-width: 576px) {
        display: none;
    }

    > .cm-list {
        position: absolute;
        display: flex;
        justify-content: center;
        width: 100%;
        bottom: 0;
        border-top: 1px solid #828282;
        background: #FFF;

        &.cm-close {
            height: 0;
            transition: height 0.3s ease;
        }
    
        &.cm-open {
            height: 300px;
            min-height: 100px;
            transition: height 0.3s ease;
        }
    
        .cm-puller {
            display: flex;
            justify-content: center;
            align-items: center;            
            height: 24px;
            width: 175px;
            margin: -25px;
            position: absolute;
            background: #FFF;
            gap: 8px;

            /* outline: 1px solid #828282; */
            border-top: 1px solid #828282;
            border-left: 1px solid #828282;
            border-right: 1px solid #828282;            
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-size: 11px;

            svg {
                font-size: 16px;
                color: ${props => props.theme.colors.mobilePullerSecondaryMenuIcon};
            }

            span {
                color: ${props => props.theme.colors.mobilePullerSecondaryMenuLabel};
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            width: 100%;
            text-align: start;
            overflow: hidden;
            gap: 16px;

            li {
                height: 50px;
                width: 100%;
                text-align: center;

                a {
                    text-decoration: none;
                    color: #000;

                    span {
                        display: flex;
                        justify-content: center;
                        height: 100%;
                        align-items: center;
                    }
                }

                button {
                    width: 100%;
                    height: inherit;
                }

                * {
                    font-size: 16px;
                }
            }
        }
    }
`;