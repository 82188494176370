import {createContext, useContext, useState} from 'react';
import { toast } from 'react-toastify';
import {
    ICourse,
    IStepOne,
    IStepTwo,
    IStepThree,
    IStepFour,
    IStepFive,
    ICourseRecourses,
} from '../models/course/Course';
import { Question } from '../models/course/Question';
import { courseService } from '../services/courseService';
import { questionService } from '../services/courseQuestionService';
import { questionAssociationService } from '../services/courseQuestionAssociationService';
import { courseResourcesService } from '../services/courseResourcesService';
import { Module } from '../models/course/Module';
import { courseModuleService } from '../services/courseModuleService';
import { courseReleasedAssociationService } from '../services/courseReleasedAssociationService';
import dialogConfirm from '../../components/ConfirmAlert';
import { useAccessibility } from './AccessibilityContext';
import { useDropdown } from './domains/DropdownContext';

interface Context {
    courses: ICourse[] | undefined;
    courseEditing: ICourse|undefined;
    questions: Question[];
    selectedQuestionsInCurrentCourse: Question[];
    currentCourseId: number;
    resources: IStepThree;
    dataStepFour: IStepFour;
    dataStepFive: IStepFive | null;

    loadingModulesList: boolean;
    loadingFetch: boolean;

    fetchCourses: () => void;
    resetCourses: () => void;
    getCourseById: (courseId: number) => void;
    saveStepOne: (course: IStepOne, callback?: () => void) => void;
    editStepOne: (course: IStepOne, courseCode: number, callback?: () => void) => void;
    resetCourseEditing: () => void;

    fetchQuestionsFromCurrentUser: () => void;
    fetchQuestionsByCourseId: (courseId: number) => void;
    saveStepTwo: (questionsId: IStepTwo, courseId: number, callback?: () => void) => void;
    editStepTwo: (questionsId: IStepTwo, courseCode: number, callback?: () => void) => void;
    resetQuestions: () => void;
    createQuestionForCurrentUserCourses: (question: Question, /*courseId: number,*/ callback?: () => void) => void;

    fetchStepThree: (courseCode: number) => void;
    saveStepThree: (data: IStepThree, courseCode: number, callback?: () => void) => void;

    fetchStepFour: (courseCode: number) => void;
    editStepFour: (module: IStepFour, courseCode: number, callback?: () => void) => void;
    createModule: (module: Module, courseCode: number, callback?: () => void) => void;
    editModule: (module: Module, courseCode: number, callback?: () => void) => void;
    removeModules: (courseCode: number, moduleIds: number[], callback?: () => void) => void;

    fetchStepFive: (courseCode: number) => void;
    editStepFive: (data: IStepFive, courseCode: number, callback?: () => void) => void;
}

interface Props {
    children?: React.ReactNode | React.ReactNode[];
};

export const CourseContext = createContext<Context>({} as Context);

export const CourseProvider: React.FC<Props> = ({  children }) => {
    const { theme } = useAccessibility();
    const { courseModuleTypes } = useDropdown();
    const [courses, setCourses] = useState<ICourse[]|undefined>(undefined);
    const [courseEditing, setCourseEditing] = useState<ICourse|undefined>(undefined);
    const [questions, setQuestions] = useState<Question[]>([]);
    const [selectedQuestionsInCurrentCourse, setSelectedQuestionsInCurrentCourse] = useState<Question[]>([]);
    const [currentCourseId, setCurrentCourseId] = useState<number>(0);
    const [resources, setResources] = useState<IStepThree>({
        id: 0,
        comunicacao: [],
        consulta: []
    });
    const [dataStepFour, setDataStepFour] = useState<IStepFour>({
        idTipoDeModuloSelecionado: -1,
        modulos: []
    });
    const [dataStepFive, setDataStepFive] = useState<IStepFive|null>(null);
    const [ loadingModulesList, setLoadingModulesList ] = useState<boolean>(false);
    const [ loadingFetch, setLoadingFetch ] = useState<boolean>(false);

    // const navigate = useNavigate();

    // #region - Passo 1
    const fetchCourses = async () => {
        try {
            const response: ICourse[] = await courseService.list();
            setCourses(response);
        } catch (error: any) {
            toast.error(
                error?.response?.data?.message
                    ? error.response.data.message
                    : 'Erro inesperado.'
            );
            console.log(error);
        }
    }
    const resetCourses = () => {
        setCourses(undefined);
    }
    const getCourseById = async (courseId: number) => {
        // console.log("Código do curso: ", courseId);
        setLoadingFetch(true);
        try {
            const response: ICourse = await courseService
                .getCourseById(courseId)
                .finally(() => setLoadingFetch(false));
            setCourseEditing(response);
        } catch (error: any) {
            toast.error(
                error?.response?.data?.message
                    ? error.response.data.message
                    : 'Erro inesperado.'
            );
            console.log(error);
        }
    }
    const saveStepOne = async (course: IStepOne, callback?: () => void) => {
        try {
            const response: any = await courseService.create(course);
            if(response) {
                console.log('response: ', response);
                // toast.success(response.message);
                toast.success("Passo 1 salvo. Apresentação do Curso criada.");
                // navigate('/');
                setCurrentCourseId(response?.codigo);
                callback && callback();
            }
            
        } catch (error: any) {
            toast.error(
                error?.response?.data?.message
                    ? error.response.data.message
                    : 'Erro inesperado.'
            );
            console.log(error);
        }
    }
    const editStepOne = async (course: IStepOne, courseCode: number, callback?: () => void) => {
        try {
            const response: any = await courseService.edit(course, courseCode)
            if(response) {
                toast.success("Passo 1 salvo. Apresentação do curso editada com sucesso.");
                callback && callback();
            }
            
        } catch (error: any) {
            toast.error(
                error?.response?.data?.message
                    ? error.response.data.message
                    : 'Erro inesperado.'
            );
            console.log(error);
        }
    }
    const resetCourseEditing = () => {
        setCourseEditing(undefined);
    }
    //#endregion - Passo 1


    // #region - Passo 2
    const fetchQuestionsFromCurrentUser = async () => {
        try {
            const response: Question[] = await questionService
                .getQuestionsFromCurrentUser();
            setQuestions(response);
            // console.log("Atualizou lista de todas as Perguntas do usuário logado");
        } catch (error) {
            toast.error('Erro inesperado.');
        }
    }    
    const fetchQuestionsByCourseId = async (courseId: number) => {
        try {
            const response: Question[] = await questionAssociationService
                .getQuestionsByCourseId(courseId);
            setSelectedQuestionsInCurrentCourse(response);
            // console.log("Atualizou lista de Perguntas selecionadas");
        } catch (error) {
            toast.error('Erro inesperado.');
        }
    }
    const createQuestionForCurrentUserCourses = async (question: Question, /*courseId: number,*/ callback?: () => void) => {
        try {
            const response: any = await questionService.createQuestion(question);
            if(response) {
                // console.log('response: ', response);
                // toast.success(response.message);
                toast.success("Nova pergunta salva com sucesso.");
                // navigate('/');
                callback && callback();
                // fetchQuestionsByCourseId(courseId);
            }
            // toast.success("TESTE: Nova pergunta salva com sucesso.");
            // fetchQuestionsByCourseId(courseId);
        } catch (error) {
            toast.error('Erro ao salvar pergunta.');
            console.log(error);
        }
    }    
    const saveStepTwo = async (questionsId: IStepTwo, courseId: number, callback?: () => void) => {
        try {
            const response: any = await questionAssociationService.createStepTwo(questionsId, courseId);
            if(response) {
                console.log('response: ', response);
                // toast.success(response.message);
                // toast.success("Passo 2 salvo. Pergunta(s) associada(s) ao curso com sucesso.");
                toast.success(`Passo 2 salvo. ${
                    questionsId.perguntasIds.length
                } pergunta(s) selecionada(s).`);
                // navigate('/');
                callback && callback();
            }
            
        } catch (error:any) {
            toast.error
            (error?.response?.data?.message
                    ? error.response.data.message
                    : 'Erro inesperado.'
            );
            console.log(error);
        }
    }
    const resetQuestions = () => {
        setQuestions([]);
    }    
    const editStepTwo = async (stepTwo: IStepTwo, courseCode: number, callback?: () => void) => {
        try {
            const response: any = await questionAssociationService.editStepTwo(stepTwo, courseCode);
            if(response) {
                // TODO: limpar console log e comentários
                console.log('response: ', response);
                // toast.success("Passo 2 salvo. Perguntas associadas ao curso atualizadas com sucesso.");
                // toast.success("Passo 2 salvo. Perguntas do formulário de inscrição atualizadas com sucesso.");
                toast.success(`Passo 2 salvo. ${
                    stepTwo.perguntasIds.length
                } pergunta(s) selecionada(s).`);
                callback && callback();
            }
            
        } catch (error: any) {
            toast.error(
                error?.response?.data?.message
                    ? error.response.data.message
                    : 'Erro inesperado.'
            );
            console.log(error);
        }
    }
    // #endregion - Passo 2


    // #region - Passo 3
    const fetchStepThree = async (courseCode: number) => {
        setLoadingFetch(true);
        try {
            const response: IStepThree = await courseResourcesService
                .listByCourseCode(courseCode)
                .finally(() => setLoadingFetch(false));
            setResources(response);
        } catch (error: any) {
            toast.error(
                error?.response?.data?.message
                    ? error.response.data.message
                    : 'Erro inesperado.'
            );
            console.log(error);
        }
    }
    const saveStepThree = async (data: IStepThree, courseCode: number, callback?: () => void) => {

        const getSelectedResouces = (resources: ICourseRecourses[]): string => {
            const resourcesName = resources.filter(x => x.selecionada).map(x => x.nome);
            return resourcesName.reduce((result,el,i,listObj) => {
                return i+1 === listObj.length
                    ? result += ' e ' + el
                    : result += ', ' + el
            })
        }

        try {
            const response: IStepThree = await courseResourcesService
                .edit(data, courseCode);
            if(response) {
                // toast.success("Passo 3 salvo. Recursos do curso atualizados com sucesso.");
                toast.success(`Passo 3 salvo. Escolhido Recurso(s): ${
                    getSelectedResouces([...data.consulta, ...data.comunicacao])
                }.`);
                callback && callback();
            }
        } catch (error: any) {
            toast.error(
                error?.response?.data?.message
                    ? error.response.data.message
                    : 'Erro inesperado.'
            );
            console.log(error);
        }
    }
    // #endregion - Passo 3


    // #region - Passo 4: Modules
    const fetchStepFour = async (courseCode: number) => {
        // Busca os dados do passo 4 do usuário logado pelo código do curso (fetchModulesFromCurrentUser)
        try {
            setLoadingModulesList(true);
            const response: IStepFour = await courseModuleService
                .listCourseModules(courseCode)
                .finally(() => setLoadingModulesList(false));
            
            setDataStepFour({
                ...response,
                modulos: response.modulos.sort(
                    (a,b) => a.ordem<b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0
                )
            });
            // setDataStepFour(response);

        } catch (error: any) {
            toast.error(
                error?.response?.data?.message
                    ? error.response.data.message
                    : 'Erro inesperado.'
            );
            console.log(error);
        }
    }
    const editStepFour = async (module: IStepFour, courseCode: number, callback?: () => void) => {
        try {
            /*const response: ICourseRecourses[] = */await courseModuleService
                .editCourseModules(module, courseCode);
            // toast.success("Passo 4 salvo. Estrutura de curso atualizada com sucesso.");
            toast.success(`Passo 4 salvo. Criado(s) ${
                module.modulos.map(x => x.selecionada).length
            } ${
                courseModuleTypes.find(x => x.id === module.idTipoDeModuloSelecionado)?.label
            }(s).`);
            callback && callback();
        } catch (error: any) {
            toast.error(
                error?.response?.data?.message
                    ? error.response.data.message
                    : 'Erro inesperado.'
            );
            console.log(error);
        }
    }
    const createModule = async (module: Module, courseCode: number, callback?: () => void) => {
        try {
            await courseModuleService.create(courseCode, module);
            toast.success("Novo módulo criado com sucesso.");
            callback && callback();
        } catch (error: any) {
            toast.error(
                error?.response?.data?.message
                    ? error.response.data.message
                    : 'Erro inesperado.'
            );
            console.log(error);
        }
    }
    const editModule = async (module: Module, courseCode: number, callback?: () => void) => {
        try {
            /*const response: ICourseRecourses[] = */await courseModuleService
                .edit(module, courseCode);
            toast.success("Módulo atualizado com sucesso.");
            callback && callback();
        } catch (error: any) {
            toast.error(
                error?.response?.data?.message
                    ? error.response.data.message
                    : 'Erro inesperado.'
            );
            console.log(error);
        }
    }
    const removeModules = async (courseCode: number, moduleIds: number[], callback?: () => void) => {
        dialogConfirm({
            text: "Você confirma que deseja apagar o(s) Módulo(s) selecionado(s)?",
            title: "Apagar Módulo(s)",
            bgColor: theme.colors.bgPrimary,
            onClickConfirm: async () => {
                try {
                    await courseModuleService.removeList(courseCode, moduleIds);
                    toast.success("Módulo(s) removido(s) com sucesso.");
                    callback && callback();
                } catch (error: any) {
                    toast.error(
                        error?.response?.data?.message
                    ? error.response.data.message
                    : 'Erro inesperado.'
            );
                    console.log(error);
                }
            }
        });
    }
    // #endregion - Passo 4


    // #region - Passo 5
    const fetchStepFive = async (courseCode: number) => {
        setLoadingFetch(true);
        // Busca os dados do passo 5 do usuário logado pelo código do curso
        try {
            const response: IStepFive = await courseReleasedAssociationService
                .list(courseCode)
                .finally(() => setLoadingFetch(false));
            setDataStepFive(response);
        } catch (error: any) {
            toast.error(
                error?.response?.data?.message
                    ? error.response.data.message
                    : 'Erro inesperado.'
            );
            console.log(error);
        }
    }
    const editStepFive = async (data: IStepFive, courseCode: number, callback?: () => void) => {
        try {
            await courseReleasedAssociationService.edit(data, courseCode);
            toast.success("Passo 5 salvo. Dado(s) de publicação do curso atualizado(s) com sucesso.");
            callback && callback();                
        } catch (error: any) {
            toast.error(
                error?.response?.data?.message
                    ? error.response.data.message
                    : 'Erro inesperado.'
            );
            console.log(error);
        } 
    }
    // #endregion - Passo 5

    return (
        <CourseContext.Provider
            value={{
                courses,
                courseEditing,
                questions,
                selectedQuestionsInCurrentCourse,
                currentCourseId,
                resources,
                dataStepFour,
                dataStepFive,

                loadingModulesList,
                loadingFetch,

                fetchCourses,
                resetCourses,
                resetCourseEditing,
                getCourseById,
                saveStepOne,
                editStepOne,

                fetchQuestionsFromCurrentUser,
                fetchQuestionsByCourseId,
                saveStepTwo,
                editStepTwo,
                resetQuestions,
                createQuestionForCurrentUserCourses,

                fetchStepThree,
                saveStepThree,

                fetchStepFour,
                editStepFour,
                createModule,
                editModule,
                removeModules,

                fetchStepFive,
                editStepFive,
            }}
            >
            {children}
        </CourseContext.Provider>
    );
};

export const useCourse = () => useContext(CourseContext);