import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../shared/contexts/UserContext";
import { User } from "../../shared/models/Users";
import Actions from "./Actions";
import { useMenu } from "../../shared/contexts/MenuContext";
import { FaTrash, FaUserPlus as AddUser } from "react-icons/fa";
import { useDropdown } from "../../shared/contexts/domains/DropdownContext";
import { DomainRegion, DomainSelectOption } from "../../shared/models/domains/Domains";
import Breadcrumb from "../../components/Breadcrumb";
import Title from "../../components/Title";
import Table from "../../components/Table";
import { LINK_TYPE } from "../../shared/consts";
import { Container, CustomTitleContent } from "./styles";
import { useAuth } from "../../shared/contexts/Auth";

const Admin: React.FC = () => {

    const { hasPermission } = useAuth();
    const { users, fetchUsers, removeUserById } = useUser();
    const { trackMenuOption } = useMenu();
    const { regions/*, ufs*/ } = useDropdown();
    const navigate = useNavigate();

    const handleRemoveUserClick = (userId: number) => {
        removeUserById(userId);
    };

    const columns = [
        {
            name: 'Ações',
            width: '70px',
            cell: (row: any) => {
                return (
                    <Actions userId={row.id} aria-label="Botões de ação">
                        <Link to="#" aria-label="Ação Remover Usuário">
                            <span onClick={() => handleRemoveUserClick(row.id)}>
                                <FaTrash />
                            </span>
                        </Link>
                    </Actions>
                )
            },
        },
        {
            name: 'Nome completo',
            minWidth: '164px',
            selector: (row: any) => (
                <span title={row.nomeCompleto}>
                    {row.nomeCompleto}
                </span>
            ),
        },
        {
            name: 'Telefone',
            width: '164px',
            selector: (row: any) => row.telefone,
        },
        {
            name: 'UF',
            width: '63px',
            selector: (row: any) => row['estado'],
        },
        {
            name: 'E-mail',
            maxWidth: '312px',
            selector: (row: any) => <span title={row.email}>{row.email}</span>,
        },
        {
            name: 'Vínculo',
            width: '110px',
            selector: (row: any) => row.tipoVinculo,
        }
    ];

    // const getUF = (id: string | undefined): string => {
    //     if(!id || !ufs) return '-';
    //     const label = ufs.find((uf: DomainSelectOption) => uf.value === id);
    //     return label?.label || '';
    // }

    const getUF = (id: string | undefined): string => {
        if(!id || regions.length === 0) return '-';
        const label = regions.find((region: DomainRegion) => region.codigo.toString() === id);
        return label?.sigla || '';
    }

    const handleCreateNewUserClick = () => {
        navigate('/register');
    }

    useEffect(() => {
        trackMenuOption(6);
    }, [trackMenuOption]);

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <Container>
            <Breadcrumb>
                <span>Usuários</span>
            </Breadcrumb>

            {hasPermission() ? (
                <Title>
                    <CustomTitleContent>
                        <span>Usuários</span>
                        <span>
                            <button
                                title="Cadastrar parcialmente um novo usuário"
                                onClick={handleCreateNewUserClick}
                            >
                                <AddUser/>
                            </button>
                        </span>
                    </CustomTitleContent>
                </Title>
            ) : (
                <Title>Usuários</Title>
            )}

            <Table
                columns={columns}
                data={users?.map((x:User) => {
                    return ({
                        id: x.id,
                        nomeCompleto: x?.nome || '-',
                        telefone: x?.celular || '-',
                        estado: getUF(x?.estado),
                        email: x?.email || '-',
                        curso: x?.dre || '-',
                        tipoVinculo: LINK_TYPE.find(
                            (y:DomainSelectOption) => y.value === x?.tipoVinculo
                        )?.label || '-'
                    })
                })}
                pagination
            />
        </Container>
    )
}
export default Admin;