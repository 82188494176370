import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import logoUFRJ from '../../assets/img/logo_ufrj.png';
import logoUFRJHighContrast from '../../assets/img/logo_ufrj_branco.png';
import logoLTC from '../../assets/img/logo_ltc.png';
import logoLTCHighContrast from '../../assets/img/logo_ltc_branco.png';
import logoMec from '../../assets/img/logo_mec.png';
import logoMecHighContrast from '../../assets/img/logo_mec_branco.png';
import logoNUTES from '../../assets/img/logo_nutes.png';
import logoNUTESHighContrast from '../../assets/img/logo_nutes_branco.png';
import logoFAPERJ from '../../assets/img/logo_faperj.jpg';
import logoFAPERJHighContrast from '../../assets/img/logo_faperj_branco.png';

import AccessibilityToolbar from '../../components/AccessibilityToolbar';
import PublicMenu from '../../components/PublicMenu';
import Input from '../../components/Input';
import Title from '../../components/Title';
import Spinner from '../../components/Spinner';
import Button from '../../components/Button';
import { ForgotPasswordData } from '../../shared/models/Users';
import { useLoader } from '../../shared/contexts/LoadingContext';
import { useAccessibility } from '../../shared/contexts/AccessibilityContext';
import { useUser } from '../../shared/contexts/UserContext';

import schema from './schema';
import {
    Container,
    Form,
    TopBrand,
    Buttons,
    Content,
    FooterBrand,
} from './styles';

const ForgotPassword: React.FC = () => {
    const { loading } = useLoader();
    const { theme } = useAccessibility();
    const { forgotPassword } = useUser();
    const navigate = useNavigate();

    const handleSubmit = (data: ForgotPasswordData) => {
        forgotPassword(data.email);
    }

    const formik = useFormik({
        onSubmit: handleSubmit,
        validationSchema: schema,
        initialValues: {
            email: ''
        },
    });

    // function handleShowError(field: string) {
    //     return formik.touched?[field] && formik?.errors[field];
    // };

    const checkDisabledSubmit = () => {
        return !(formik.dirty || formik.isValid) || 
                !formik.dirty ||
                loading;
    }

    const getErrorMessage = (fieldName: string) => {
        return (formik?.getFieldMeta(fieldName)?.touched &&
                formik?.getFieldMeta(fieldName)?.error)
            ?
            formik.getFieldMeta(fieldName).error
            :
            ''
    }

    const getLogoImage = (normal: string, highContrast: string) => {
        return theme.id !== 2 ? normal : highContrast;
    }

    return (
        <Container>
            <AccessibilityToolbar />
            <Content>
                <TopBrand>
                    <span>
                        <img
                            src={getLogoImage(logoMec, logoMecHighContrast)}
                            alt="Ministério da Educação - MEC"
                            className="logo-mec"
                        />
                    </span>
                    <span>
                        <span>
                            <img
                                src={getLogoImage(logoUFRJ, logoUFRJHighContrast)}
                                alt="UFRJ"
                                className="logo-ufrj"
                            />
                        </span>
                        <span>
                            <img
                                src={getLogoImage(logoNUTES,logoNUTESHighContrast)}
                                alt="NUTES"
                                className="logo-nutes"
                            />
                        </span>
                        <span>
                            <img
                                src={getLogoImage(logoLTC,logoLTCHighContrast)}
                                alt="Laboratorio de Tecnologias Cognitivas"
                                className="logo-ltc"
                            />
                        </span>
                    </span>
                </TopBrand>

                <Title>Esqueci Minha Senha</Title>

                <Form onSubmit={formik.handleSubmit}>
                    <div>
                        <Input
                            label="E-mail *"
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Digite seu e-mail"
                            value={formik?.values?.email}
                            onChange={formik?.handleChange}
                            autoComplete
                            autoFocus
                            readOnly={loading}
                            errorText={getErrorMessage('email')}
                        />
                        <Buttons>
                            <Button onClick={() => navigate('/')} btnTheme="primary">
                                Cancelar
                            </Button>
                            <Button
                                type="submit"
                                disabled={checkDisabledSubmit()}
                                btnTheme="primary"
                            >
                                {loading ? <Spinner /> : 'Redefinir senha'}
                            </Button>
                        </Buttons>
                    </div>
                </Form>
            </Content>
            <FooterBrand>
                <img src={getLogoImage(logoFAPERJ, logoFAPERJHighContrast)} alt="Logotipo FAPERJ" />
            </FooterBrand>            
            <PublicMenu />
        </Container>
    );
}

export default ForgotPassword;