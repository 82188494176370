import React from 'react';

import { Container } from './styles';

import MainHeader from '../MainHeader';
import AccessibilityToolbar from '../AccessibilityToolbar';
import MenuMobile from '../Menu/MenuMobile';
import ContextualMenu from '../Menu/ContextualMenu';
import MainBody from '../MainBody';

type Props = {
    children?: React.ReactNode | React.ReactNode[];
};

const Layout: React.FC<Props> = ({ children }) => (
    <Container className="aux-layout">
        <AccessibilityToolbar/>
        <MainHeader />
        <MainBody>
            {children}
        </MainBody>
        <ContextualMenu/>
        <MenuMobile/>
    </Container>
);

export default Layout;